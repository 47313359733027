import { Box, Typography, Stack, Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from '../../Shared/Dialogs/ConfirmDialog'
import { useUsers } from '../../Users/Contexts/UsersContext'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext'
import { useAuth } from '../../Shared/Contexts/AuthContext'

const DeleteUser = () => {
  const navigate = useNavigate()
  const [confirmDeleteUserIsOpen, setConfirmDeleteUserIsOpen] = useState(false)
  const { useDeleteSelf } = useUsers()
  const { logout } = useAuth()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const handleBackToProfile = () => navigate('/management/profile')

  const handleDeleteRequest = () => setConfirmDeleteUserIsOpen(true)

  const handleConfirmDeleteUser = () => {
    useDeleteSelf()
      .then(() => {
        openSuccessAlert()
        logout()
        navigate('/')
      })
      .catch(() => openErrorAlert({ keys: [] }))
    setConfirmDeleteUserIsOpen(false)
  }

  return (
    <>
      <Box
        marginTop={'66px'}
        height={'71vh'}
        width={'100%'}
        alignContent={'center'}>
        <Box
          marginTop={5}
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}>
          <Typography variant="h1">ELIMINA UTENTE</Typography>
        </Box>
        <Box
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
          marginTop={10}>
          <Stack
            display={'flex'}
            width={'30%'}
            alignContent={'center'}
            justifyContent={'center'}
            spacing={3}>
            <Typography textAlign={'center'}>Sei sicuro di voler eliminare il tuo utente?</Typography>
            <Typography
              textAlign={'center'}
              fontWeight="bold">
              (Questa operazione non è reversibile)
            </Typography>
            <Button
              variant="contained"
              onClick={handleBackToProfile}>
              Torna al profilo
            </Button>
            <Button
              id="template-delete-user-button-delete"
              color="error"
              variant="contained"
              onClick={handleDeleteRequest}>
              Elimina
            </Button>
          </Stack>
        </Box>
      </Box>

      <ConfirmDialog
        isOpen={confirmDeleteUserIsOpen}
        title={'Elimina utente'}
        message={`Sei sicuro di voler cancellare il tuo account?`}
        onConfirm={handleConfirmDeleteUser}
        onClose={() => setConfirmDeleteUserIsOpen(false)}
      />
    </>
  )
}

export default DeleteUser
