import React, { useState } from 'react'
import { t } from 'i18next'
import { Box, Stack, TextField } from '@mui/material'
import Joi from 'joi'
import useFieldValidation from '../../../../helpers/fieldValidation'
import { useValidationEffect } from '../../../../helpers/hooks'

const schema = Joi.object({
  intervalInMinutes: Joi.string().empty(null, '').required(),
  toleranceInSeconds: Joi.string().empty(null, '').required(),
  maxDelta: Joi.string().empty(null, '').required()
})

const DeltaTriggerSelector = ({ trigger, onDeltaTriggerSelectorChange }) => {
  const onChange = (newTrigger) =>
    onDeltaTriggerSelectorChange({ trigger: newTrigger })

  const [validation, setValidation] = useState({ isValid: false })

  useValidationEffect(() => {
    setValidation(
      useFieldValidation(
        {
          intervalInMinutes: trigger?.intervalInMinutes,
          toleranceInSeconds: trigger?.toleranceInSeconds,
          maxDelta: trigger?.maxDelta
        },
        schema
      )
    )
  }, [trigger])

  return (
    <Box sx={{ margin: '5px 5px' }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <TextField
          id="deltaTriggerSelectorInterval"
          label={t('common.interval')}
          value={trigger?.intervalInMinutes}
          onChange={(event) =>
            onChange({ ...trigger, intervalInMinutes: event.target.value })
          }
          type="number"
          error={validation?.messages?.intervalInMinutes?.length > 0}
          helperText={validation?.messages?.intervalInMinutes?.join(', ')}
        />
        <TextField
          id="deltaTriggerTolerance"
          label={t('common.tolerance')}
          value={trigger?.toleranceInSeconds}
          onChange={(event) =>
            onChange({ ...trigger, toleranceInSeconds: event.target.value })
          }
          type="number"
          error={validation?.messages?.toleranceInSeconds?.length > 0}
          helperText={validation?.messages?.toleranceInSeconds?.join(', ')}
        />
        <TextField
          id="deltaTriggerMaxDelta"
          label={t('rules.maxDelta')}
          value={trigger?.maxDelta}
          onChange={(event) =>
            onChange({ ...trigger, maxDelta: event.target.value })
          }
          type="number"
          error={validation?.messages?.maxDelta?.length > 0}
          helperText={validation?.messages?.maxDelta?.join(', ')}
        />
      </Stack>
    </Box>
  )
}

export default DeltaTriggerSelector
