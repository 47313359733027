import {
  Autocomplete,
  CircularProgress,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Inventory2Rounded as Inventory2RoundedIcon } from '@mui/icons-material'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useFilters } from '../../Contexts/FiltersContext'

const BrandsFilter = ({ filters = {}, onChange }) => {
  const { useFetchBrandsFilters } = useFilters()

  const [brandsFilterOptions, setBrandsFiltersOptions] = useState([])
  const [isBrandsFilterLoading, setIsBrandsFilterLoading] = useState(false)

  const handleChange = (_, value) => {
    const brandIds = value.map((i) => i.id)
    onChange({ brandIds })
  }

  useEffect(() => {
    setIsBrandsFilterLoading(true)

    useFetchBrandsFilters(brandIds)
      .then(({ data }) => setBrandsFiltersOptions(data))
      .finally(() => setIsBrandsFilterLoading(false))
  }, [])

  const { brandIds = [] } = filters

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.brands.label')}
        </Typography>
        <Autocomplete
          multiple
          disabled={isBrandsFilterLoading}
          id="brands-filter"
          options={brandsFilterOptions}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          loading={isBrandsFilterLoading}
          getOptionLabel={(option) => option.name}
          value={brandsFilterOptions.filter(({ id }) => brandIds.includes(id))}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              divider
              key={option.id}
            >
              <ListItemIcon>
                <Inventory2RoundedIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: '0.8rem',
                  fontWeight: 500
                }}
                primary={option.name}
              />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={t('filters.devices.other.brands.placeholder')}
              slotProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      color="inherit"
                      edge="end"
                    >
                      {isBrandsFilterLoading && (
                        <CircularProgress
                          edge="end"
                          size={20}
                        />
                      )}
                    </Icon>
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </Stack>
    </>
  )
}

BrandsFilter.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default BrandsFilter
