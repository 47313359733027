import {
  Box,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  IconButton,
  Pagination,
  Skeleton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { t } from 'i18next'
import StatusBadge from '../StatusBadge/StatusBadge.jsx'
import {
  EditRounded as EditRoundedIcon,
  DeleteRounded as DeleteRoundedIcon,
  SendRounded as SendRoundedIcon
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import Can from '../../Layout/Can/Can'
import Show from '../../Layout/Can/Show.jsx'

const DEFAULT_PAGE_SIZE = 25

const DevicesTable = ({ onSelectedDevicesChanged, onSelectAllChanged, page, sorting, onPageChange, onSortingChange, onEdit, onDelete, onAction, isLoading, devices }) => {
  const { direction, sort } = sorting

  const [selectAllDevices, setSelectAllDevices] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedDevices, setSelectedDevices] = useState([])

  const handleSelectAllDevices = ({ target: { checked } }) => {
    const newSelectedDevices = checked ? devices.data.map((x) => x.id) : []
    setSelectAllDevices(checked)
    setSelectedDevices(newSelectedDevices)
    onSelectedDevicesChanged(newSelectedDevices)
  }

  const onSelectAll = (_, value) => {
    const newSelectedDevices = value ? devices.data.map((x) => x.id) : []
    setSelectAll(value)
    setSelectAllDevices(value)
    setSelectedDevices(newSelectedDevices)
    onSelectAllChanged(value)
  }

  const handleSelectedDevice = (deviceId, checked) => {
    if (selectAllDevices && !checked) {
      setSelectAllDevices(false)
    }
    const newSelectedDevices = checked
      ? [...selectedDevices, deviceId]
      : selectedDevices.filter((id) => id !== deviceId)
    setSelectedDevices(newSelectedDevices)
    onSelectedDevicesChanged(newSelectedDevices)
  }

  const selectionCells = {
    custom: true,
    customControl: (
      <>
        <FormControlLabel
          control={
            <Switch
              onChange={handleSelectAllDevices}
              checked={selectAllDevices}
              size="small"
            />
          }
          label={<small>{t('common.selectPage')}</small>}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={onSelectAll}
              checked={selectAll}
              size="small"
            />
          }
          label={<small>{t('common.all')}</small>}
          labelPlacement="end"
        />
      </>
    )
  }

  const headerCells = [
    selectionCells,
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'isAlive',
      label: t('common.status')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'name',
      label: t('common.name')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'model',
      label: `${t('common.brand')} / ${t('common.model')}`
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'technology',
      label: `${t('common.type')}`
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'lastMeasurementAt',
      label: t('devices.lastUpdate')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'address',
      label: t('common.address')
    },
    {
      isSortable: false,
      custom: false,
      customControl: null,
      name: 'tags',
      label: 'Tags'
    },
    {
      isSortable: false,
      custom: false,
      customControl: null,
      name: '',
      label: ''
    }
  ]

  function createCell(cell) {
    return cell.isSortable ? (
      <TableSortLabel
        active={sort === cell.name}
        direction={sort === cell.name ? direction : 'asc'}
        onClick={() => onSortingChange(cell.name)}
      >
        {cell.label}
      </TableSortLabel>
    ) : (
      cell.label
    )
  }

  function tableHead(headerCells) {
    return headerCells.map((cell, i) => (
      <TableCell key={i}>
        {cell.custom ? cell.customControl : createCell(cell)}
      </TableCell>
    ))
  }

  return (
    <>
      <Show when={() => isLoading}>
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}
        >
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '100px' }}
            ></Skeleton>
          ))}
        </Stack>
      </Show>
      <TableContainer component={Box}>
        <Table aria-label="devices table">
          <TableHead>
            <TableRow>{tableHead(headerCells)}</TableRow>
          </TableHead>
          <TableBody>
            {devices?.data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    id={`checkbox-handle-selected-device-${index}`}
                    onChange={({ target: { checked } }) =>
                      handleSelectedDevice(row.id, checked)
                    }
                    color="primary"
                    checked={selectedDevices.includes(row.id)}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                >
                  <StatusBadge
                    isAlive={row.isAlive}
                    isAlarm={row.isAlarm}
                    withMessage={false}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                >
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.brand} / {row.model}
                </TableCell>
                <TableCell>
                  {row.source.type}
                </TableCell>
                <TableCell>{moment(row.lastMeasurementAt).fromNow()}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.tags}</TableCell>

                <TableCell>
                  <ButtonGroup size="small">
                    <Can action="devices_u">
                      <IconButton
                        variant="text"
                        key="edit"
                        onClick={() => onEdit(row)}
                        color="primary"
                      >
                        <EditRoundedIcon fontSize="small" />
                      </IconButton>
                    </Can>
                    <Can action="devices_d">
                      <IconButton
                        variant="text"
                        key="trash"
                        onClick={() => onDelete(row)}
                        color="error"
                      >
                        <DeleteRoundedIcon fontSize="small" />
                      </IconButton>
                    </Can>
                    <Can action="devices_u">
                      <IconButton
                        variant="text"
                        key="actions"
                        disabled={row.actions.length === 0}
                        onClick={() => onAction(row)}
                        color="primary"
                      >
                        <SendRoundedIcon fontSize="small" />
                      </IconButton>
                    </Can>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Show when={() => devices && !isLoading && devices.count > DEFAULT_PAGE_SIZE}>
        <Box
          display="flex"
          justifyContent="center"
        >
          {devices?.count &&
            <Pagination
              count={Math.ceil(devices?.count / DEFAULT_PAGE_SIZE)}
              page={page}
              onChange={onPageChange}
              showFirstButton
              showLastButton
            />}
        </Box>
      </Show>
    </>
  )
}

DevicesTable.propTypes = {
  onSelectedDevicesChanged: PropTypes.func,
  onSelectAllChanged: PropTypes.func
}

export default DevicesTable
