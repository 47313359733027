import { Box } from '@mui/material'
import React, { useState } from 'react'
import LogoColor from '../../../images/cityeyedataviz.svg'
import LogoWhite from '../../../images/cityeyedatawhite.svg'
import { useAuth } from '../../Shared/Contexts/AuthContext'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext'

const Logo = () => {
  const { theme } = useGlobalContext()

  const { currentUser } = useAuth()

  const [found, setFound] = useState(true)

  return (
    <>
      {found && (
        <img
          src={`/api/logos/${currentUser.currentOrganizationKey}`}
          onError={() => setFound(false)}
          alt="Organization Logo"
          style={{ maxWidth: '100%', maxHeight: '70px' }}
        />
      )}
      {!found && theme === 'light' && (
        <Box
          component="img"
          sx={{ maxWidth: '100%', maxHeight: '70px' }}
          src={LogoColor}
        />
      )}
      {!found && theme === 'dark' && (
        <Box
          component="img"
          sx={{ maxWidth: '100%', maxHeight: '70px' }}
          src={LogoWhite}
        />
      )}
    </>
  )
}

export default Logo
