import { useGlobalContext } from '../components/Shared/Contexts/GlobalContext.jsx'

const useFieldValidation = (form, schema) => {
  const itErrors = {
    'string.empty': 'Non può essere vuoto',
    'string.lowercase': 'Deve essere minuscolo',
    'string.uppercase': 'Deve essere maiuscolo',
    'string.email': 'Deve essere una email valida',
    'string.hex': 'Deve essere un valore esadecimale',
    'string.min': 'Deve essere almeno di :limit caratteri',
    'string.max': 'Deve essere al massimo di :limit caratteri',
    'string.length': 'Deve essere di :limit caratteri',
    'string.alphanum':
      'Deve contenere solo caratteri alfanumerici (non sono ammessi caratteri speciali)',
    'string.pattern.base': `Può contenere solo lettere, numeri, spazi e i seguenti caratteri speciali . : , - _ ( )`,
    'number.base': 'Deve essere un numero',
    'number.integer': 'Deve essere un numero intero',
    'any.invalid': 'Valore non valido',
    'any.unknown': 'Campo non ammesso',
    'any.required': 'Obbligatorio',
    'domain.invalid': 'Dominio non valido',
    'email.invalid': 'Email non valida',
    'phoneNumber.invalid': 'Numero di telefono non valido',
    'array.unique': 'Valore duplicato',
    'custom.max11char':
      'Massimo 11 caratteri alfanumerici. Iniziare con una lettera'
  }

  const enErrors = {
    'string.empty': 'Cannot be empty',
    'string.lowercase': 'Must be lowercase',
    'string.uppercase': 'Must be uppercase',
    'string.email': 'Must be a valid email address',
    'string.hex': 'Must be an hexadecimal value',
    'string.min': 'Must be of at least :limit characters',
    'string.max': 'Cannot be more than :limit characters',
    'string.length': 'Must be of :limit characters',
    'string.alphanum':
      'Must only contain alphanumerical characters (no special characters allowed)',
    'string.pattern.base': `Can only contain letters, numbers, spaces and the following special characters . : , - _ ( )`,
    'number.base': 'Must be a number',
    'number.integer': 'Must be an integer number',
    'any.invalid': 'Invalid value',
    'any.unknown': 'Unknown field',
    'any.required': 'Required',
    'domain.invalid': 'Invalid domain',
    'email.invalid': 'Invalid email address',
    'phoneNumber.invalid': 'Invalid phone number',
    'array.unique': 'Duplicate value',
    'custom.max11char':
      'Maximum 11 alphanumeric characters. Start with a letter'
  }

  const options = {
    abortEarly: false,
    convert: false,
    errors: {
      label: false,
      language: 'it'
    },
    messages: {
      it: { ...itErrors },
      en: { ...enErrors }
    }
  }

  const messages = schema.validate(form, options)

  if (messages && messages.error) {
    const errors = {}

    messages.error.details.forEach((e) => {
      if (!errors[e.path[0]]) {
        errors[e.path[0]] = []
      }
      errors[e.path[0]].push(e.message.replace(':limit', e.context.limit))
    })

    return { isValid: false, messages: errors }
  }

  return { isValid: true }
}

export default useFieldValidation
