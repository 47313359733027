import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { t } from 'i18next'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton'
import PropTypes from 'prop-types'
import { useDevices } from '../Contexts/DevicesContext.jsx'

const Actions = ({ isOpen, setIsOpen, device, onSendAction }) => {
  const { useFetchDownlinks } = useDevices()

  const [downlinks, setDownlinks] = useState()
  const [isDownlinksLoading, setIsDownlinksLoading] = useState(false)

  useEffect(() => {
    setIsDownlinksLoading(true)
    useFetchDownlinks(device.id)
      .then(({ data }) => setDownlinks(data))
      .finally(() => setIsDownlinksLoading(false))
  }, [device])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSendAction = () => {
    onSendAction(device)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={'md'}
    >
      <DialogTitle
        id="device-detail-actions-dialog-title"
        display="flex"
        justifyContent="center"
        variant="h4"
      >
        {t('devices.actionsDevice.title')}
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent display="flex">
        {!isDownlinksLoading && (
          <Table
            sx={{ minWidth: 650 }}
            aria-label="downlinks-table"
          >
            <TableHead>
              <TableRow>
                <TableCell>{t('downlinks.name')}</TableCell>
                <TableCell align="right">{t('downlinks.state')}</TableCell>
                <TableCell align="right">{t('downlinks.date')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {downlinks?.results.map((row, i) => (
                <TableRow
                  key={row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    id={`device-detail-actions-dialog-table-row-${i}-action-name`}
                    component="th"
                    scope="row"
                  >
                    {row?.data?.actionName.join(', ') || '-'}
                  </TableCell>
                  <TableCell align="right">{row?.status || '-'}</TableCell>
                  <TableCell align="right">
                    {moment(row?.createdAt).format('DD.MM.YYYY HH:mm:ss') ||
                      '-'}
                  </TableCell>
                </TableRow>
              ))}
              {downlinks?.results && downlinks.results.length === 0 && (
                <TableRow
                  key={'no-data'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={3}
                  >
                    {t('downlinks.noData')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          id="device-detail-actions-dialog-button-new-action"
          onClick={handleSendAction}
          variant="contained"
          color="warning"
        >
          {t('actions.sendNew')}
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
        >
          {t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Actions.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  device: PropTypes.object,
  onSendAction: PropTypes.func
}

export default Actions
