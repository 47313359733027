import { Container, Button, Grid2, Stack, Chip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import PendingInvitesList from '../Tables/PendingInvitesList.jsx'
import InviteUser from '../Dialogs/InviteUser.jsx'
import { useInvites } from '../Contexts/InvitesContext.jsx'
import { useSearchParams } from 'react-router-dom'
import Can from '../../Layout/Can/Can.jsx'

const DEFAULT_PAGE_SIZE = 25
const Invites = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const { useFetchInvites } = useInvites()
  const [page, setPage] = useState(1)
  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false)
  const [invites, setInvites] = useState()

  const fetchInvites = ({ page }) => {
    useFetchInvites(fullText ? { page, pageSize: DEFAULT_PAGE_SIZE, fullText } : { page, pageSize: DEFAULT_PAGE_SIZE }).then((res) => setInvites(res))
  }

  useEffect(() => {
    fetchInvites({ page })
  }, [page])

  useEffect(() => {
    fetchInvites({ page })
  }, [fullText])

  const handleInviteUser = () => setIsInviteUserDialogOpen(true)
  const handleChangePage = (_, value) => setPage(value)

  const handleInviteDeleted = () => {
    fetchInvites({ page: 1 })
    setPage(1)
  }

  const handleInviteCreated = () => {
    fetchInvites({ page: 1 })
    setPage(1)
    setIsInviteUserDialogOpen(false)
  }

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{ py: 3 }}>
        <Grid2
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start">
          <Grid2>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}>
              <Typography
                id="invites-header"
                variant="h2">
                {t('common.invites')}
              </Typography>
              <Chip
                label={invites?.count}
                color="primary"
              />
            </Stack>
          </Grid2>

          <Grid2>
            <Can action={'users_c'}>
              <Button
                id="invite-user-button"
                variant="contained"
                color="primary"
                onClick={handleInviteUser}>
                {t('invites.add')}
              </Button>
            </Can>
          </Grid2>
        </Grid2>
        <PendingInvitesList
          invites={invites}
          page={page}
          onChangePage={handleChangePage}
          onInviteDeleted={handleInviteDeleted}
        />
      </Container>
      <InviteUser
        isOpen={isInviteUserDialogOpen}
        onClose={handleInviteCreated}
      />
    </>
  )
}

export default Invites
