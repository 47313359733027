import React, { createContext, useContext, useReducer, useState } from 'react'
import i18n from '../../../i18n/index.js'
import { useAuth } from './AuthContext.jsx'
import sanitizer from '../../../helpers/sanitizer.js'

const GlobalContext = createContext({})

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setTheme':
      return {
        ...state,
        theme: action.payload
      }
    case 'setLanguage':
      return {
        ...state,
        language: action.payload
      }

    case 'setMap':
      return {
        ...state,
        map: action.payload
      }

    case 'setMapCenter':
      return {
        ...state,
        map: {
          ...state.map,
          center: action.payload
        }
      }

    case 'setMapZoom':
      return {
        ...state,
        map: {
          ...state.map,
          zoom: action.payload
        }
      }

    case 'setFromTo':
      return {
        ...state,
        fromTo: action.payload
      }

    case 'setDevicesFilters':
      return {
        ...state,
        devicesFilters: action.payload
      }

    default:
      throw new Error(`Reducer error: unknown action type ${action.type}`)
  }
}

export const GlobalContextProvider = ({ children }) => {
  const { useBaseAxiosGet, useBaseAxiosPost } = useAuth()

  const [state, dispatch] = useReducer(reducer, {
    theme: 'light',
    language: 'it',
    map: {
      center: { lat: 45.745, lng: 9.823 },
      zoom: 10
    },
    devicesFilters: {
      organizationKeys: [],
      showPublic: null,
      showOffline: null,
      showHidden: null,
      brandIds: [],
      modelIds: [],
      tags: [],
      tagSearchMode: null,
      searchInSubOrganizations: false
    }
  })

  const useGlobalSearch = async ({ entityType, query }) => {
    return await useBaseAxiosGet({
      url: `/watson/search?query=${query}${entityType ? `&entityType=${entityType}` : ''}`
    })
  }

  //TODO 04-12-2024 spostare in devices
  const useFetchMarkers = async ({ filters }) => {
    delete filters.searchInSubOrganizations
    return await useBaseAxiosPost({
      url: '/devices/markers',
      payload: sanitizer(filters)
    })
  }

  const setLanguage = (language) => {
    i18n.changeLanguage(language)
    dispatch({ type: 'setLanguage', payload: language })
  }

  const setTheme = (theme) => dispatch({ type: 'setTheme', payload: theme })

  const setMap = ({ zoom, lat, lng }) => dispatch({ type: 'setMap', payload: { center: { lat, lng }, zoom } })

  const setMapCenter = ({ lat, lng }) => dispatch({ type: 'setMapCenter', payload: { lat, lng } })

  const setMapZoom = ({ zoom }) => dispatch({ type: 'setMapZoom', payload: zoom })

  //TODO 04-12-2024 è corretto che stia qui? Forse dovrebbe andare all'interno di un filters e non gestito come una singola entità
  const setFromTo = ({ from, to }) => dispatch({ type: 'setFromTo', payload: [from, to] })

  const setDevicesFilters = (filters) => dispatch({ type: 'setDevicesFilters', payload: filters })

  const [loginDialog, setLoginDialog] = useState(false)
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false)
  const [resetPasswordSuccessDialog, setResetPasswordSuccessDialog] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorAlertMessage, setErrorAlertMessage] = useState('')
  const [successAlert, setSuccessAlert] = useState(false)

  const openSuccessAlert = (successObject = {}) => {
    setSuccessAlert(true)
  }

  const openErrorAlert = (errorObject = {}) => {
    const { message = '', keys = [], statusCode } = errorObject
    setErrorAlert(true)
    setErrorAlertMessage(keys)
  }

  const value = {
    theme: state.theme,
    language: state.theme,
    map: state.map,
    devicesFilters: state.devicesFilters,
    setLanguage,
    setTheme,
    setMap,
    useGlobalSearch,
    useFetchMarkers,
    setFromTo,
    setMapCenter,
    setMapZoom,
    setDevicesFilters,
    loginDialog,
    setLoginDialog,
    resetPasswordDialog,
    setResetPasswordDialog,
    resetPasswordSuccessDialog,
    setResetPasswordSuccessDialog,
    errorAlert,
    setErrorAlert,
    errorAlertMessage,
    setErrorAlertMessage,
    successAlert,
    setSuccessAlert,
    openSuccessAlert,
    openErrorAlert
  }

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}
