import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'

const VisibilityFilter = ({ filters = {}, onChange }) => {
  const { showHidden } = filters

  const [visible, setVisible] = useState(null)
  const [hidden, setHidden] = useState(null)

  const handleChangeHidden = (_, value) => {
    setHidden(value)
    !value && !visible
      ? onChange({ showHidden: null })
      : onChange({ showHidden: value })
  }

  const handleChangeVisible = (_, value) => {
    setVisible(value)
    !value && !hidden
      ? onChange({ showHidden: null })
      : onChange({ showHidden: false })
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.visibility.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="visible"
            control={
              <Checkbox
                onChange={handleChangeVisible}
                checked={showHidden !== null && !showHidden}
                disabled={showHidden !== null && showHidden}
              />
            }
            label={t('filters.devices.other.visibility.valueTrue')}
            sx={{ width: '100px' }}
          />

          <FormControlLabel
            value="hidden"
            control={
              <Checkbox
                onChange={handleChangeHidden}
                checked={showHidden !== null && showHidden}
                disabled={showHidden !== null && !showHidden}
              />
            }
            label={t('filters.devices.other.visibility.valueFalse')}
            sx={{ width: '100px' }}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

export default VisibilityFilter
