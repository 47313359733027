import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import EventsTable from '../../Events/Tables/Table'
import BarChart from '../../Charts/BarChart'
import PropTypes from 'prop-types'
import NotFoundMessage from '../../Shared/Components/NotFoundMessage'
import { useEvents } from '../../Events/Contexts/EventsContext'

const EventsTab = ({ deviceId, refresh, setRefresh, timePicker }) => {
  const { useFetchEvents, useFetchEventsSearchSummary } = useEvents()

  //TODO capire a cosa e se serve
  const [, setEventsCount] = useState(0)
  const [events, setEvents] = useState()
  const [isEventsLoading, setIsEventsLoading] = useState(false)
  const [allEvents, setAllEvents] = useState([])

  useEffect(() => {
    useFetchEventsSearchSummary({
      fromTo: {
        from: timePicker[0],
        to: timePicker[1]
      },
      deviceIds: [deviceId]
    }).then((res) => {
      setAllEvents(res.data)
    })
  }, [])

  useEffect(() => {
    if (!isEventsLoading) {
      setEventsCount(events?.totalCount)
    }
  }, [events])

  useEffect(() => {
    setIsEventsLoading(false)

    useFetchEvents({
      from: timePicker[0],
      to: timePicker[1],
      deviceId: deviceId
    })
      .then((res) => {
        setEvents(res.data)
      })
      .finally(() => {
        setIsEventsLoading(false)
      })
  }, [timePicker])

  useEffect(() => {
    if (refresh) {
      useFetchEvents({
        from: timePicker[0],
        to: timePicker[1],
        deviceId: deviceId
      })
        .then((res) => {
          setEvents(res.data)
        })
        .finally(() => {
          setIsEventsLoading(false)
        })
      setRefresh(false)
    }
  }, [refresh])

  return (
    <>
      {allEvents?.length && events?.results?.length ? (
        <Stack spacing={20}>
          <Box
            key={'events'}
            id="events"
            sx={{ height: '400px' }}
          >
            <Typography
              variant="h5"
              align="center"
              mb={2}
            >
              {t('devices.events')}
            </Typography>
            <Card variant="standard">
              <CardContent>
                <BarChart data={allEvents} />
              </CardContent>
            </Card>
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <EventsTable
              events={events}
              paging={{ page: 1, pageSize: 999 }}
            />
          </Stack>
        </Stack>
      ) : (
        <NotFoundMessage
          when={() => !allEvents?.length || !events?.results?.length}
        />
      )}
    </>
  )
}

EventsTab.propTypes = {
  deviceId: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
}

export default EventsTab
