import { Autocomplete, Chip, Stack, TextField, Typography } from '@mui/material'
import Joi from 'joi'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import useFieldValidation from '../../../helpers/fieldValidation.js'
import PropTypes from 'prop-types'
import { useValidationEffect } from '../../../helpers/hooks.js'
import { useFilters } from '../../Shared/Contexts/FiltersContext.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'

const schema = Joi.object({
  organization: Joi.object().empty(null).required(),
  name: Joi.string()
    .empty(null)
    .regex(/^[A-Za-z0-9.:,_()èùéàòì-\s]{1,110}$/i)
    .required(),
  serial: Joi.string().allow(null, '').default(null),
  description: Joi.string().allow(null, '').default(null),
  tags: Joi.array().items(
    Joi.string()
      .empty(null)
      .regex(/^[A-Za-z0-9.:,_()#&'\/\-\s]{1,50}$/i)
  )
})

const Info = ({ setIsValid, onChange }) => {
  const { useFetchSelfOrganizations } = useAuth()
  const { useFetchTagsFilters } = useFilters()

  const [validation, setValidation] = useState({ isValid: false })
  const [info, setInfo] = useState({})
  const [organizationsSuggestions, setOrganizationsSuggestions] = useState([])
  const [
    isOrganizationsSuggestionsLoading,
    setIsOrganizationsSuggestionsLoading
  ] = useState(false)
  const [tags, setTags] = useState([])
  const [isTagsLoading, setIsTagsLoading] = useState(false)

  const handleChangeOrganization = (_, value) => {
    setInfo({
      ...info,
      organization: value
    })
  }

  const handleChangeTags = (_, value) => {
    setInfo({
      ...info,
      tags: value
    })
  }

  const handleChangeName = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value
    })
  }

  const handleChangeSerial = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value
    })
  }

  const handleChangeDescription = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value
    })
  }

  useValidationEffect(() => {
    const newValidation = useFieldValidation(info, schema)
    setValidation(newValidation)
    setIsValid(newValidation.isValid)
    onChange(info)
  }, [info])

  useEffect(() => {
    setIsOrganizationsSuggestionsLoading(true)
    setIsTagsLoading(true)

    useFetchSelfOrganizations()
      .then((res) => {
        setOrganizationsSuggestions(res.data)
      })
      .finally(() => {
        setIsOrganizationsSuggestionsLoading(false)
      })

    useFetchTagsFilters()
      .then((res) => {
        setTags(res.data)
      })
      .finally(() => {
        setIsTagsLoading(false)
      })
  }, [])

  return (
    <Stack
      direction="column"
      spacing={5}
    >
      <Typography
        variant="h2"
        py={1}
      >
        {t('devices.addInfo')}
      </Typography>

      <Autocomplete
        id="device-form-choose-organization"
        name="organizationKey"
        sx={{ width: '50%' }}
        disableClearable
        loading={isOrganizationsSuggestionsLoading}
        options={organizationsSuggestions || []}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        value={info.organization || { name: '' }}
        onChange={handleChangeOrganization}
        renderInput={(params) => (
          <TextField
            name="organizationKey"
            {...params}
            label={t('devices.chooseOrganization')}
            required={true}
            variant="standard"
            error={validation?.messages?.organization?.length > 0}
            helperText={validation?.messages?.organization?.join(', ')}
          />
        )}
      />

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <TextField
          id="device-form-info-name"
          label={t('common.name')}
          required={true}
          name="name"
          variant="standard"
          error={validation.messages?.name?.length > 0}
          helperText={validation.messages?.name?.join(', ')}
          fullWidth
          value={info.name || ''}
          onChange={handleChangeName}
        />

        <TextField
          label={t('devices.serialNumber')}
          name="serial"
          variant="standard"
          fullWidth
          error={validation.messages?.serial?.length > 0}
          helperText={validation.messages?.serial?.join(', ')}
          value={info.serial || ''}
          onChange={handleChangeSerial}
        />
      </Stack>
      <TextField
        label={t('common.description')}
        name="description"
        variant="standard"
        multiline
        rows={4}
        fullWidth
        error={validation.messages?.description?.length > 0}
        helperText={validation.messages?.description?.join(', ')}
        value={info.description || ''}
        onChange={handleChangeDescription}
      />

      <Autocomplete
        multiple
        id="tags-filled"
        name="tags"
        loading={isTagsLoading}
        options={tags.map((option) => option.name)}
        fullWidth
        freeSolo
        onChange={handleChangeTags}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={`tag-option-${index}`}
              variant="standard"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        value={info.tags}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Tags"
            placeholder="Tags"
            error={validation.messages?.tags?.length > 0}
            helperText={validation.messages?.tags?.join(', ')}
          />
        )}
      />
    </Stack>
  )
}

export default Info

Info.propTypes = {
  setIsValid: PropTypes.func,
  onChange: PropTypes.func
}
