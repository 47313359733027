import React, { useState, useEffect } from 'react'
import { Autocomplete, Button, TextField, Stack } from '@mui/material'
import { t } from 'i18next'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useRoles } from '../../Roles/Contexts/RolesContext.jsx'
import { useUsers } from '../Contexts/UsersContext.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

const UpdateUser = ({ isOpen, selectedUser, onClose }) => {
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const { useFetchRolesStandardQuery } = useRoles()
  const { useUpdateUserRoleAndOrganization } = useUsers()
  const { currentUser } = useAuth()

  const [rolesSuggestions, setRolesSuggestions] = useState([])
  const [isRolesSuggestionsLoading, setIsRolesSuggestionsLoading] = useState(false)
  const [isRolesSuggestionsError, setIsRolesSuggestionsError] = useState(false)
  const [user, setUser] = useState({
    organization: selectedUser?.organization,
    role: selectedUser?.role
  })

  useEffect(() => {
    setIsRolesSuggestionsLoading(true)
    useFetchRolesStandardQuery()
      .then((res) => {
        setRolesSuggestions(res.data)
        setIsRolesSuggestionsLoading(false)
      })
      .catch(() => setIsRolesSuggestionsError(true))
  }, [])

  const handleChangeOrganization = (_, value) => setUser({ ...user, organization: value })

  const handleChangeRole = (_, value) => setUser({ ...user, role: value })

  const handleSave = () => {
    useUpdateUserRoleAndOrganization({
      uid: selectedUser.uid,
      currentOrganizationKey: selectedUser.organization.key,
      roleId: user.role.id,
      organizationKey: user.organization.key
    })
      .then(() => {
        onClose()
        openSuccessAlert()
      })
      .catch((err) => openErrorAlert({ keys: err.data }))
  }

  useEffect(
    () =>
      setUser({
        organization: selectedUser?.organization,
        role: selectedUser?.role
      }),
    [selectedUser]
  )

  useEffect(() => {
    if (user.organization === null) {
      setUser({
        ...user,
        organization: currentUser.selfOrganizations.find((o) => o.key === currentUser.currentOrganizationKey)
      })
    }
  }, [currentUser.selfOrganizations])

  useEffect(() => {
    if (user.role === null && rolesSuggestions.length) {
      setUser({
        ...user,
        role: rolesSuggestions.find((r) => r.name === 'viewer')
      })
    }
  }, [rolesSuggestions])

  return (
    <ZDialog
      id={'user'}
      isOpen={isOpen}
      title={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
      maxWidth="sm"
      content={
        <>
          <Autocomplete
            id="update-user-change-organization-autocomplete"
            name="organization"
            disableClearable
            options={currentUser.selfOrganizations || []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option && value && option.key === value.key}
            value={user.organization ? user.organization : selectedUser?.organization}
            onChange={handleChangeOrganization}
            renderInput={(params) => (
              <TextField
                name="organization"
                {...params}
                label={t('invites.chooseOrganization')}
                variant="standard"
                helperText={t('common.organization')}
              />
            )}
          />

          <Autocomplete
            id="change-user-role"
            name="role"
            disableClearable
            loading={isRolesSuggestionsLoading}
            options={rolesSuggestions || []}
            disabled={isRolesSuggestionsError}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
            value={user.role ? user.role : selectedUser?.role}
            onChange={handleChangeRole}
            renderInput={(params) => (
              <TextField
                name="role"
                {...params}
                label={t('invites.chooseRole')}
                variant="standard"
                helperText={t('common.role')}
              />
            )}
            sx={{ marginTop: 3 }}
          />
        </>
      }
      actions={
        <Stack
          direction="row"
          spacing={2}>
          <Button
            id="update-user-save-button"
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ marginTop: 3 }}>
            {t('common.save')}
          </Button>
          <Button
            id="update-user-cancel-button"
            variant="contained"
            onClick={onClose}>
            {t('common.cancel')}
          </Button>
        </Stack>
      }
      onClose={onClose}
    />
  )
}

export default UpdateUser
