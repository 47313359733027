import { useAuth } from '../components/Shared/Contexts/AuthContext'

const useUserPermission = (action) => {
  const {
    currentUser: { permissions, currentOrganizationKey }
  } = useAuth()

  if (permissions?.[currentOrganizationKey]?.includes(action)) {
    return true
  } else {
    return false
  }
}

export default useUserPermission
