import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import AddDevice from '../Dialogs/AddDevice.jsx'
import DeviceFilters from '../Filters/DevicesFilters.jsx'
import DevicesTable from '../Tables/Table.jsx'
import TimePicker from '../../Layout/TimePicker/TimePicker.jsx'
import {
  DownloadRounded as DownloadRoundedIcon,
  UploadRounded as UploadRoundedIcon,
  AddCircleRounded as AddCircleRoundedIcon,
  ArticleRounded as ArticleRoundedIcon,
  LeaderboardRounded as LeaderboardRoundedIcon
} from '@mui/icons-material'
import sanitizer from '../../../helpers/sanitizer.js'
import ImportDevices from '../Dialogs/ImportDevices.jsx'
import Can from '../../Layout/Can/Can.jsx'
import UpdateDevice from '../Dialogs/UpdateDevice'
import SendAction from '../Dialogs/SendAction.jsx'
import Actions from '../Dialogs/Actions'
import { useSearchParams } from 'react-router-dom'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog'
import { DeleteRounded as DeleteRoundedIcon } from '@mui/icons-material'
import Show from '../../Layout/Can/Show.jsx'
import moment from 'moment-timezone'
import { useDevices } from '../Contexts/DevicesContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import AlarmedFilter from '../../Shared/Filters/Fields/AlarmedFilter.jsx'

const DEFAULT_PAGE_SIZE = 25

const Devices = () => {
  const { useDeleteDevice, useFetchDevices, useExportDevicesMeasurementsDownload, useExportDevicesStatisticsDownload } = useDevices()
  const { devicesFilters: filters, setDevicesFilters, openErrorAlert } = useGlobalContext()

  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const [timePicker, setTimePicker] = useState([moment().subtract(3, 'days').startOf('day').toISOString(), moment().endOf('day').toISOString()])

  const [openExportMeasuresMenu, setOpenExportMeasuresMenu] = useState(false)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [selectedAll, setSelectedAll] = useState(false)
  const [isAddDeviceOpen, setIsAddDeviceOpen] = useState(false)
  const [isImportDeviceDialogOpen, setIsImportDeviceDialogOpen] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [deviceUpdateIsOpen, setDeviceUpdateIsOpen] = useState(false)
  const [isActionDialogOpen, setIsActionDialogOpen] = useState(false)
  const [isSendActionOpen, setIsSendActionOpen] = useState(false)
  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)
  const [devices, setDevices] = useState({ data: [], count: 0 })
  const [isDevicesError, setIsDevicesError] = useState(false)
  const [isDevicesLoading, setIsDevicesLoading] = useState(false)
  const [isDeleteDeviceSuccess, setIsDeleteDeviceSuccess] = useState(false)
  const [page, setPage] = useState(1)

  const [{ sort, direction }, setSorting] = useState({
    direction: 'desc',
    sort: 'lastMeasurementAt'
  })

  const [isBackdropOpen, setIsBackdropOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (!fullText || !fullText.length) {
      return
    }
    setIsDevicesLoading(true)
    useFetchDevices({
      filters: { ...filters, fullText },
      sort,
      direction,
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
      .then((res) => setDevices({ data: res.data, count: res.count }))
      .catch(() => setIsDevicesError(true))
      .finally(() => setIsDevicesLoading(false))
    setPage(1)
  }, [fullText, sort, direction, isDeleteDeviceSuccess, filters])

  const fetchDevices = async () => {
    useFetchDevices({
      filters: { ...filters, fullText },
      sort,
      direction,
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
      .then((res) => setDevices({ data: res.data, count: res.count }))
      .catch(() => setIsDevicesError(true))
      .finally(() => setIsDevicesLoading(false))
  }

  useEffect(() => {
    setIsDevicesLoading(true)
    fetchDevices()
  }, [filters, page])

  const handleDevicesFiltersChange = (filters) => setDevicesFilters(filters)

  const handleSelectedDevicesChanged = (devices) => setSelectedDevices(devices)

  const handleSelectedAllChanged = (value) => setSelectedAll(value)

  const handleChangePage = (_, value) => setPage(value)

  const handleChangeSorting = (field) =>
    setSorting({
      sort: field,
      direction: sorting.direction === 'asc' ? 'desc' : 'asc'
    })

  const handleTimePickerChange = (values) => setTimePicker(values)

  const handleCloseConfirmWithCheck = () => setIsConfirmWithCheckOpen(false)

  const handleConfirmAction = () => {
    useDeleteDevice({ id: selectedDevice.id })
      .then(() => {
        setIsDeleteDeviceSuccess(true)
        fetchDevices()
      })
      .catch((err) => {
        setDeleteDeviceError(err.data.keys)
        setIsDeleteDeviceError(true)
      })
    setIsConfirmWithCheckOpen(false)
  }

  const handleExportMeasuresClick = (event) => {
    setOpenExportMeasuresMenu(!openExportMeasuresMenu)
    setAnchorEl(event.currentTarget)
  }

  const handleExportMeasuresClose = () => {
    setOpenExportMeasuresMenu(false)
    setAnchorEl(null)
  }

  const handleExportSelectedMeasures = () => {
    if (!selectedDevices.length && !selectedAll) {
      return
    }

    const deviceFilters = JSON.parse(JSON.stringify(filters))
    delete deviceFilters.isAlarm
    const payload = {
      measuresFilters: {
        from: timePicker[0],
        to: timePicker[1],
        devices: [...selectedDevices]
      },
      devicesFilters: sanitizer(deviceFilters),
      selectedAll
    }

    setIsBackdropOpen(true)

    useExportDevicesMeasurementsDownload({ payload })
      .catch(() => openErrorAlert({ keys: ['download'] }))
      .finally(() => setIsBackdropOpen(false))
  }

  const handleExportStatistics = () => {
    if (!selectedDevices.length && !selectedAll) {
      return
    }

    const deviceFilters = JSON.parse(JSON.stringify(filters))
    delete deviceFilters.isAlarm

    const payload = {
      measuresFilters: {
        from: timePicker[0],
        to: timePicker[1],
        devices: [...selectedDevices]
      },
      devicesFilters: sanitizer(deviceFilters),
      selectedAll
    }

    setIsBackdropOpen(true)

    useExportDevicesStatisticsDownload({ payload })
      .catch(() => openErrorAlert({ keys: ['download'] }))
      .finally(() => setIsBackdropOpen(false))
  }

  const handleEdit = (device) => {
    setSelectedDevice(device)
    setDeviceUpdateIsOpen(true)
  }

  const handleDelete = (device) => {
    setSelectedDevice(device)
    setIsConfirmWithCheckOpen(true)
  }

  const handleSendAction = (device) => {
    setIsActionDialogOpen(false)
    setIsSendActionOpen(true)
    setSelectedDevice(device)
  }

  const handleSendActionIsOpen = (isOpen) => {
    setIsSendActionOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleAction = (device) => {
    setIsActionDialogOpen(true)
    setSelectedDevice(device)
  }

  const handleSetIsOpenActionDialogOpen = (isOpen) => {
    setIsActionDialogOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleCloseAddDevice = ({ success }) => {
    if (success) {
      fetchDevices()
    }
    setIsAddDeviceOpen(false)
  }

  const handleAlarmedFilterChange = (value) => setDevicesFilters({ ...filters, ...value })

  return (
    <Container sx={{ py: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Show when={() => !!devices}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
            <Typography variant="h2">{t('common.devices')}</Typography>
            <Chip
              label={devices?.count}
              color="primary"
            />
          </Stack>
        </Show>

        <Stack
          direction={'row'}
          justifyContent={'space-around'}
          spacing={2}>
          <TimePicker
            onChange={handleTimePickerChange}
            timePicker={timePicker}
          />
          <Box>
            <IconButton
              id="basic-button"
              aria-controls={openExportMeasuresMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleExportMeasuresClick}>
              <DownloadRoundedIcon />
            </IconButton>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openExportMeasuresMenu}
              onClose={handleExportMeasuresClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}>
              <MenuItem
                onClick={handleExportSelectedMeasures}
                disabled={!selectedDevices.length && !selectedAll}>
                <ListItemIcon>
                  <ArticleRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText id="export-devices-measures-button">{t('devices.exportMeasures')}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={handleExportStatistics}
                disabled={!selectedDevices.length && !selectedAll}>
                <ListItemIcon>
                  <LeaderboardRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText id="export-devices-statistics-button">{t('devices.exportStatistics')}</ListItemText>
              </MenuItem>
            </Menu>
          </Box>

          <Can action="devices_c">
            <Box>
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => setIsImportDeviceDialogOpen(true)}>
                <UploadRoundedIcon />
              </IconButton>
            </Box>
          </Can>

          <Can action="devices_c">
            <Box>
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => setIsAddDeviceOpen(true)}>
                <AddCircleRoundedIcon />
              </IconButton>
            </Box>
          </Can>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={4}>
        <AlarmedFilter
          filters={filters}
          onChange={handleAlarmedFilterChange}
        />
      </Stack>

      <DeviceFilters
        itemsCount={devices?.count}
        filters={filters}
        onChange={handleDevicesFiltersChange}
      />

      <DevicesTable
        onSelectedDevicesChanged={handleSelectedDevicesChanged}
        onSelectAllChanged={handleSelectedAllChanged}
        onPageChange={handleChangePage}
        onSortingChange={handleChangeSorting}
        page={page}
        sorting={{ sort, direction }}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onAction={handleAction}
        isLoading={isDevicesLoading || isDevicesError}
        devices={devices}
      />
      <AddDevice
        isOpen={isAddDeviceOpen}
        onClose={handleCloseAddDevice}
      />
      {isImportDeviceDialogOpen && (
        <ImportDevices
          isOpen={isImportDeviceDialogOpen}
          setIsOpen={setIsImportDeviceDialogOpen}
        />
      )}
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isBackdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Show when={() => !!selectedDevice}>
        <ConfirmWithCheckDialog
          onClose={handleCloseConfirmWithCheck}
          icon={
            <DeleteRoundedIcon
              size={60}
              color="#ef5350"
            />
          }
          onConfirmAction={handleConfirmAction}
          questionText={t('devices.deleteDevice.title')}
          subQuestionText={selectedDevice?.name}
          copyText={selectedDevice?.id}
          bodyText={t('devices.deleteDevice.subtitle')}
          inputSuggestionText={t('devices.deleteDevice.helper')}
          isOpen={isConfirmWithCheckOpen}
        />
      </Show>

      <Can action="devices_u">
        {deviceUpdateIsOpen && (
          <UpdateDevice
            deviceId={selectedDevice.id}
            isOpen={deviceUpdateIsOpen}
            setIsOpen={() => setDeviceUpdateIsOpen(false)}
          />
        )}
        {isActionDialogOpen && (
          <Actions
            onSendAction={handleSendAction}
            isOpen={isActionDialogOpen}
            setIsOpen={handleSetIsOpenActionDialogOpen}
            device={selectedDevice}
          />
        )}
        {isSendActionOpen && (
          <SendAction
            device={selectedDevice}
            isOpen={isSendActionOpen}
            setIsOpen={handleSendActionIsOpen}
          />
        )}
      </Can>
    </Container>
  )
}

export default Devices
