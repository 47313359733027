import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'

const TriggerTypeFilter = ({ filters = {}, onChange = () => {} }) => {
  const filterOptions = [
    { label: t('triggers.types.communication'), value: 'communication' },
    { label: t('triggers.types.threshold'), value: 'threshold' },
    { label: t('triggers.types.delta'), value: 'delta' },
    { label: t('triggers.types.trigger'), value: 'trigger' },
    { label: t('triggers.types.scheduledTrigger'), value: 'scheduled-trigger' },
    {
      label: t('triggers.types.measure-communication'),
      value: 'measure-communication'
    },
    { label: t('triggers.types.measure-trigger'), value: 'measure-trigger' }
  ]

  const handleChange = (_, data) => {
    onChange({ triggerTypes: !data.value ? [] : [data.value] })
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('triggers.triggerType')}
        </Typography>
        <Autocomplete
          id="action-type-filter"
          options={filterOptions}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.value === value}
          getOptionLabel={(option) => option?.label || ''}
          value={
            filters?.triggerTypes
              ? filterOptions.find(
                  (f) => f.value === filters?.triggerTypes[0]
                ) || ''
              : ''
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            />
          )}
        />
      </Stack>
    </>
  )
}

TriggerTypeFilter.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default TriggerTypeFilter
