import { Divider } from '@mui/material'
import React from 'react'
import {
  BrandsFilter,
  ModelsFilter,
  TriggerTypeFilter,
  ActionTypeFilter,
  IsEnabledFilter
} from './index.js'

const RulesOthersFilters = ({ filters = {}, onChange = () => {} }) => {
  return (
    <>
      <IsEnabledFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <BrandsFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <ModelsFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <TriggerTypeFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <ActionTypeFilter
        filters={filters}
        onChange={onChange}
      />
    </>
  )
}

export default RulesOthersFilters
