import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { t } from 'i18next'
import Can from '../../Layout/Can/Can.jsx'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import RolesFilter from './Filters/RolesFilter.jsx'
import { OrganizationsFilter } from '../../Shared/Filters/Fields/index.js'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'

const Filters = ({ onChange, filters }) => {
  const [selectedRoles, setSelectedRoles] = useState(false)
  const [selectedOrganizations, setSelectedOrganizations] = useState(false)

  const { authState } = useAuth()

  const handleOnDeleteRoles = () => {
    onChange({ ...filters, roleIds: [] })
    setSelectedRoles(false)
  }

  const handleOnDeleteOrganizations = () => {
    onChange({
      ...filters,
      organizationKeys: [],
      searchInSubOrganizations: false
    })
    setSelectedOrganizations(false)
  }

  const handleChangeRoles = (data) => {
    setSelectedRoles(true)
    onChange({ ...filters, ...data })
  }

  const handleChangeOrganizations = (data) => {
    setSelectedOrganizations(true)
    onChange({ ...filters, ...data })
  }

  const filterUnits = [
    {
      label: t('common.role'),
      selected: selectedRoles,
      onDelete: handleOnDeleteRoles,
      content: (
        <RolesFilter
          onChange={handleChangeRoles}
          filters={filters}
        />
      ),
      count: <></>
    },
    {
      label: t('common.organization'),
      selected: selectedOrganizations,
      onDelete: handleOnDeleteOrganizations,
      hidden: !authState.isAuthenticated,
      content: (
        <OrganizationsFilter
          onChange={handleChangeOrganizations}
          filters={filters}
        />
      ),
      count: <></>
    }
  ]

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {[...filterUnits].map((e, i) => (
          <Can
            action="users_r"
            key={`user-filter-${i}`}
          >
            <Filter
              id={`button-filter-${e.label}`}
              key={`user-filter-${i}`}
              onClick={e.onClick}
              onDelete={e.onDelete}
              selected={e.selected}
              title={e.label}
              hidden={e.hidden || false}
              content={e.content}
              count={e.count}
            />
          </Can>
        ))}
      </Stack>
    </>
  )
}

Filters.propTypes = {
  filters: PropTypes.shape({
    organizationKeys: PropTypes.array,
    roleIds: PropTypes.array
  })
}

export default Filters
