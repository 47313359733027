import {
  Autocomplete,
  Box,
  FormControlLabel,
  Grid2,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Tag as TagIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { useFilters } from '../../Contexts/FiltersContext'

const TagsFilter = ({ filters = {}, onChange }) => {
  const { useFetchTagsFilters } = useFilters()
  const [tagsFilterOptions, setTagsFilterOptions] = useState([])

  useEffect(() => {
    useFetchTagsFilters().then(({ data }) => setTagsFilterOptions(data))
  }, [])

  const handleChange = (_, value) => {
    if (!Array.isArray(value)) {
      return
    }

    onChange({
      tags: value.map((i) => i.id)
    })
  }

  const handleOperatorChange = (_, value) => {
    onChange({
      tagSearchMode: value ? 'AND' : null
    })
  }

  return (
    <>
      <Typography
        align="left"
        sx={{ fontWeight: 'bold' }}
      >
        {t('filters.devices.other.tags.label')}
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Grid2
          container
          spacing={1}
          display="flex"
          alignItems="center"
        >
          <Grid2 size={10}>
            <Autocomplete
              multiple
              id="tags-filter"
              options={tagsFilterOptions}
              onChange={handleChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              value={tagsFilterOptions.filter(({ id }) => {
                const { tags = [] } = filters
                return tags.includes(id)
              })}
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  divider
                  key={option.id}
                >
                  <ListItemIcon>
                    <TagIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '0.8rem',
                      fontWeight: 500
                    }}
                    primary={option.name}
                  />
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder={t('filters.devices.other.tags.placeholder')}
                  slotProps={{
                    ...params.InputProps
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size="grow">
            <FormControlLabel
              value="top"
              control={
                <Switch
                  onChange={handleOperatorChange}
                  checked={filters?.tagSearchMode === 'AND'}
                />
              }
              labelPlacement="top"
              label={filters?.tagSearchMode === 'AND' ? 'And' : 'Or'}
            />
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}

export default TagsFilter
