import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import {
  typeToInt,
  typeToString
} from '../../../helpers/lorawanFieldsHelper.js'

import { Deveui, Appeui, Appkey, Appskey, Nwkskey, Devaddr } from './Fields'
import { useAuth } from '../../Shared/Contexts/AuthContext'
import { useDevices } from '../Contexts/DevicesContext'
import useFieldValidation from '../../../helpers/fieldValidation.js'
import Joi from 'joi'
import { useValidationEffect } from '../../../helpers/hooks.js'

const UpdateNetwork = ({ deviceData, onChange }) => {
  const {
    currentUser: { isSuperAdmin, currentOrganizationKey }
  } = useAuth()
  const {
    useFetchNetworkServers,
    useFetchNetworkServerUsers,
    useFetchNetworkServerApplications
  } = useDevices()

  const [network, setNetwork] = useState()
  const [networkType, setNetworkType] = useState()
  const [validation, setValidation] = useState({ isValid: false })
  const [networkServers, setNetworkServers] = useState([])
  const [networkServerUsers, setNetworkServerUsers] = useState()
  const [networkServerApplications, setNetworkServerApplications] = useState()

  const getValidationSchema = ({ device, sourceData }) => {
    switch (device.source.type) {
      case 'nbiot':
        return Joi.object({
          imsi: Joi.string()
            .pattern(/^[0-9]+$/)
            .length(16)
        }).unknown()

      case 'wmbus':
        return Joi.object({
          wmbusId: Joi.string().hex().length(16)
        }).unknown()

      case 'mqtt':
        return Joi.object({
          mqttId: Joi.string().required()
        }).unknown()

      default:
        return typeToString(sourceData?.device?.type) === 'OTAA'
          ? Joi.object({
              networkServerId: Joi.string().required(),
              userId: Joi.any().optional(),
              appId: Joi.any().optional(),
              profile: Joi.any().optional(),
              label: Joi.any().optional(),
              info: Joi.any().optional(),
              type: Joi.number().required(),
              devEui: Joi.string()
                .hex()
                .length(16)
                .required()
                .invalid('0000000000000000'),
              joinEui: Joi.string().hex().length(16).required(),
              appKey: Joi.string().hex().length(32).required()
            }).unknown()
          : Joi.object({
              networkServerId: Joi.string().required(),
              userId: Joi.any().optional(),
              appId: Joi.any().optional(),
              profile: Joi.any().optional(),
              label: Joi.any().optional(),
              info: Joi.any().optional(),
              type: Joi.number().required(),
              devEui: Joi.string()
                .hex()
                .length(16)
                .required()
                .invalid('0000000000000000'),
              appSKey: Joi.string().hex().length(32).required(),
              nwkSKey: Joi.string().hex().length(32).required(),
              devaddr: Joi.string().hex().length(8).required()
            }).unknown()
    }
  }

  const fetchNetworkServerApplications = ({ userId, networkServerId }) => {
    useFetchNetworkServerApplications({
      networkServer: networkServerId,
      nsUserId: userId
    }).then((res) => setNetworkServerApplications(res.data))
  }

  const fillLorawanFields = (sourceData) => {
    if (!sourceData) {
      return
    }

    const { device, networkServerId } = sourceData

    useFetchNetworkServerUsers({ networkServer: networkServerId }).then((res) =>
      setNetworkServerUsers(res.data)
    )

    fetchNetworkServerApplications({ userId: device.userId, networkServerId })

    const fields =
      typeToString(device.type) === 'ABP'
        ? {
            devEui: device.devEui?.toLowerCase(),
            appSKey: device.appSKey?.toLowerCase(),
            nwkSKey: device.nwkSKey?.toLowerCase(),
            devaddr: device.devaddr?.toLowerCase()
          }
        : {
            devEui: device.devEui?.toLowerCase(),
            joinEui: device.joinEui?.toLowerCase(),
            appKey: device.appKey?.toLowerCase()
          }
    setNetwork({
      device: {
        userId: device.userId,
        appId: device.appId,
        profile: device.profile,
        label: device.label,
        type: typeToInt(device.type),
        info: {
          firmware: device.info.firmware,
          model: device.info.model,
          position: device.info.position
        },
        ...fields
      },
      networkServerId: networkServerId
    })

    setNetworkType(typeToString(device.type))
  }
  const fillWmbusFields = (deviceData) => {
    const { sourceKey } = deviceData
    setNetwork({ wmbusId: sourceKey.wmbusid })
  }
  const fillNbiotFields = (deviceData) => {
    const { sourceKey } = deviceData
    setNetwork({ imsi: sourceKey.imsi })
  }
  const fillMqttFields = (deviceData) => {
    const { sourceKey } = deviceData
    setNetwork({ mqttId: sourceKey.mqttid })
  }

  useEffect(() => {
    useFetchNetworkServers(currentOrganizationKey).then((res) =>
      setNetworkServers(res.data)
    )
  }, [])

  useValidationEffect(() => {
    if (!network) {
      return
    }

    const schema = getValidationSchema({
      ...deviceData,
      sourceData: { device: network.device }
    })

    const validateNetwork = !!network.device
      ? { ...network.device, networkServerId: network.networkServerId }
      : network
    const newValidation = useFieldValidation(validateNetwork, schema)

    setValidation(newValidation)

    onChange(network, newValidation.isValid)
  }, [network])

  useEffect(() => {
    const {
      device: {
        source: { type }
      }
    } = deviceData
    switch (type) {
      case 'wmbus':
        fillWmbusFields(deviceData)
        break
      case 'nbiot':
        fillNbiotFields(deviceData)
        break
      case 'mqtt':
        fillMqttFields(deviceData)
        break
      default:
        fillLorawanFields(deviceData.sourceData)
        break
    }
  }, [deviceData])

  const handleNetworkServerChange = ({ target: { value, name } }) => {
    setNetwork({
      ...network,
      [name]: value
    })
  }

  const handleChange = (event) => {
    setNetwork({
      ...network,
      device: {
        ...network.device,
        [event.target.name]: event.target.value?.toLowerCase()
      }
    })
  }

  const handleWmbusChange = (event) => {
    setNetwork({
      ...network,
      [event.target.name]: event.target.value
    })
  }

  const handleNbIotChange = (event) => {
    setNetwork({
      ...network,
      [event.target.name]: event.target.value
    })
  }

  const handleMqttChange = (event) => {
    setNetwork({
      ...network,
      [event.target.name]: event.target.value
    })
  }

  const handleChangeType = ({ target: { value, name } }) => {
    const resetFields = {
      OTAA: () => ({ appSKey: '', nwkSKey: '', devaddr: '' }),
      ABP: () => ({ joinEui: '', appKey: '' })
    }

    const nwkparams = resetFields[value]()

    setNetworkType(value)

    setNetwork({
      ...network,
      device: {
        ...network.device,
        ...nwkparams,
        type: typeToInt(value)
      }
    })
  }

  const handleChangeApplication = (_, value) => {
    if (!value) return

    setNetwork({
      ...network,
      device: {
        ...network.device,
        appId: value.id
      }
    })
  }

  const handleChangeUser = (_, value) => {
    if (!value) return

    fetchNetworkServerApplications({
      userId: value.userId,
      networkServerId: network.networkServerId
    })

    setNetwork({
      ...network,
      device: {
        ...network.device,
        appId: null,
        userId: value.userId,
        user: value.user
      }
    })
  }

  const userToString = (user) => {
    return `${user.isCityEyeUser ? `[CityEye] ${user.user}` : user.user}`
  }

  return (
    <>
      {deviceData.device.source.type !== 'http' && (
        <>
          <Typography
            variant="h2"
            py={1}
          >
            {t('devices.addNetwork')}
          </Typography>

          {deviceData.device.source.type === 'lorawan' && (
            <>
              <Stack
                direction="row"
                spacing={2}
                my={5}
              >
                <FormControl fullWidth>
                  <InputLabel htmlFor="Network-NetworkServer">
                    {t('devices.chooseNetworkServer')}
                  </InputLabel>
                  {networkServers.length > 0 && (
                    <Select
                      id="Network-NetworkServer"
                      name="networkServerId"
                      variant="standard"
                      value={network?.networkServerId || ''}
                      onChange={handleNetworkServerChange}
                      error={validation?.messages?.networkServerId?.length > 0}
                    >
                      {networkServers.map((o, i) => (
                        <MenuItem
                          value={o.server}
                          key={i}
                        >
                          {o.server}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  <FormHelperText>
                    {validation?.messages?.networkServerId?.join(', ')}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel htmlFor="Network-NetworkType">
                    {t('devices.chooseLoraType')}
                  </InputLabel>
                  <Select
                    id="Network-NetworkType"
                    name="networkType"
                    variant="standard"
                    value={typeToString(networkType) || ''}
                    onChange={handleChangeType}
                    error={validation?.messages?.networkType?.length > 0}
                  >
                    <MenuItem value="OTAA">OTAA</MenuItem>
                    <MenuItem value="ABP">ABP</MenuItem>
                  </Select>
                  <FormHelperText>
                    {validation?.messages?.networkType?.join(', ')}
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
              >
                <TextField
                  id="Network-devEUI"
                  name="devEui"
                  fullWidth
                  label="Device EUI"
                  variant="standard"
                  onChange={handleChange}
                  value={network?.device?.devEui || ''}
                  error={validation?.messages?.devEui?.length > 0}
                  helperText={validation?.messages?.devEui?.join(', ')}
                  slotProps={{ inputComponent: Deveui }}
                />
              </Stack>

              {networkType === 'OTAA' && (
                <Stack
                  direction="row"
                  spacing={2}
                  mt={5}
                >
                  <TextField
                    id="Network-joinEUI"
                    name="joinEui"
                    fullWidth
                    label="Join EUI / Application EUI"
                    variant="standard"
                    onChange={handleChange}
                    value={network?.device?.joinEui || ''}
                    error={validation?.messages?.joinEui?.length > 0}
                    helperText={validation?.messages?.joinEui?.join(', ')}
                    slotProps={{ inputComponent: Appeui }}
                  />
                  <TextField
                    id="Network-appKey"
                    name="appKey"
                    fullWidth
                    label="Application KEY"
                    variant="standard"
                    onChange={handleChange}
                    value={network?.device?.appKey || ''}
                    error={validation?.messages?.appKey?.length > 0}
                    helperText={validation?.messages?.appKey?.join(', ')}
                    slotProps={{ inputComponent: Appkey }}
                  />
                </Stack>
              )}

              {networkType === 'ABP' && (
                <>
                  <Stack
                    direction="row"
                    spacing={2}
                    mt={5}
                  >
                    <TextField
                      id="Network-appSKey"
                      name="appSKey"
                      fullWidth
                      label="Application session KEY"
                      variant="standard"
                      onChange={handleChange}
                      value={network?.device?.appSKey || ''}
                      error={validation?.messages?.appSKey?.length > 0}
                      helperText={validation?.messages?.appSKey?.join(', ')}
                      slotProps={{ inputComponent: Appskey }}
                    />
                    <TextField
                      id="Network-nwkSkey"
                      name="nwkSKey"
                      fullWidth
                      label="Network session KEY"
                      variant="standard"
                      onChange={handleChange}
                      value={network?.device?.nwkSKey || ''}
                      error={validation?.messages?.nwkSKey?.length > 0}
                      helperText={validation?.messages?.nwkSKey?.join(', ')}
                      slotProps={{ inputComponent: Nwkskey }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                  >
                    <TextField
                      id="Network-devaddr"
                      name="devaddr"
                      sx={{ width: 420 }}
                      label={t('devices.deviceAddress')}
                      variant="standard"
                      onChange={handleChange}
                      value={network?.device.devaddr || ''}
                      error={validation?.messages?.devaddr?.length > 0}
                      helperText={validation?.messages?.devaddr?.join(', ')}
                      slotProps={{ inputComponent: Devaddr }}
                    />
                  </Stack>
                </>
              )}

              {isSuperAdmin &&
                networkServerUsers &&
                network?.device?.userId && (
                  <Stack
                    direction={'row'}
                    spacing={2}
                    mt={5}
                  >
                    <Autocomplete
                      id="netowrkServerUserAutocomplete"
                      name="networkServerUserAutocomplete"
                      sx={{ width: '50%' }}
                      options={networkServerUsers || []}
                      getOptionLabel={userToString}
                      isOptionEqualToValue={(option, value) =>
                        option.userId === value
                      }
                      onChange={handleChangeUser}
                      value={
                        network?.device?.userId
                          ? networkServerUsers.find(
                              (x) => x.userId === network?.device?.userId
                            )
                          : ''
                      }
                      renderInput={(params) => (
                        <TextField
                          name="networkServerUserAutocompleteText"
                          {...params}
                          label={t('devices.selectAUser')}
                          variant="standard"
                        />
                      )}
                    />
                    <Autocomplete
                      id="netowrkServerApplicationAutocomplete"
                      name="netowrkServerApplicationAutocomplete"
                      sx={{ width: '50%' }}
                      options={networkServerApplications || []}
                      value={
                        network?.device?.appId && networkServerApplications
                          ? networkServerApplications.find(
                              (x) => x.id === network?.device?.appId
                            )
                          : { id: '', label: '' }
                      }
                      getOptionLabel={(option) =>
                        `${option.id ? `[${option.id}] ${option.label}` : ''} `
                      }
                      onChange={handleChangeApplication}
                      renderInput={(params) => (
                        <TextField
                          name="netowrkServerApplicationText"
                          {...params}
                          label={t('devices.selectAnApplication')}
                          variant="standard"
                        />
                      )}
                    />
                  </Stack>
                )}
            </>
          )}

          {deviceData.device.source.type === 'wmbus' && (
            <Stack
              direction="row"
              spacing={2}
              mt={5}
            >
              <TextField
                id="Network-wmbus-id"
                name="wmbusId"
                sx={{ width: 420 }}
                label="Wmbus ID"
                variant="standard"
                onChange={handleWmbusChange}
                error={validation?.messages?.wmbusId?.length > 0}
                helperText={validation?.messages?.wmbusId?.join(', ')}
                value={network?.wmbusId || ''}
              />
            </Stack>
          )}

          {deviceData.device.source.type === 'nbiot' && (
            <Stack
              direction="row"
              spacing={2}
              mt={5}
            >
              <TextField
                id="Network-imsi"
                name="imsi"
                sx={{ width: 420 }}
                label="imsi"
                variant="standard"
                onChange={handleNbIotChange}
                error={validation?.messages?.imsi?.length > 0}
                helperText={validation?.messages?.imsi?.join(', ')}
                value={network?.imsi || ''}
              />
            </Stack>
          )}

          {deviceData.device.source.type === 'mqtt' && (
            <Stack
              direction="row"
              spacing={2}
              mt={5}
            >
              <TextField
                id="network-mqtt"
                name="mqttId"
                sx={{ width: 420 }}
                label="MQTT Id"
                variant="standard"
                onChange={handleMqttChange}
                error={validation?.messages?.mqttId?.length > 0}
                helperText={validation?.messages?.mqttId?.join(', ')}
                value={network?.mqttId || ''}
              />
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default UpdateNetwork
