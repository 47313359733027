import {
  Box,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import moment from 'moment'
import React from 'react'
import { t } from 'i18next'
import EventDuration from '../EventDuration/EventDuration.jsx'
import { WarningRounded as WarningRoundedIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Show from '../../Layout/Can/Show.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

const EventsTable = ({ events, onPageChange, paging }) => {
  const navigate = useNavigate()
  const { language } = useGlobalContext()
  moment.locale(language)

  const { page, pageSize } = paging

  const handleChangePage = (_, value) => onPageChange(value)

  const handleClickDevice = (device) => {
    navigate(`/detail/${device.id}`)
    if (device.position.latitude && device.position.longitude) {
      setMap({
        lat: device.position.latitude,
        lng: device.position.longitude,
        zoom: 20
      })
    }
  }

  return (
    <>
      <TableContainer component={Box}>
        <Table aria-label="events table">
          <TableHead>
            <TableRow>
              <TableCell component="th"></TableCell>
              <TableCell component="th">{t('common.startedAt')}</TableCell>
              <TableCell component="th">{t('common.endedAt')}</TableCell>
              <TableCell component="th">{t('common.device')}</TableCell>
              <TableCell component="th">{t('common.rule')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events?.data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  {row.isAlarm && !row.endedAt && (
                    <WarningRoundedIcon
                      size={16}
                      color="red"
                    />
                  )}
                  {row.isAlarm && row.endedAt && (
                    <WarningRoundedIcon
                      size={16}
                      color="grey"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="p">
                    {moment(row.startedAt).format('DD/MM/YY HH:mm:ss')}
                  </Typography>
                  <Typography variant="body2">
                    <small>{moment(row.startedAt).fromNow()}</small>
                  </Typography>
                </TableCell>
                <TableCell>
                  {row.endedAt && (
                    <>
                      <Typography variant="p">
                        {moment(row.endedAt).format('DD/MM/YY HH:mm:ss')}
                      </Typography>

                      <EventDuration
                        start={row.startedAt}
                        end={row.endedAt}
                      />
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {row.device.brand.name}/{row.device.model.name}
                  </Typography>

                  <Link
                    variant="subtitle2"
                    href="#"
                    underline="hover"
                    onClick={() => handleClickDevice(row.device)}
                  >
                    <b>{row.device.name}</b>
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography variant="p">{row.rule?.name}</Typography>
                  <Typography
                    id="rule-name-event"
                    variant="body2">
                    <small>{row.type}</small>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {events && (
        <Box
          display="flex"
          justifyContent="center"
        >
          <Pagination
            count={Math.ceil(events?.count / pageSize)}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </>
  )
}

export default EventsTable
