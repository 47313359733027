import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import React from 'react'
import { t } from 'i18next'

const GatewayStatus = ({ filters, onChange }) => {
  const technology = filters?.technology || []

  const handleChange = ({ target: { name } }) => {
    const newTechnology = technology.includes(name)
      ? technology.filter((s) => s !== name)
      : technology.concat(name)
    onChange({ technology: newTechnology })
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.gateways.technology.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="Lorawan"
            control={
              <Checkbox
                name="Lorawan"
                onChange={handleChange}
                checked={technology.includes('Lorawan')}
              />
            }
            label={t('filters.gateways.technology.lorawan')}
          />
          <FormControlLabel
            value="Wmbus"
            control={
              <Checkbox
                name="Wmbus"
                onChange={handleChange}
                checked={technology.includes('Wmbus')}
              />
            }
            label={t('filters.gateways.technology.wmbus')}
          />
          <FormControlLabel
            value="Wmbus+Lorawan"
            control={
              <Checkbox
                name="Wmbus+Lorawan"
                onChange={handleChange}
                checked={technology.includes('Wmbus+Lorawan')}
              />
            }
            label={t('filters.gateways.technology.dual')}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

export default GatewayStatus
