import React, { useEffect, useState } from 'react'
import useFieldValidation from '../../../helpers/fieldValidation.js'

import {
  Button,
  Box,
  TextField,
  Typography,
  Stack,
  Card,
  CardContent
} from '@mui/material'
import { t } from 'i18next'

import Joi from 'joi'
import PropTypes from 'prop-types'
import { useValidationEffect } from '../../../helpers/hooks.js'
import Show from '../../Layout/Can/Show.jsx'
import InlineMessage from '../../Shared/Components/InlineMessage.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useUsers } from '../Contexts/UsersContext.jsx'

const schema = Joi.object({
  oldPassword: Joi.string().required(),
  newPassword: Joi.string()
    .pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)
    .message(`La passowrd non rispetta gli standard di sicurezza`)
    .required(),
  confirmPassword: Joi.string().required()
})

const UpdatePassword = ({ isOpen, handleClose = () => { } }) => {
  const { useChangePassword } = useUsers()

  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [validation, setValidation] = useState({ isValid: false })
  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [isError, setIsError] = useState(false)
  const [errorCode, setErrorCode] = useState(0)

  useValidationEffect(() => {
    const validationResults = useFieldValidation(
      { oldPassword, newPassword, confirmPassword },
      schema
    )

    setValidation(validationResults)
  }, [oldPassword, newPassword, confirmPassword])

  const handleSave = () => {
    useChangePassword({ oldPassword, newPassword }).then((res) => {
      if (res.isError) {
        setIsError(true)
        setErrorCode(res.status)
        return
      }
      handleClose(true)
    })
  }

  const handleOldPassword = ({ target: { value } }) => setOldPassword(value)

  const handleNewPassword = ({ target: { value } }) => {
    const passwordMatchValue = value === confirmPassword
    setPasswordsMatch(passwordMatchValue)
    setNewPassword(value)
  }

  const handleConfirmPassword = ({ target: { value } }) => {
    const passwordMatchValue = value === newPassword
    setPasswordsMatch(passwordMatchValue)
    setConfirmPassword(value)
  }

  return (
    <ZDialog
      id="change-password"
      isOpen={isOpen}
      title={t('users.changePassword')}
      content={
        <Box>
          <Stack spacing={3}>
            <TextField
              id="user-dialog-update-password-old-password"
              label="Password Attuale"
              name="oldPassword"
              variant="standard"
              type="password"
              error={validation?.messages?.oldPassword?.length > 0}
              helperText={validation?.messages?.oldPassword?.join(', ')}
              onChange={handleOldPassword}
              fullWidth
              required
            ></TextField>
            <TextField
              id="user-dialog-update-password-new-password"
              label="Nuova Password"
              name="newPassword"
              variant="standard"
              type="password"
              error={validation?.messages?.newPassword?.length > 0}
              helperText={validation?.messages?.newPassword?.join(', ')}
              onChange={handleNewPassword}
              fullWidth
              required
            ></TextField>
            <TextField
              id="user-dialog-update-password-confirm-password"
              label="Conferma Password"
              name="confirmPassword"
              variant="standard"
              type="password"
              error={validation?.messages?.confirmPassword?.length > 0}
              helperText={validation?.messages?.confirmPassword?.join(', ')}
              onChange={handleConfirmPassword}
              fullWidth
              required
            ></TextField>
          </Stack>
          {validation?.messages?.password?.length && (
            <Card
              variant="standard"
              sx={{
                width: '100%',
                background: 'rgba(229, 94, 194, 0.1)',
                marginTop: '10px'
              }}
            >
              <CardContent>
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  spacing={2}
                >
                  <Typography
                    variant="body2"
                    py={1}
                  >
                    {t('invites.passwordError')}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          )}
          {!passwordsMatch && (
            <Card
              variant="standard"
              sx={{
                width: '100%',
                background: 'rgba(229, 94, 194, 0.1)',
                marginTop: '10px'
              }}
            >
              <CardContent>
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  spacing={2}
                >
                  <Typography
                    variant="body2"
                    py={1}
                  >
                    {t('invites.correspondingPasswordError')}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          )}
          <Show when={() => isError && errorCode === 401}>
            <InlineMessage message={t('users.invalidOldPassword')} />
          </Show>
          <Show when={() => isError && errorCode !== 401}>
            <InlineMessage message={t('common.genericRequestError')} />
          </Show>
        </Box>
      }
      actions={
        <Box textAlign="center">
          <Stack
            direction="row"
            spacing={2}
          >
            <Button
              id="user-dialog-update-password-button-save"
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={!validation.isValid || !passwordsMatch}
              sx={{ marginTop: 3 }}
            >
              {t('common.save')}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleClose(false)}
            >
              {t('common.cancel')}
            </Button>
          </Stack>
        </Box>
      }
      onClose={() => handleClose(false)}
      maxWidth="sm"
    />
  )
}

UpdatePassword.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func
}

export default UpdatePassword
