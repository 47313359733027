import React, { createContext, useContext } from 'react'
import { useAuth } from '../../Shared/Contexts/AuthContext'

const InvitesContext = createContext({})

export const useInvites = () => {
  return useContext(InvitesContext)
}

export const InvitesProvider = ({ children }) => {
  const { useBaseAxiosGet, useBaseAxiosPost, useBaseAxiosDelete } = useAuth()

  const useFetchInvites = async ({ page, pageSize, fullText }) => {
    return await useBaseAxiosGet({
      url: `/invites?page?${page}&pageSize=${pageSize}${fullText ? `&fullText=${fullText}` : ''}`
    })
  }

  const useCreateInvite = async (payload) => {
    return await useBaseAxiosPost({ url: '/invites', payload: payload })
  }

  const useResendInvite = async (payload) => {
    return await useBaseAxiosPost({ url: '/invites/resend', payload: payload })
  }

  const useDeleteInvite = async (id) => {
    return await useBaseAxiosDelete({
      url: `/invites/${id}`
    })
  }

  const useAcceptInvite = async ({ inviteId, payload }) => {
    return await useBaseAxiosPost({
      url: `/invites/${inviteId}/accept`,
      payload,
      authorization: false
    })
  }

  const useGetInvite = async ({ inviteId }) => {
    return await useBaseAxiosGet({
      url: `/invites/${inviteId}`,
      authorization: false
    })
  }

  const value = {
    useFetchInvites,
    useCreateInvite,
    useDeleteInvite,
    useResendInvite,
    useAcceptInvite,
    useGetInvite
  }

  return (
    <InvitesContext.Provider value={value}>{children}</InvitesContext.Provider>
  )
}
