import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'

const StatusFilter = ({ filters = {}, onChange }) => {
  const { showOffline } = filters

  const [online, setOnline] = useState(null)
  const [offline, setOffline] = useState(null)

  const handleChangeOffline = (_, value) => {
    setOffline(value)
    !value && !online
      ? onChange({ showOffline: null })
      : onChange({ showOffline: value })
  }

  const handleChangeOnline = (_, value) => {
    setOnline(value)
    !value && !offline
      ? onChange({ showOffline: null })
      : onChange({ showOffline: false })
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.status.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="online"
            control={
              <Checkbox
                onChange={handleChangeOnline}
                checked={showOffline !== null && !showOffline}
                disabled={showOffline !== null && showOffline}
              />
            }
            label={t('filters.devices.other.status.valueTrue')}
            sx={{ width: '100px' }}
          />
          <FormControlLabel
            value="offline"
            control={
              <Checkbox
                onChange={handleChangeOffline}
                checked={showOffline !== null && showOffline}
                disabled={showOffline !== null && !showOffline}
              />
            }
            label={t('filters.devices.other.status.valueFalse')}
            sx={{ width: '100px' }}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

export default StatusFilter
