import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import React from 'react'
import {
  WarningRounded as WarningRoundedIcon,
  SettingsSuggestRounded as SettingsSuggestRoundedIcon,
  HomeRounded as HomeRoundedIcon,
  LayersRounded as LayersRoundedIcon,
  SensorsRounded as SensorsRoundedIcon,
  PersonAddRounded as PersonAddRoundedIcon,
  ShieldRounded as ShieldRoundedIcon,
  AccountCircleRounded as AccountCircleRoundedIcon,
  PeopleRounded as PeopleRoundedIcon,
  WifiRounded as WifiRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import Can from '../Can/Can.jsx'
import { grey } from '@mui/material/colors'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'

const ManagementMenu = () => {
  const {
    currentUser: { isSuperAdmin }
  } = useAuth()
  return (
    <MenuList>
      <MenuItem
        id="menu-home"
        component={Link}
        to="/">
        <ListItemIcon sx={{ color: grey[500] }}>
          <HomeRoundedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('common.home')}</ListItemText>
      </MenuItem>

      <MenuItem
        id="menu-profile"
        component={Link}
        to="/management/profile">
        <ListItemIcon sx={{ color: grey[500] }}>
          <AccountCircleRoundedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('common.myProfile')}</ListItemText>
      </MenuItem>

      <Divider />

      <Can action="organizations_r">
        <MenuItem
          id="menu-organizations"
          component={Link}
          to="/management/organizations">
          <ListItemIcon sx={{ color: grey[500] }}>
            <LayersRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.organizations')}</ListItemText>
        </MenuItem>
      </Can>

      <Can action="users_r">
        <MenuItem
          id="menu-invites"
          component={Link}
          to="/management/invites">
          <ListItemIcon sx={{ color: grey[500] }}>
            <PersonAddRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.invites')}</ListItemText>
        </MenuItem>

        <Divider />
      </Can>

      <Can action="devices_r">
        <MenuItem
          id="menu-devices"
          component={Link}
          to="/management/devices">
          <ListItemIcon sx={{ color: grey[500] }}>
            <SensorsRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.devices')}</ListItemText>
        </MenuItem>
      </Can>

      <Can action="devices_r">
        <MenuItem
          id="menu-automations"
          component={Link}
          to="/management/automations">
          <ListItemIcon sx={{ color: grey[500] }}>
            <SettingsSuggestRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.automations')}</ListItemText>
        </MenuItem>
      </Can>

      <Can action="devices_r">
        <MenuItem
          id="menu-events"
          component={Link}
          to="/management/events">
          <ListItemIcon sx={{ color: grey[500] }}>
            <WarningRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.events')}</ListItemText>
        </MenuItem>
      </Can>

      <Divider />

      <Can
        action="users_r"
        expression={() => isSuperAdmin}>
        <MenuItem
          id="menu-gateways"
          component={Link}
          to="/management/gateways">
          <ListItemIcon sx={{ color: grey[500] }}>
            <WifiRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.gateways')}</ListItemText>
        </MenuItem>
      </Can>

      {isSuperAdmin && <Divider />}

      <Can action="users_r">
        <MenuItem
          id="menu-users"
          component={Link}
          to="/management/users">
          <ListItemIcon sx={{ color: grey[500] }}>
            <PeopleRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.users')}</ListItemText>
        </MenuItem>
      </Can>

      <Can
        action="users_r"
        expression={() => isSuperAdmin}>
        <MenuItem
          id="menu-roles"
          component={Link}
          to="/management/roles">
          <ListItemIcon sx={{ color: grey[500] }}>
            <ShieldRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('common.roles')}</ListItemText>
        </MenuItem>
      </Can>
    </MenuList>
  )
}

export default ManagementMenu
