import React, { createContext, useContext, useReducer, useEffect } from 'react'
import axios from 'axios'

const DEFAULT_ORGANIZATION_KEY = 'a2a'

const AuthContext = createContext({})

export const useAuth = () => {
  return useContext(AuthContext)
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'saveCurrentUser':
      return {
        ...state,
        currentUser: action.payload
      }

    case 'saveAuthState': {
      return { ...state, authState: action.payload }
    }

    case 'authenticateUser': {
      return action.payload
    }

    case 'logout': {
      return {
        currentUser: {
          currentOrganizationKey: '',
          isSuperAdmin: false,
          permissions: {},
          selfOrganizations: []
        },
        authState: {
          isAuthenticated: false,
          accessToken: '',
          refreshToken: ''
        }
      }
    }

    case 'setCurrentOrganizationKey': {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          currentOrganizationKey: action.payload.organizationKey
        }
      }
    }

    case 'setSelfOrganizations':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          selfOrganizations: action.payload.organizations
        }
      }

    default:
      throw new Error(`Reducer error: unkown action type ${action.type}`)
  }
}

export const AuthProvider = ({ children }) => {
  const currentOrganizationKey = localStorage.getItem('organizationKey') || DEFAULT_ORGANIZATION_KEY
  const isSuperAdmin = localStorage.getItem('isSuperAdmin') || false
  const permissions = JSON.parse(localStorage.getItem('permissions'))
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')

  const [state, dispatch] = useReducer(reducer, {
    currentUser: {
      currentOrganizationKey: currentOrganizationKey,
      isSuperAdmin: isSuperAdmin,
      permissions: permissions ? permissions : {},
      selfOrganizations: []
    },
    authState: {
      isAuthenticated: !!accessToken,
      accessToken,
      refreshToken: refreshToken
    }
  })

  useEffect(() => {
    useFetchSelfOrganizations()
      .then((res) =>
        dispatch({
          type: 'setSelfOrganizations',
          payload: { organizations: res.data }
        })
      )
      .catch(() =>
        dispatch({
          type: 'setSelfOrganizations',
          payload: { organizations: [] }
        })
      )
  }, [])

  const baseAxios = axios.create({
    baseURL: '/api'
  })

  baseAxios.interceptors.request.use(
    (config) => {
      if (config.authorization) {
        //questo probabilmente lo possiamo mettere in authstate e lasciare solo il refresh nel localstorage
        const token = getCurrentAccessToken()
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }

      //ricordarsi di mettere un default per la orgKey
      //questo probabilmente lo possiamo mettere in authstate e lasciare solo il refresh nel localstorage
      config.headers.organizationKey = state.currentUser.currentOrganizationKey || DEFAULT_ORGANIZATION_KEY

      return config
    },
    (error) => Promise.reject(error)
  )

  let failedQueue = []
  let isRefreshing = false
  const refreshTokenUrl = '/refresh'

  baseAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config
      const refreshToken = getCurrentRefreshToken()

      const canRefresh = () =>
        refreshToken && error.response?.status === 401 && originalRequest?.url !== refreshTokenUrl && originalRequest?._retry !== true

      //Se si verifica un errore fai passare tutta la coda e slogga l'utente
      const handleError = (error) => {
        processQueue(error)
        logout()
        return Promise.reject(error)
      }

      const refreshAction = () => {
        isRefreshing = true
        originalRequest._retry = true
        return baseAxios
          .post('/refresh', { refreshToken })
          .then((res) => {
            setRefreshedTokens({
              accessToken: res.data?.accessToken,
              refreshToken: res.data?.refreshToken
            })
            processQueue(null)

            return baseAxios(originalRequest)
          }, handleError)
          .finally(() => (isRefreshing = false))
      }

      if (canRefresh()) {
        return isRefreshing
          ? new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject })
            })
              .then(() => baseAxios(originalRequest))
              .catch((err) => Promise.reject(err))
          : refreshAction()
      }

      // Se va in errore il refresh fai passare la coda e slogga direttamente
      //da capire come implementare
      if (error.response?.status === 401 && originalRequest?.url === refreshTokenUrl) {
        return handleError(error)
      }

      //Tutti gli altri status di errore che non sono 401 finiscono qui
      return Promise.reject(error)
    }
  )

  const useBaseDownload = async ({ url, payload, method = 'get', authorization = true }) => {
    const requestConfig = {
      url,
      method,
      authorization,
      data: payload,
      responseType: 'blob'
    }

    return executeRequest(requestConfig)
  }

  const useBaseAxiosGet = async ({ url, authorization = true, project = (x) => x }) => {
    const requestConfig = {
      url: url,
      method: 'get',
      authorization: authorization
    }

    return executeRequest(requestConfig, project)
  }

  const useBaseAxiosPost = async ({ url, authorization = true, payload, params, project = (x) => x }) => {
    const requestConfig = {
      url: url,
      method: 'post',
      authorization: authorization,
      params: params,
      data: payload
    }

    return executeRequest(requestConfig, project)
  }

  const useBaseAxiosPatch = async ({ url, authorization = true, payload }) => {
    const requestConfig = {
      url: url,
      method: 'patch',
      authorization: authorization,
      data: payload
    }

    return executeRequest(requestConfig)
  }

  const useBaseAxiosPut = async ({ url, authorization = true, payload }) => {
    const requestConfig = {
      url: url,
      method: 'put',
      authorization: authorization,
      data: payload
    }

    return executeRequest(requestConfig)
  }

  const useBaseAxiosDelete = async ({ url, authorization = true }) => {
    const requestConfig = {
      url: url,
      method: 'delete',
      authorization: authorization
    }

    return executeRequest(requestConfig)
  }

  const executeRequest = async (requestConfig, project = (x) => x) =>
    new Promise((resolve, reject) =>
      baseAxios
        .request(requestConfig)
        .then((response) => {
          const { data, status, headers } = response
          resolve(
            project({
              data: data,
              isSuccess: true,
              isError: false,
              status: status,
              count: headers['x-total-count'] || null
            })
          )
        })
        .catch(({ response, status }) => {
          reject(
            project({
              data: response.data,
              isSuccess: false,
              isError: true,
              status: status
            })
          )
        })
    )

  const logout = () => {
    deleteTokens()
    dispatch({ type: 'logout' })
  }

  const getCurrentAccessToken = () => {
    return localStorage.getItem('accessToken')
  }

  const getCurrentRefreshToken = () => {
    return localStorage.getItem('refreshToken')
  }

  const deleteTokens = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('organizationKey')
    localStorage.removeItem('isSuperAdmin')
    localStorage.removeItem('permissions')
  }

  const setRefreshedTokens = ({ accessToken, refreshToken }) => {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
  }

  const processQueue = (error) => {
    failedQueue.map(({ reject, resolve }) => (error ? reject(error) : resolve()))

    failedQueue = []
  }

  const saveCurrentUser = ({ accessToken, refreshToken, permissions: { permissions, isSuperAdmin }, preferences }) => {
    useFetchSelfOrganizations()
      .then((res) =>
        dispatch({
          type: 'setSelfOrganizations',
          payload: { organizations: res.data }
        })
      )
      .catch(() =>
        dispatch({
          type: 'setSelfOrganizations',
          payload: { organizations: [] }
        })
      )

    const orgs = isSuperAdmin ? Object.keys(permissions).filter((key) => key === DEFAULT_ORGANIZATION_KEY) : Object.keys(permissions)
    const orgKey = orgs[0] ? orgs[0] : null
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('organizationKey', preferences?.defaultOrganization || orgKey)
    if (isSuperAdmin) {
      localStorage.setItem('isSuperAdmin', isSuperAdmin)
    }
    localStorage.setItem('permissions', JSON.stringify(permissions))

    dispatch({
      type: 'authenticateUser',
      payload: {
        currentUser: {
          currentOrganizationKey: preferences?.defaultOrganization || orgKey,
          isSuperAdmin,
          permissions
        },
        authState: {
          accessToken,
          refreshToken,
          isAuthenticated: true
        }
      }
    })
  }

  const setCurrentOrganizationKey = ({ organizationKey }) => {
    localStorage.setItem('organizationKey', organizationKey)
    dispatch({
      type: 'setCurrentOrganizationKey',
      payload: { organizationKey }
    })
  }

  const useFetchSelfOrganizations = async () => {
    return await useBaseAxiosGet({ url: '/self/organizations' })
  }

  const useLoginUser = async (payload) => {
    return await useBaseAxiosPost({
      url: 'login',
      payload: payload,
      authorization: false
    })
  }

  const useLogoutUser = async (payload) => {
    return await useBaseAxiosPost({
      url: 'logout',
      payload: payload
    })
  }

  const useResetPassword = async (payload) => {
    return await useBaseAxiosPost({
      url: '/users/reset-password',
      payload: payload,
      authorization: false
    })
  }

  const useResetPasswordRequest = async ({ token, data }) => {
    return await useBaseAxiosPatch({
      url: `/users/reset-password/${token}`,
      payload: { password: data }
    })
  }

  const value = {
    authState: state.authState,
    useBaseAxiosGet,
    useBaseAxiosPost,
    useBaseAxiosPatch,
    useBaseAxiosPut,
    useBaseAxiosDelete,
    useLoginUser,
    useLogoutUser,
    useResetPassword,
    useResetPasswordRequest,
    logout,
    saveCurrentUser,
    currentUser: state.currentUser,
    setCurrentOrganizationKey,
    useFetchSelfOrganizations,
    useBaseDownload
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
