const theme = {
  palette: {
    type: 'dark',
    primary: {
      main: '#90caf9',
      light: '#e3f2fd',
      dark: '#42a5f5',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    secondary: {
      main: '#ce93d8',
      light: '#f3e5f5',
      dark: '#ab47bc',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#ffa726',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#29b6f6',
      light: '#EBEBEB',
      dark: '#0288d1',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    notSelected: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#66bb6a',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    contrastThreshold: 3,
    tonalOffset: 0.5,
    background: {
      paper: '#171C21',
      default: '#171C21'
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.7)'
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    action: {
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)'
    }
  },
  typography: {
    htmlFontSize: 12,
    fontSize: 11,
    fontFamily: '"Noto Sans JP", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 400,
      fontSize: '2.5rem'
    },
    h2: {
      fontWeight: 400,
      fontSize: '2rem'
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.75rem'
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 500,
      fontSize: '0.8rem'
    },
    h6: {
      fontWeight: 500,
      fontSize: '0.8rem'
    },
    body: {
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: 1.43
    },
    body2: {
      fontWeight: 300,
      fontSize: '0.8rem',
      lineHeight: 1.43
    }
  },
  shadows: [
    'none',
    '0px 10px 20px 0px rgba(100, 100, 100, 0.4), 0px 0px 5px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 25px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 30px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 35px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 40px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 45px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 50px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 55px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 60px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)',
    '0px 10px 65px 0px rgba(100, 100, 100, 0.4), 0px 0px 10px 0px rgba(100, 100, 100, 0.15)'
  ],
  shape: {
    borderRadius: 5
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          flexShrink: 0
        },
        paper: {
          position: 'unset',
          height: 'calc(100vh - 140px)',
          boxSizing: 'border-box',
          padding: '20px',
          marginTop: '80px'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#171C21 !important',
          color: 'rgba(255, 255, 255, 0.87) !important'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&::before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.87) !important'
          }
        }
      }
    }
  }
}

export default theme
