import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import DynamicForm from '../../Shared/Forms/DynamicForm.jsx'
import { useDevices } from '../Contexts/DevicesContext.jsx'

const UpdateProvisioning = ({ deviceData, onChange }) => {
  const { useFetchProvisioningSetup } = useDevices()

  const [updatedDeviceData, setUpdatedDeviceData] = useState()
  const [provisioningSetup, setProvisioningSetup] = useState(null)

  useEffect(() => {
    useFetchProvisioningSetup({
      brandEncoded: deviceData.device.brandEncoded,
      modelEncoded: deviceData.device.modelEncoded
    }).then((res) => {
      setProvisioningSetup(res.data)
    })
  }, [])

  useEffect(() => {
    if (deviceData) {
      setUpdatedDeviceData({
        ...deviceData.parsingData
      })
    }
  }, [deviceData])

  useEffect(() => {
    if (!provisioningSetup || !provisioningSetup?.fields?.length) {
      onChange({}, true)
    }
  }, [provisioningSetup])

  const handleDynamicFormChange = (value, isValid) => {
    setUpdatedDeviceData(value)
    onChange(value, isValid)
  }

  if (!provisioningSetup || !provisioningSetup?.fields?.length) {
    return <></>
  }

  return (
    <>
      <Typography
        id="devices-form-label-provisioning-setup"
        variant="h2"
        py={1}
      >
        {t('devices.provisioningSetup')}
      </Typography>
      {provisioningSetup && updatedDeviceData && (
        <DynamicForm
          fieldsPayload={updatedDeviceData}
          fields={provisioningSetup.fields}
          onChange={handleDynamicFormChange}
        />
      )}
    </>
  )
}

UpdateProvisioning.propTypes = {
  deviceData: PropTypes.object,
  onChange: PropTypes.func
}
export default UpdateProvisioning
