import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { t } from 'i18next'

import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Shared/Contexts/AuthContext'

const ChangeOrganization = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate()

  const {
    currentUser: { currentOrganizationKey, selfOrganizations },
    setCurrentOrganizationKey
  } = useAuth()

  const handleChange = (_event, value) => {
    const { key: newOrganization } = value
    if (currentOrganizationKey === newOrganization) {
      return
    }
    setCurrentOrganizationKey({ organizationKey: newOrganization })
    setIsOpen(false)
    navigate(0)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll="body"
      >
        <DialogTitle
          variant="h4"
          display="flex"
          justifyContent="center"
        >
          {t('organizations.changeOrganization')}
        </DialogTitle>

        <CloseDialogButton onClose={handleClose} />

        <DialogContent>
          <Autocomplete
            id="change-organization-dialog-select-organization"
            options={selfOrganizations}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            value={selfOrganizations?.find(
              ({ key }) => key === currentOrganizationKey
            )}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('common.choose')}
                variant="standard"
                helperText={t('organizations.inputOrganization')}
              />
            )}
            disableClearable
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

ChangeOrganization.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default ChangeOrganization
