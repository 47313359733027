import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { Save as SaveIcon } from '@mui/icons-material'
import RuleForm from '../Form/Rule/RuleForm.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'

const RuleDetail = ({ isOpen, selectedRuleId, onClose }) => {
  const { currentUser } = useAuth()
  const { useUpdateRule, useGetRuleQuery } = useAutomations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const [rule, setRule] = useState({
    trigger: null,
    actions: [],
    enabled: true,
    name: ''
  })

  useEffect(() => {
    if (!selectedRuleId) {
      return
    }

    useGetRuleQuery({ id: selectedRuleId }).then((res) => setRule(res.data))
  }, [selectedRuleId])

  const handleOnClose = (reload) => onClose(reload)

  const handleRuleFormChange = (rule) => setRule(rule)

  const handleSaveRule = async () =>
    useUpdateRule({
      id: rule.id,
      payload: {
        organizationKey: rule.organizationKey || currentUser.currentOrganizationKey,
        enabled: rule.enabled,
        name: rule.name,
        triggerId: rule.trigger.id,
        actions: rule.actions.map((x) => ({
          actionId: x.id,
          actionType: x.type
        }))
      }
    })
      .then(() => {
        onClose(true)
        openSuccessAlert()
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))

  return (
    <ZDialog
      id={'rule-detail-dialog'}
      isOpen={isOpen}
      onClose={() => handleOnClose(false)}
      title={t('rules.add')}
      content={
        <RuleForm
          onChange={handleRuleFormChange}
          rule={rule}
          isEdit={true}
        />
      }
      actions={
        <Button
          id="add-rule-dialog-button-save"
          variant="contained"
          endIcon={<SaveIcon />}
          onClick={handleSaveRule}
          disabled={
            rule.actions.length == 0 ||
            !rule.trigger ||
            !rule.name ||
            (rule.trigger?.type === 'scheduled-trigger' && rule.actions.filter((a) => a.type !== 'downlink').length > 0)
          }>
          {t('common.save')}
        </Button>
      }
    />
  )
}

export default RuleDetail
