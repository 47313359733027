import React, { useEffect, useState } from 'react'
import ModelsSelector from './ModelsSelector'
import { Autocomplete, Stack, TextField } from '@mui/material'
import { t } from 'i18next'
import { useValidationEffect } from '../../../../helpers/hooks'
import { NumericFormat } from 'react-number-format'
import { useAutomations } from '../../Contexts/AutomationsContext'
import { useAuth } from '../../../Shared/Contexts/AuthContext'
import { Construction } from '@mui/icons-material'

const sortFn = (a, b) => {
  const label1 = a.label.toLowerCase()
  const label2 = b.label.toLowerCase()
  if (label1 < label2) return -1
  if (label1 > label2) return 1
  return 0
}

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      decimalSeparator=","
      allowedDecimalSeparators={[',', '.']}
    />
  )
})

const CommunicationRuleForm = ({ isEdit, onChange, validation, selectedOrganizationKey, trigger }) => {
  const { applyToChildOrganizations, type, device, quantityId = trigger.quantity?.id || trigger.quantityId, missingComunicationQuantity } = trigger
  const { useGetQuantities } = useAutomations()
  const {
    currentUser: { isSuperAdmin }
  } = useAuth()

  const [quantities, setQuantities] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState()
  const [selectedSubQuantity, setSelectedSubQuantity] = useState()

  const comunicationRule = { selectedModels: trigger.models, missingComunicationQuantity }

  useEffect(() => {
    useGetQuantities().then((res) => {
      setQuantities(res.data)
      setSelectedQuantity(res.data.find((q) => q.id === quantityId))
    })
  }, [])

  useEffect(() => {
    const selected = quantities.find((q) => q.id === quantityId)
    setSelectedQuantity(selected)
  }, [quantityId])

  useValidationEffect(() => {
    const subQuantities = getSubQuantities()
    const selectedSubQuantity = subQuantities.length === 1 ? subQuantities[0] : null
    changeSubMeasure(selectedSubQuantity)
  }, [selectedQuantity])

  const changeSubMeasure = (subQuantity) => {
    if (!device) {
      return
    }
    setSelectedSubQuantity(subQuantity)
    const models = subQuantity
      ? [
          {
            id: parseInt(device.modelId, 10),
            measures: [{ name: subQuantity.id }]
          }
        ]
      : []
    onChange({ ...comunicationRule, models, quantity: selectedQuantity, quantityId: selectedQuantity?.id })
  }

  const handleModelsChanged = (data) => onChange({ ...comunicationRule, ...data })

  const handleSelectedQuantity = (_data, value) => {
    setSelectedQuantity(value)
    setSelectedSubQuantity(null)
    onChange({
      ...comunicationRule,
      quantity: value,
      quantityId: value?.id,
      models: []
    })
  }

  const handleChangeSubMeasuere = (_event, value) => changeSubMeasure(value)

  const handleTimeDeltaChange = ({ target: { value } }) => {
    const timeDelta = isSuperAdmin ? parseFloat(value) : parseInt(value)
    onChange({
      ...comunicationRule,
      missingComunicationQuantity: { ...missingComunicationQuantity, timeDelta }
    })
  }

  const filteredQuantities = device
    ? quantities
        .filter((q) => device.quantities.some((dq) => dq.measureId === q.id))
        .map((fq) => ({ ...fq, label: t(fq.label) }))
        .sort(sortFn)
    : quantities.map((fq) => ({ ...fq, label: t(fq.label) })).sort(sortFn)

  const getSubQuantities = () =>
    device && selectedQuantity
      ? device.quantities.filter((q) => q.measureId === selectedQuantity.id).map((q) => ({ id: q.measureName, label: q.measureName }))
      : []

  const getSelectedSubQuantity = () => {
    const subQuantities = getSubQuantities()
    return subQuantities.length === 1 ? subQuantities[0] : ''
  }

  const subQuantities = getSubQuantities()

  return (
    <>
      {type === 'measure-communication' && missingComunicationQuantity && (
        <Stack
          direction={'column'}
          spacing={2}>
          <Autocomplete
            sx={{
              marginTop: '30px'
            }}
            id="comunication-trigger-form-measure-selector"
            name="measureSelector"
            options={filteredQuantities.map((fq) => ({ ...fq, label: t(fq.label) })).sort(sortFn)}
            value={selectedQuantity ? { ...selectedQuantity, label: t(selectedQuantity.label) } : ''}
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleSelectedQuantity}
            renderInput={(params) => (
              <TextField
                required
                name="quantity"
                {...params}
                label={t('triggers.selectMeasure')}
                variant="standard"
                error={validation?.messages?.quantityId?.length > 0}
                helperText={validation?.messages?.quantityId?.join(', ')}
              />
            )}
            disabled={isEdit}
          />
          {subQuantities.length > 0 && (
            <Autocomplete
              sx={{
                marginTop: '30px'
              }}
              py={5}
              id="subMeasureSelector"
              name="subMeasureSelector"
              options={subQuantities}
              getOptionLabel={(option) => t(option.label)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleChangeSubMeasuere}
              defaultValue={selectedSubQuantity || getSelectedSubQuantity()}
              value={selectedSubQuantity || getSelectedSubQuantity()}
              renderInput={(params) => (
                <TextField
                  name="quantity"
                  {...params}
                  label={t('triggers.selectSubMeasure')}
                  variant="standard"
                />
              )}
              disabled={isEdit}
            />
          )}
          {isSuperAdmin ? (
            <TextField
              id="comunication-trigger-form-delta-hours"
              label={'Ore trascorse da ultima misura'}
              name="deltaHours"
              variant="standard"
              fullWidth
              required
              value={missingComunicationQuantity?.timeDelta}
              error={validation?.messages?.timeDelta?.length > 0}
              helperText={validation?.messages?.timeDelta?.join(', ')}
              onChange={handleTimeDeltaChange}
              disabled={isEdit}
              slotProps={{
                input: {
                  inputComponent: NumericFormatCustom
                }
              }}
            />
          ) : (
            <TextField
              id="comunication-trigger-form-delta-hours"
              label={'Ore trascorse da ultima misura'}
              name="deltaHours"
              variant="standard"
              type="number"
              fullWidth
              required
              value={missingComunicationQuantity?.timeDelta}
              error={validation?.messages?.timeDelta?.length > 0}
              helperText={validation?.messages?.timeDelta?.join(', ')}
              onChange={handleTimeDeltaChange}
              disabled={isEdit}
            />
          )}
        </Stack>
      )}
      {!device && (
        <ModelsSelector
          applyToChildOrganizations={applyToChildOrganizations}
          type={type}
          selectedModels={comunicationRule.selectedModels}
          selectedQuantity={selectedQuantity}
          isEdit={isEdit}
          onModelsChanged={handleModelsChanged}
          selectedOrganizationKey={trigger.organizationKey || selectedOrganizationKey}
          validation={validation}
        />
      )}
    </>
  )
}

export default CommunicationRuleForm
