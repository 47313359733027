import { Box, Typography, Stack, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../Shared/Contexts/AuthContext'
import { useUsers } from '../Contexts/UsersContext'

const ChangeEmail = () => {
  const { logout } = useAuth()
  const { useGetEmailChangeRequest, useChangeEmail } = useUsers()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const navigate = useNavigate()
  const { changeId } = useParams()

  const [emailChangeRequest, setEmailChangeRequest] = useState()

  useEffect(() => {
    useGetEmailChangeRequest({ requestId: changeId }).then((res) => setEmailChangeRequest(res.data))
  }, [])

  const handleBackToProfile = () => navigate('/')

  const handleConfirmEmailChange = () =>
    useChangeEmail({
      requestId: changeId,
      payload: { email: emailChangeRequest.newEmail }
    })
      .then(() => {
        openSuccessAlert()
        logout()
        navigate('/')
      })
      .catch((err) => openErrorAlert(err.data.keys))

  return (
    <>
      <Box
        marginTop={'66px'}
        height={'71vh'}
        width={'100%'}
        alignContent={'center'}>
        <Box
          marginTop={5}
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}>
          <Typography variant="h1">CAMBIA INDRIZZO E-MAIL</Typography>
        </Box>
        <Box
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
          marginTop={10}>
          <Stack
            display={'flex'}
            width={'30%'}
            alignContent={'center'}
            justifyContent={'center'}
            spacing={3}>
            <Typography textAlign={'center'}>Email precedente: {emailChangeRequest?.oldEmail}</Typography>
            <Typography textAlign={'center'}>Nuova email: {emailChangeRequest?.newEmail}</Typography>
            <Button
              variant="contained"
              onClick={handleConfirmEmailChange}>
              Conferma
            </Button>
            <Button
              variant="outlined"
              onClick={handleBackToProfile}>
              Indietro
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default ChangeEmail
