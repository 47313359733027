import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Automations from './components/Automations/Automations.jsx'
import Devices from './components/Devices/Pages/Management.jsx'
import Events from './components/Events/Pages/Management.jsx'
import Gateways from './components/Gateways/Pages/Management.jsx'
import Invites from './components/Invites/Pages/Invites.jsx'
import AcceptInvite from './components/Invites/Pages/AcceptInvite.jsx'
import MainLayout from './components/Layout/MainLayout.jsx' //QUI
import FrontPageTemplate from './components/Layout/Templates/FrontPage.jsx'
import ManagementTemplate from './components/Layout/Templates/Management.jsx'
import ResetPasswordTemplate from './components/Layout/Templates/ResetPassword.jsx'
import ChangeEmail from './components/Users/Page/ChangeEmail.jsx'
import InvitesTemplate from './components/Layout/Templates/Invites.jsx'
import NoMatch from './components/Layout/Templates/NoMatch.jsx'
import Organizations from './components/Organizations/Pages/Organizations.jsx'
import Roles from './components/Roles/Pages/Management.jsx'
import UsersList from './components/Users/Page/UsersList.jsx'
import UserProfile from './components/Users/Page/UserProfile.jsx'
import ProtectedRoute from './components/Layout/ProtectedRoute/ProtectedRoute.jsx'
import { CircularProgress, Box, Stack, Button, Typography } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import InlineMessage from './components/Shared/Components/InlineMessage.jsx'
import { LicenseInfo } from '@mui/x-license'
import { MUI_LICENSE } from './configs/environment.js'
import DeleteUser from './components/Layout/Templates/DeleteUser.jsx'
import { RolesProvider } from './components/Roles/Contexts/RolesContext.jsx'
import { InvitesProvider } from './components/Invites/Contexts/InvitesContext.jsx'
import { OrganizationsProvider } from './components/Organizations/Contexts/OrganizationsContext.jsx'
import { EventsProvider } from './components/Events/Contexts/EventsContext.jsx'
import { AutomationsProvider } from './components/Automations/Contexts/AutomationsContext.jsx'
import { DevicesProvider } from './components/Devices/Contexts/DevicesContext.jsx'
import { GatewaysProvider } from './components/Gateways/Contexts/GatewaysContext.jsx'
import { UsersProvider } from './components/Users/Contexts/UsersContext.jsx'

const App = () => {
  LicenseInfo.setLicenseKey(MUI_LICENSE)

  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh">
          <CircularProgress />
          <Box>
            <Typography>Loading ...</Typography>
          </Box>
        </Box>
      }>
      <ErrorBoundary
        onError={(e) => {
          console.log('ERRORE', e)
        }}
        fallback={
          <Stack
            alignContent={'center'}
            alignItems={'center'}>
            <InlineMessage message={'Ops!!!Something went wrong!!!'} />
            <Button
              size="small"
              sx={{ height: '38px' }}
              component="label"
              variant="contained"
              onClick={() => (window.location.href = '/')}>
              Click to reload the page
            </Button>
          </Stack>
        }>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<MainLayout />}>
              <Route
                index
                element={
                  <DevicesProvider>
                    <OrganizationsProvider>
                      <EventsProvider>
                        <FrontPageTemplate />
                      </EventsProvider>
                    </OrganizationsProvider>
                  </DevicesProvider>
                }
              />
              <Route
                path="*"
                element={<NoMatch />}
              />
            </Route>

            <Route
              path="/public"
              element={<MainLayout />}>
              <Route
                path=":organizationKey"
                element={
                  <DevicesProvider>
                    <OrganizationsProvider>
                      <EventsProvider>
                        <FrontPageTemplate />
                      </EventsProvider>
                    </OrganizationsProvider>
                  </DevicesProvider>
                }
              />
            </Route>

            <Route
              path="/invites"
              element={<MainLayout />}>
              <Route
                path=":inviteId"
                element={
                  <InvitesTemplate
                    main={
                      <RolesProvider>
                        <InvitesProvider>
                          <AcceptInvite />
                        </InvitesProvider>
                      </RolesProvider>
                    }
                  />
                }
              />
            </Route>

            <Route
              path="/detail"
              element={
                <DevicesProvider>
                  <OrganizationsProvider>
                    <MainLayout />
                  </OrganizationsProvider>
                </DevicesProvider>
              }>
              <Route
                path=":deviceId"
                element={
                  <DevicesProvider>
                    <OrganizationsProvider>
                      <EventsProvider>
                        <FrontPageTemplate />
                      </EventsProvider>
                    </OrganizationsProvider>
                  </DevicesProvider>
                }
              />
            </Route>

            <Route
              path="/password-reset"
              element={<MainLayout />}>
              <Route
                path=":requestId"
                element={<ResetPasswordTemplate />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/delete-account"
                element={<MainLayout />}>
                <Route
                  index
                  element={
                    <UsersProvider>
                      <DeleteUser />
                    </UsersProvider>
                  }
                />
              </Route>

              <Route
                path="/email-confirmation/:changeId"
                element={<MainLayout />}>
                <Route
                  index
                  element={
                    <UsersProvider>
                      <ChangeEmail />
                    </UsersProvider>
                  }
                />
              </Route>
              <Route
                path="/management"
                element={<MainLayout />}>
                <Route
                  path="organizations"
                  element={
                    <ManagementTemplate
                      main={
                        <OrganizationsProvider>
                          <Organizations />
                        </OrganizationsProvider>
                      }
                    />
                  }
                />
                <Route
                  path="organizations/:organizationId"
                  element={
                    <ManagementTemplate
                      main={
                        <OrganizationsProvider>
                          <Organizations />
                        </OrganizationsProvider>
                      }
                    />
                  }
                />
                <Route
                  path="profile"
                  element={
                    <ManagementTemplate
                      main={
                        <UsersProvider>
                          <UserProfile />
                        </UsersProvider>
                      }
                    />
                  }
                />
                <Route
                  path="users"
                  element={
                    <ManagementTemplate
                      main={
                        <InvitesProvider>
                          <RolesProvider>
                            <UsersProvider>
                              <UsersList />
                            </UsersProvider>
                          </RolesProvider>
                        </InvitesProvider>
                      }
                    />
                  }
                />
                <Route
                  path="users/:entityId"
                  element={
                    <ManagementTemplate
                      main={
                        <InvitesProvider>
                          <RolesProvider>
                            <UsersProvider>
                              <UsersList />
                            </UsersProvider>
                          </RolesProvider>
                        </InvitesProvider>
                      }
                    />
                  }
                />
                <Route
                  path="events"
                  element={
                    <ManagementTemplate
                      main={
                        <EventsProvider>
                          <Events />
                        </EventsProvider>
                      }
                    />
                  }
                />
                <Route
                  path="gateways"
                  element={
                    <ManagementTemplate
                      main={
                        <GatewaysProvider>
                          <Gateways />
                        </GatewaysProvider>
                      }
                    />
                  }
                />
                <Route
                  path="invites"
                  element={
                    <ManagementTemplate
                      main={
                        <RolesProvider>
                          <InvitesProvider>
                            <Invites />
                          </InvitesProvider>
                        </RolesProvider>
                      }
                    />
                  }
                />
                <Route
                  path="roles"
                  element={
                    <ManagementTemplate
                      main={
                        <RolesProvider>
                          <Roles />
                        </RolesProvider>
                      }
                    />
                  }
                />
                <Route
                  path="devices"
                  element={
                    <ManagementTemplate
                      main={
                        <DevicesProvider>
                          <EventsProvider>
                            <Devices />
                          </EventsProvider>
                        </DevicesProvider>
                      }
                    />
                  }
                />
                <Route
                  path="automations"
                  element={
                    <ManagementTemplate
                      main={
                        <DevicesProvider>
                          <AutomationsProvider>
                            <Automations />
                          </AutomationsProvider>
                        </DevicesProvider>
                      }
                    />
                  }
                />
                <Route
                  path="automations/:tab"
                  element={
                    <ManagementTemplate
                      main={
                        <AutomationsProvider>
                          <Automations />
                        </AutomationsProvider>
                      }
                    />
                  }
                />
                <Route
                  path="automations/:tab/:entityId"
                  element={
                    <ManagementTemplate
                      main={
                        <AutomationsProvider>
                          <Automations />
                        </AutomationsProvider>
                      }
                    />
                  }
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
