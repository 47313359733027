import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import Logo from '../../Layout/Logo/Logo.jsx'
import LoginForm from '../../Shared/Forms/LoginForm.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'

const Login = () => {
  const { loginDialog, setLoginDialog, setResetPasswordDialog } =
    useGlobalContext()
  const { saveCurrentUser, useLoginUser } = useAuth()

  const [isValid, setIsValid] = useState(false)
  const [form, setForm] = useState({ email: '', password: '' })
  const [isLoginError, setIsLoginError] = useState(false)

  const handleLoginFormChange = (loginForm, isValid) => {
    setForm(loginForm)
    setIsValid(isValid)
  }

  const handleCloseLogin = () => setLoginDialog(false)

  const handleResetPassword = () => {
    setLoginDialog(false)
    setResetPasswordDialog(true)
  }

  const handleLogin = () => {
    if (!isValid) {
      return
    }
    useLoginUser(form)
      .then(({ data: loginData }) => {
        saveCurrentUser(loginData)
        setIsValid(false)
        setForm({ email: '', password: '' })
        handleCloseLogin()
      })
      .catch((err) => setIsLoginError(err.isError))
  }

  return (
    <Dialog
      maxWidth="sm"
      scroll="body"
      fullWidth={true}
      open={loginDialog}
      onClose={handleCloseLogin}
    >
      <CloseDialogButton onClose={handleCloseLogin} />

      <DialogTitle
        display="flex"
        justifyContent="center"
      >
        <Stack
          direction="column"
          spacing={2}
          paddingTop={3}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Logo />
          </Box>

          <Typography
            variant="h3"
            align="center"
          >
            <strong>{t('users.loginToCityEye')}</strong>
          </Typography>
          <Typography
            variant="body2"
            align="center"
          >
            {t('users.handleYourDevices')}
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent
        align="center"
        validation="center"
      >
        <Stack
          direction="column"
          maxWidth="70%"
          py={2}
          spacing={2}
        >
          <LoginForm
            onChange={handleLoginFormChange}
            onLogin={handleLogin}
          />
          {isLoginError && (
            <Alert severity="error">{t('users.loginFail')}</Alert>
          )}
          <FormControl variant="standard">
            <Button
              id="login-dialog-button-login"
              sx={{ mt: 4 }}
              variant="contained"
              onClick={handleLogin}
              disabled={!isValid}
            >
              {t('common.login')}
            </Button>
          </FormControl>

          <Button
            variant="text"
            onClick={handleResetPassword}
            size="small"
          >
            <Typography variant="body2">
              <>{t('users.forgotPassword')}</>
            </Typography>
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default Login
