import {
  Badge,
  Box,
  Link,
  Pagination,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import { useGateways } from '../Contexts/GatewaysContext.jsx'

const DEFAULT_PAGE_SIZE = 25
const GatewaysTable = ({ serial }) => {
  const { setFromTo } = useGlobalContext()
  const { useFetchGatewayTests } = useGateways()
  const navigate = useNavigate()

  const [tests, setTests] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    useFetchGatewayTests({ serial, page, pageSize: DEFAULT_PAGE_SIZE }).then(
      (res) => setTests(res)
    )
  }, [])

  useEffect(() => {
    useFetchGatewayTests({
      serial,
      page,
      pageSize: DEFAULT_PAGE_SIZE
    }).then((res) => setTests(res))
  }, [page])

  const handleChangePage = (_, value) => setPage(value)

  const handleGotToTracker = (deviceId, createdAt) => {
    const from = moment(createdAt).subtract(30, 'minutes').toISOString(true)
    const to = moment(createdAt).add(30, 'minutes').toISOString(true)
    setFromTo({ from, to })
    navigate(`/detail/${deviceId}`)
  }

  return (
    <>
      {tests?.data && (
        <>
          <TableContainer component={Box}>
            <Table aria-label="gateways table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('common.createdAt')}</TableCell>
                  <TableCell>{t('common.snr')}</TableCell>
                  <TableCell>{t('common.rssi')}</TableCell>
                  <TableCell>{t('gateways.tests.tester')}</TableCell>
                  <TableCell>{t('gateways.tests.testDevice')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tests?.data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {row.createdAt && (
                        <>
                          <Typography variant="p">
                            {moment(row.createdAt).format('DD/MM/YY HH:mm:ss')}
                          </Typography>
                        </>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {row.result?.snr && (
                        <Badge
                          badgeContent={
                            row.result?.snr > 8
                              ? t('gateways.tests.good')
                              : t('gateways.tests.poor')
                          }
                          color={row.result?.snr > 8 ? 'success' : 'error'}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          <Typography p={1}>{row.result?.snr}</Typography>
                        </Badge>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {row.result?.rssi && (
                        <Badge
                          badgeContent={
                            row.result?.rssi > 8
                              ? t('gateways.tests.good')
                              : t('gateways.tests.poor')
                          }
                          color={row.result?.rssi > 8 ? 'success' : 'error'}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          <Typography p={1}>{row.result?.rssi}</Typography>
                        </Badge>
                      )}
                    </TableCell>

                    <TableCell>
                      {row.result.operator.user}
                      <br /> {row.result.operator.email}
                    </TableCell>

                    <TableCell>
                      <Link
                        onClick={() =>
                          handleGotToTracker(
                            row.result.fieldTestDevice.deviceId,
                            row.createdAt
                          )
                        }
                      >
                        {row.result.fieldTestDevice.name} -{' '}
                        {row.result.fieldTestDevice.organizationKey}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {tests?.count > DEFAULT_PAGE_SIZE && (
            <Box
              display="flex"
              justifyContent="center"
            >
              <Pagination
                count={Math.ceil(tests.count / DEFAULT_PAGE_SIZE)}
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </>
      )}

      {!tests?.data.length && (
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}
        >
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '100px' }}
            ></Skeleton>
          ))}
        </Stack>
      )}
    </>
  )
}

GatewaysTable.propTypes = {
  serial: PropTypes.string.isRequired
}

export default GatewaysTable
