import React, { createContext, useContext } from 'react'
import { useAuth } from '../../Shared/Contexts/AuthContext'
import sanitizer, { sanitizeFilters } from '../../../helpers/sanitizer'

const AutomationsContext = createContext({})

export const useAutomations = () => {
  return useContext(AutomationsContext)
}

export const AutomationsProvider = ({ children }) => {
  const {
    useBaseAxiosPost,
    useBaseAxiosDelete,
    useBaseAxiosGet,
    useBaseAxiosPut,
    useBaseAxiosPatch
  } = useAuth()

  const useSearchRules = async ({ filters, page, pageSize }) => {
    delete filters.brandIds
    return await useBaseAxiosPost({
      url: `/rules/search?page=${page}&pageSize=${pageSize}`,
      payload: sanitizeFilters(filters)
    })
  }

  const useDeleteRule = async ({ id }) => {
    return await useBaseAxiosDelete({ url: `/rules/${id}` })
  }

  const useGetRuleQuery = async ({ id }) => {
    return await useBaseAxiosGet({ url: `/rules/${id}` })
  }

  const useUpdateRule = async ({ id, payload }) => {
    return await useBaseAxiosPut({
      url: `/rules/${id}`,
      payload: sanitizer(payload)
    })
  }

  const useCreateRule = async (payload) => {
    return await useBaseAxiosPost({
      url: `/rules`,
      payload: sanitizer(payload)
    })
  }

  const useSearchTriggers = async ({ filters, page, pageSize }) => {
    delete filters.brandIds
    return await useBaseAxiosPost({
      url: `/automanTriggers/search?page=${page}&pageSize=${pageSize}`,
      payload: sanitizeFilters(filters)
    })
  }

  const useDeleteTrigger = async ({ id }) => {
    return await useBaseAxiosDelete({ url: `/automanTriggers/${id}` })
  }

  const useGetTrigger = async ({ id }) => {
    return await useBaseAxiosGet({ url: `/automanTriggers/${id}` })
  }

  const useUpdateTrigger = async ({ id, payload }) => {
    return await useBaseAxiosPatch({
      url: `/automanTriggers/${id}`,
      payload: payload
    })
  }

  const useCreateTrigger = async (payload) => {
    return await useBaseAxiosPost({
      url: `/automanTriggers`,
      payload
    })
  }

  const useGetQuantities = async () => {
    return await useBaseAxiosGet({ url: '/devices/quantities' })
  }

  const useGetOrganizationModels = async ({
    searchInChildrenOrganizations,
    organizationKey
  }) => {
    return await useBaseAxiosGet({
      url: `/devices/models/summary?searchInChildrenOrganizations=${searchInChildrenOrganizations}${organizationKey ? `&organizationKey=${organizationKey}` : ''}`
    })
  }
  const useGetModelsByQuantity = async ({
    quantityId,
    searchInChildrenOrganizations,
    organizationKey
  }) => {
    return await useBaseAxiosGet({
      url: `/devices/quantities/${quantityId}/models?searchInChildrenOrganizations=${searchInChildrenOrganizations}${organizationKey ? `&organizationKey=${organizationKey}` : ''}`
    })
  }

  const useGetNumericComparisonOperators = async () => {
    return await useBaseAxiosGet({ url: '/numeric-comparison-operators' })
  }

  const useSearchActions = async ({
    page,
    pageSize,
    organizationKeys,
    fullText
  }) => {
    return await useBaseAxiosGet({
      url: `/all-actions?page=${page}&pageSize=${pageSize}${organizationKeys && organizationKeys.length ? `&organizationKeys=${organizationKeys}` : ''}${fullText ? `&fullText=${fullText}` : ''}`
    })
  }

  const useDeleteAction = async ({ id, type }) => {
    return await useBaseAxiosDelete({ url: `/action/${type}/${id}` })
  }

  const useCreateDownlinkAction = async ({ payload }) => {
    return await useBaseAxiosPost({ url: '/downlinks/actions', payload })
  }

  const useCreateAlarmAction = async ({ payload }) => {
    return await useBaseAxiosPost({
      url: '/events/actions',
      payload: sanitizer(payload)
    })
  }

  const useCreateNotificationAction = async ({ payload }) => {
    return await useBaseAxiosPost({
      url: '/notifications/actions',
      payload: sanitizer(payload)
    })
  }

  const useCreateWebhookAction = async ({ payload }) => {
    return await useBaseAxiosPost({
      url: '/webhooks',
      payload: sanitizer(payload)
    })
  }

  const useSearchDownlinks = async ({ brand, model }) => {
    return await useBaseAxiosGet({
      url: `/brands/${brand}/models/${model}/actions?withFields=true`
    })
  }

  //TODO 02-12-2024 probabilmente dovrà essere rimposso perchè sarà presente anche nel Context dei device
  const useFetchDevices = async ({
    filters,
    sort,
    direction,
    page,
    pageSize
  }) => {
    return await useBaseAxiosPost({
      url: `/devices/search?page=${page}&pageSize=${pageSize}${sort ? `&sort=${sort}:${direction}` : ''}`,
      payload: sanitizer(filters)
    })
  }

  //TODO 02-12-2024 spostare nel filterContext una volta mergiato tutto
  const useFetchBrandsFilter = async () => {
    return useBaseAxiosGet({ url: '/filters/brands' })
  }

  //TODO 02-12-2024 spostare nel filterContext una volta mergiato tutto
  const useFetchModelsFilter = async ({ brandIds }) => {
    //TODO 02-12-2024 fare refactoring della rotta perchè cosi non va bene
    return await useBaseAxiosGet({ url: `/filters/models?brandId=${brandIds}` })
  }

  const value = {
    useSearchRules,
    useDeleteRule,
    useGetRuleQuery,
    useUpdateRule,
    useCreateRule,
    useSearchTriggers,
    useDeleteTrigger,
    useGetTrigger,
    useUpdateTrigger,
    useCreateTrigger,
    useGetQuantities,
    useGetOrganizationModels,
    useGetModelsByQuantity,
    useGetNumericComparisonOperators,
    useSearchActions,
    useDeleteAction,
    useCreateDownlinkAction,
    useCreateAlarmAction,
    useCreateNotificationAction,
    useCreateWebhookAction,
    useSearchDownlinks,
    useFetchDevices,
    useFetchBrandsFilter,
    useFetchModelsFilter
  }

  return (
    <AutomationsContext.Provider value={value}>
      {children}
    </AutomationsContext.Provider>
  )
}
