import CommunicationRuleForm from './CommunicationRuleForm'
import DeltaRuleForm from './DeltaRuleForm'
import MeasureTriggerRuleForm from './MeasureTriggerRuleForm'
import ScheduledTriggerRuleForm from './ScheduledTriggerRuleForm'
import ThresholdRuleForm from './ThresholdRuleForm'
import TriggerRuleForm from './TriggerRuleForm'

const component = {
  'measure-communication': ({ isEdit, selectedOrganizationKey, validation, trigger, onChange }) => (
    <CommunicationRuleForm
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),

  communication: ({ isEdit, selectedOrganizationKey, validation, trigger, onChange }) => (
    <CommunicationRuleForm
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),

  threshold: ({ isEdit, selectedOrganizationKey, validation, trigger, onChange }) => (
    <ThresholdRuleForm
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),
  delta: ({ isEdit, selectedOrganizationKey, validation, trigger, onChange }) => (
    <DeltaRuleForm
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),

  trigger: ({ isEdit, selectedOrganizationKey, validation, trigger, onChange }) => (
    <TriggerRuleForm
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),

  'measure-trigger': ({ isEdit, selectedOrganizationKey, validation, trigger, onChange }) => (
    <MeasureTriggerRuleForm
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),

  'scheduled-trigger': () => <ScheduledTriggerRuleForm />
}

const TriggerComponent = ({ type, isEdit, selectedOrganizationKey, validation, trigger, onChange }) => {
  return component[type]({ isEdit, selectedOrganizationKey, validation, trigger, onChange })
}

export default TriggerComponent
