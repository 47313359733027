import React, { createContext, useContext, useState } from 'react'
import { useAuth } from '../../Shared/Contexts/AuthContext'
import sanitizer from '../../../helpers/sanitizer'

const UsersContext = createContext({})

export const useUsers = () => {
  return useContext(UsersContext)
}

export const UsersProvider = ({ children }) => {
  const {
    useBaseAxiosGet,
    useBaseAxiosPost,
    useBaseAxiosPatch,
    useBaseAxiosPut,
    useBaseAxiosDelete
  } = useAuth()

  const [filters, setFilters] = useState({
    roleIds: [],
    organizationKeys: [],
    searchInSubOrganizations: false
  })

  const useChangePassword = async (payload) => {
    return await useBaseAxiosPatch({
      url: '/users/self/change-password',
      payload
    })
  }

  const useGetEmailChangeRequest = async ({ requestId }) => {
    return await useBaseAxiosGet({
      url: `/users/email-change-requests/${requestId}`
    })
  }

  const useUpdateUserRoleAndOrganization = async ({
    uid,
    currentOrganizationKey,
    roleId,
    organizationKey
  }) => {
    return await useBaseAxiosPut({
      url: `/users/${uid}/organizations/${currentOrganizationKey}`,
      payload: { roleId, organizationKey }
    })
  }

  const useSearchUsersQuery = async ({ filters, page, pageSize }) => {
    return await useBaseAxiosPost({
      url: '/users/search',
      payload: sanitizer(filters),
      params: { page, pageSize }
    })
  }

  const useDeleteUser = async ({ uid, organizationKey }) => {
    return await useBaseAxiosDelete({
      url: `/users/${uid}/organizations/${organizationKey}`
    })
  }

  const useDeleteSelf = async () => {
    return await useBaseAxiosDelete({
      url: `/users/self`
    })
  }

  const useSaveUserProfile = async (payload) => {
    return await useBaseAxiosPatch({
      url: `/users/self`,
      payload: sanitizer(payload)
    })
  }

  const useGetUserProfile = async () => {
    return await useBaseAxiosGet({
      url: `/users/self`
    })
  }

  const useChengeEmail = async ({ requestId, payload }) => {
    return await useBaseAxiosPatch({
      url: `/users/email-change-requests/${requestId}`,
      payload: sanitizer(payload)
    })
  }

  const useFetchUserInvites = async ({ userId }) => {
    return useBaseAxiosGet({ url: `/users/${userId}/invites` })
  }

  const useFetchUser = async (userId) => {
    return useBaseAxiosGet({ url: `/users/${userId}` })
  }

  const value = {
    filters,
    setFilters,
    useChangePassword,
    useUpdateUserRoleAndOrganization,
    useSearchUsersQuery,
    useDeleteUser,
    useSaveUserProfile,
    useGetUserProfile,
    useGetEmailChangeRequest,
    useChengeEmail,
    useDeleteSelf,
    useFetchUserInvites,
    useFetchUser
  }

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
}
