import { Grid2, Stack, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { SensorsRounded as SensorsRoundedIcon } from '@mui/icons-material'
import moment from 'moment'
import { useAutomations } from '../../Contexts/AutomationsContext'
import { useValidationEffect } from '../../../../helpers/hooks'

//TODO 02-12-2024 questo componente di ricerca di device probabilmente andra mergiato con altri componenti e spostato in un shared
const DevicesList = ({ onChange, selectedDevice }) => {
  const { useFetchDevices } = useAutomations()
  const [devices, setDevices] = useState([])

  const handleChangeSelectedDevice = (_, device) => onChange(device)

  useEffect(() => {
    if (!selectedDevice?.modelId || !selectedDevice?.brandId) {
      return
    }

    useFetchDevices({
      filters: {
        modelIds: [selectedDevice?.modelId],
        brandIds: [selectedDevice?.brandId]
      },
      page: 1,
      pageSize: 100
    }).then((res) => setDevices(res.data))
  }, [selectedDevice])

  return (
    <>
      {devices.map((device, i) => (
        <Grid2
          id={`create-action-device-${i}`}
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
          py={2}
          px={1}
          key={`device-${i}`}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor:
              device.id == selectedDevice?.id
                ? 'rgb(176, 222, 242, 0.3)'
                : '#fff'
          }}
          onClick={(event) => handleChangeSelectedDevice(event, device)}
        >
          <Grid2 size={1}>
            <Box
              mt={1}
              pl={2}
            >
              <SensorsRoundedIcon sx={{ color: '#5CDE9A' }} />
            </Box>
          </Grid2>
          <Grid2 size="grow">
            <Stack>
              <Typography>{device.name}</Typography>
              <Typography variant="body2">{device.address}</Typography>
            </Stack>
          </Grid2>
          <Grid2 size={3}>
            <Typography variant="body2">
              {t('common.lastUpdate')}:{' '}
              {device.lastMeasurementAt
                ? moment(device.lastMeasurementAt).format('DD.MM.YYYY HH:mm:ss')
                : t('common.noPresent')}
            </Typography>
          </Grid2>
        </Grid2>
      ))}
    </>
  )
}

export default DevicesList
