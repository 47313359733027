import { Chip, Container, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import EventsTable from '../Tables/Table.jsx'
import EventsFilters from '../Filters/Filters.jsx'
import dayjs from 'dayjs'
import BarChart from '../../Charts/BarChart.jsx'
import NotFoundMessage from '../../Shared/Components/NotFoundMessage.jsx'
import Show from '../../Layout/Can/Show.jsx'
import { useEvents } from '../Contexts/EventsContext.jsx'
import AlarmedFilter from '../../Shared/Filters/Fields/AlarmedFilter.jsx'

const DEFAULT_PAGE_SIZE = 25

const Events = () => {
  const { useSearchEvents, useFetchEventsSearchSummary } = useEvents()
  const [filters, setFilters] = useState({
    fromTo: {
      from: dayjs().add(-8, 'day').toISOString(),
      to: dayjs().toISOString()
    }
  })
  const [isLoading, setIsLoading] = useState(false)
  const [events, setEvents] = useState()
  const [eventsSummary, setEventsSummary] = useState()
  const [page, setPage] = useState(1)

  const handlePageChange = (value) => setPage(value)

  useEffect(() => {
    setIsLoading(true)
    useSearchEvents({
      payload: { ...filters, allowMissingPosition: true },
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
      .then((res) => setEvents(res))
      .finally(() => setIsLoading(false))
  }, [filters, page])

  useEffect(() => {
    setPage(1)
  }, [filters])

  useEffect(() => {
    useFetchEventsSearchSummary(filters).then((res) => setEventsSummary(res))
  }, [])

  const handleFiltersChange = (filters) => setFilters(filters)

  const handleAlarmedFilterChange = (value) =>
    setFilters({ ...filters, ...value })

  return (
    <Container sx={{ py: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h2">{t('common.events')}</Typography>
          <Chip
            label={events?.count}
            color="primary"
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <AlarmedFilter
          filters={filters}
          onChange={handleAlarmedFilterChange}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <EventsFilters
          filters={filters}
          onChange={handleFiltersChange}
        />
      </Stack>
      <Show when={() => isLoading}>
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}
        >
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '100px' }}
            ></Skeleton>
          ))}
        </Stack>
      </Show>
      <Show when={() => !isLoading}>
        <BarChart data={eventsSummary} />
        <EventsTable
          events={events}
          onPageChange={handlePageChange}
          paging={{ page, pageSize: DEFAULT_PAGE_SIZE }}
        />
      </Show>
      <NotFoundMessage when={() => !events?.data?.length} />
    </Container>
  )
}

export default Events
