import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Card from '../Card/Card.jsx'
import { useRoles } from '../Contexts/RolesContext'

const RolesList = () => {
  const [roles, setRoles] = useState([])
  const { useFetchRolesStandardQuery } = useRoles()

  useEffect(() => {
    useFetchRolesStandardQuery().then((res) => {
      if (res.isError) {
        return
      }
      setRoles(res.data)
    })
  }, [])

  return (
    <Box mt={4}>
      {roles.map((role) => (
        <Card
          role={role}
          isSuper={false}
          key={`role-${role.name}`}
        />
      ))}
    </Box>
  )
}

export default RolesList
