import React, { useEffect, useState } from 'react'
import ModelsSelector from './ModelsSelector'
import { useAutomations } from '../../Contexts/AutomationsContext'

const TriggerRuleForm = ({ trigger, isEdit, onChange, selectedOrganizationKey, validation }) => {
  const {
    quantityId = trigger.quantity?.id || trigger.quantityId,
    selectedModels = trigger.models,
    applyToChildOrganizations,
    type,
    organizationKey
  } = trigger

  const { useGetQuantities } = useAutomations()

  const [quantities, setQuantities] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState()

  useEffect(() => {
    useGetQuantities().then((res) => {
      setQuantities(res.data)
      setSelectedQuantity(res.data.find((q) => q.id === quantityId))
    })
  }, [])

  useEffect(() => {
    const selected = quantities.find((q) => q.id === quantityId)
    setSelectedQuantity(selected)
  }, [quantityId])

  const handleModelsChanged = (data) => {
    onChange(data)
  }

  return (
    <>
      <ModelsSelector
        applyToChildOrganizations={applyToChildOrganizations}
        type={type}
        selectedModels={selectedModels}
        selectedQuantity={selectedQuantity}
        isEdit={isEdit}
        onModelsChanged={handleModelsChanged}
        selectedOrganizationKey={organizationKey || selectedOrganizationKey}
        validation={validation}
      />
    </>
  )
}

export default TriggerRuleForm
