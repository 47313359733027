import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { DeleteRounded as DeleteRoundedIcon } from '@mui/icons-material'
import TimeField from 'react-simple-timefield'
import Show from '../../../Layout/Can/Show'
import { t } from 'i18next'

const WeekDay = ({ name, scheduler, hideEndHour, onChange }) => {
  const handleAddHours = () => {
    const timeSlots = !hideEndHour ? { startHour: '00:00', endHour: '23:59' } : { startHour: '00:00' }
    const newScheduler = {
      ...scheduler,
      [name]: {
        selected: true,
        timeSlots: [...scheduler[name].timeSlots, timeSlots]
      }
    }
    onChange(newScheduler)
  }

  const handleRemovedHours = (index) => {
    const { timeSlots } = structuredClone(scheduler[name])
    timeSlots.splice(index, 1)
    const newScheduler = {
      ...scheduler,
      [name]: { selected: timeSlots.length > 0, timeSlots: timeSlots }
    }
    onChange(newScheduler)
  }

  const handleChangeStartHour = ({ target: { value } }, index) => {
    const { timeSlots } = structuredClone(scheduler[name])
    timeSlots[index].startHour = value
    const newScheduler = {
      ...scheduler,
      [name]: { selected: true, timeSlots: timeSlots }
    }
    onChange(newScheduler)
  }

  const handleChangeEndHour = ({ target: { value } }, index) => {
    const { timeSlots } = structuredClone(scheduler[name])
    timeSlots[index].endHour = value
    const newScheduler = {
      ...scheduler,
      [name]: { selected: true, timeSlots: timeSlots }
    }
    onChange(newScheduler)
  }

  return (
    <>
      <Box>
        <Stack
          direction="row"
          spacing={3}
          alignItems={'center'}
          justifyContent="space-between">
          <Typography
            variant="h5"
            mb={5}>
            {t(`triggers.weekDays.${name}`)}
          </Typography>
          <Stack
            direction="column"
            spacing={3}
            alignItems={'center'}
            justifyContent="space-between">
            {scheduler[name].timeSlots.map((ts, index) => (
              <Stack
                key={`time-slot-${index}`}
                direction="row"
                spacing={3}
                alignItems={'center'}
                justifyContent="space-between">
                <TimeField
                  value={ts.startHour || ''}
                  onChange={(event) => handleChangeStartHour(event, index)}
                  input={
                    <TextField
                      type="text"
                      label={!hideEndHour ? t('common.fromHour') : t('common.hours')}
                    />
                  }
                />
                <Show when={() => !hideEndHour}>
                  <TimeField
                    value={ts.endHour || ''}
                    onChange={(event) => handleChangeEndHour(event, index)}
                    input={
                      <TextField
                        type="text"
                        label={t('common.toHour')}
                      />
                    }
                  />
                </Show>
                <Button
                  variant="text"
                  key="trash"
                  onClick={() => handleRemovedHours(index)}>
                  <DeleteRoundedIcon
                    size={16}
                    color="red"
                  />
                </Button>
              </Stack>
            ))}
          </Stack>
          <Button onClick={handleAddHours}>{t('triggers.addTime')}</Button>
        </Stack>
        <Divider
          orientation="horizontal"
          sx={{ my: 3 }}
        />
      </Box>
    </>
  )
}

export default WeekDay
