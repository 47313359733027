import React, { useEffect, useState } from 'react'
import { Button, Container, Stack } from '@mui/material'
import { t } from 'i18next'
import { Save as SaveIcon } from '@mui/icons-material'
import DeviceSummary from '../Form/DeviceSummary.jsx'
import UpdateDeviceInfo from '../Form/UpdateDeviceInfo.jsx'
import UpdateNetwork from '../Form/UpdateNetwork.jsx'
import UpdatePosition from '../Form/UpdatePosition.jsx'
import UpdateProvisioning from '../Form/UpdateProvisioning.jsx'
import UpdatePublication from '../Form/UpdatePublication.jsx'
import ErrorDialog from '../../Shared/Dialogs/ErrorDialog.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import { useDevices } from '../Contexts/DevicesContext.jsx'

const UpdateDevice = ({ deviceId, isOpen, setIsOpen }) => {
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const { useFetchDevice, useUpdateDevice } = useDevices()
  const [deviceInfo, setDeviceInfo] = useState(null)
  const [positionInfo, setPositionInfo] = useState(null)
  const [publicationInfo, setPublicationInfo] = useState(null)
  const [networkInfo, setNetworkInfo] = useState(null)
  const [provisioningInfo, setProvisioningInfo] = useState({})
  const [errorDescription, setErrorDescription] = useState(null)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [deviceData, setDeviceData] = useState()
  const [deviceError, setDeviceError] = useState(false)
  const [isDeviceLoading, setIsDeviceLoading] = useState(false)
  const [updateDeviceError, setUpdateDeviceError] = useState()

  const [deviceInfoValid, setDeviceInfoValid] = useState(true)
  const [provisioningInfoValid, setProvisioningInfoValid] = useState(true)
  const [networkValid, setNetworkValid] = useState(true)
  const [positionValid, setPositionValid] = useState(true)

  const handleError = () => {
    setErrorDescription(`${updateDeviceError.data.statusCode} - ${updateDeviceError.data.error}: ${updateDeviceError.data.message}`)
    setIsErrorDialogOpen(true)
  }

  const handleClose = () => setIsOpen(false)

  const handleSave = async () => {
    const payload = {
      device: {
        organizationKey: deviceInfo.organization,
        name: deviceInfo.name,
        description: deviceInfo.description,
        modelId: parseInt(deviceData.device.modelId),
        position: positionInfo,
        public: publicationInfo.isPublic,
        hidden: false, //2024-03-05 per ora lascerei così se entro 3 mesi nessuno chiede di ripristinarlo lo eliminiamo anche lato BE
        serial: deviceInfo.serial,
        tags: deviceInfo.tags,
        odlCode: deviceData.device.odlCode,
        locationCode: deviceData.device.locationCode
      },
      sourceData:
        deviceData.device.source.type === 'lorawan'
          ? {
              ...deviceData.sourceData,
              device: {
                ...networkInfo.device,
                user: networkInfo.device.user || deviceData.sourceData.device.username
              }
            }
          : { ...networkInfo },
      parsingData: provisioningInfo
    }

    useUpdateDevice({ id: deviceId, payload })
      .then(() => {
        openSuccessAlert()
        handleClose()
      })
      .catch((err) => {
        handleError()
        setUpdateDeviceError(err.data)
        openErrorAlert({ keys: err.data })
      })
  }

  const updateDeviceInfo = (info, isValid) => {
    setDeviceInfoValid(isValid)
    setDeviceInfo(info)
  }

  const updatePositionInfo = (info, isValid) => {
    setPositionValid(isValid)
    setPositionInfo(info)
  }

  const updatePublicationInfo = (info) => setPublicationInfo(info)

  const updateNetworkInfo = (info, isValid) => {
    setNetworkValid(isValid)
    setNetworkInfo(info)
  }

  const handleErrorDialogClose = () => setIsErrorDialogOpen(false)

  const updateProvisioningInfo = (info, isValid) => {
    setProvisioningInfoValid(isValid)
    setProvisioningInfo(info)
  }

  useEffect(() => {
    setIsDeviceLoading(true)

    useFetchDevice({ id: deviceId })
      .then((res) => setDeviceData(res.data))
      .catch(() => setDeviceError(true))
      .finally(() => setIsDeviceLoading(false))
  }, [])

  const showNetworkStep = () => ['lorawan', 'wmbus', 'nbiot', 'mqtt'].includes(deviceData.device.source.type)

  return (
    <>
      <ZDialog
        id={'device-update-dialog'}
        isOpen={isOpen}
        onClose={handleClose}
        title={t('devices.update')}
        content={
          <Container maxWidth="md">
            {!deviceError && !isDeviceLoading && deviceData && (
              <Stack
                direction="column"
                spacing={10}>
                <DeviceSummary
                  model={deviceData.device.model}
                  brand={deviceData.device.brand}
                  type={deviceData.device.type}
                  sourceType={deviceData.device.source.type}
                  measurements={deviceData.quantities}
                />

                <UpdateDeviceInfo
                  deviceData={deviceData}
                  onChange={updateDeviceInfo}
                />
                <UpdatePosition
                  deviceData={deviceData}
                  onChange={updatePositionInfo}
                />
                {showNetworkStep() && (
                  <UpdateNetwork
                    deviceData={deviceData}
                    onChange={updateNetworkInfo}
                  />
                )}
                <UpdateProvisioning
                  deviceData={deviceData}
                  onChange={updateProvisioningInfo}
                />
                <UpdatePublication
                  deviceData={deviceData}
                  onChange={updatePublicationInfo}
                />
              </Stack>
            )}
          </Container>
        }
        actions={
          <Button
            id="update-device-dialog-button-save"
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={!provisioningInfoValid || !deviceInfoValid || !networkValid || !positionValid}>
            {t('common.save')}
          </Button>
        }
      />

      <ErrorDialog
        isOpen={isErrorDialogOpen}
        errorFrom="Update Device"
        errorDescription={errorDescription}
        onClose={handleErrorDialogClose}
      />
    </>
  )
}

export default UpdateDevice
