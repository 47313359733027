import { createTheme, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import darkTheme from '../../../themes/dark.js'
import lightTheme from '../../../themes/light.js'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

export const MyThemeProvider = ({ children }) => {
  const { theme } = useGlobalContext()

  return (
    <ThemeProvider
      theme={
        theme === 'light' ? createTheme(lightTheme) : createTheme(darkTheme)
      }
    >
      {children}
    </ThemeProvider>
  )
}
