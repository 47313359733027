import { Box, ButtonGroup, Chip, IconButton, Pagination, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import {
  ContentCopyRounded as ContentCopyRoundedIcon,
  CheckRounded as CheckRoundedIcon,
  MailRounded as MailRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'

import { APP_BASE_URL } from '../../../configs/environment.js'
import { useInvites } from '../Contexts/InvitesContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

const DEFAULT_PAGE_SIZE = 25
const PendingInvitesList = ({ invites, page, onChangePage, onInviteDeleted }) => {
  const { useDeleteInvite, useResendInvite } = useInvites()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const [copied, setCopied] = useState(null)

  const handleResend = (email, inviteId) => {
    useResendInvite({ email, inviteId })
      .then(() => openSuccessAlert())
      .catch((err) => openErrorAlert({ keys: err.keys }))
  }

  const handleDelete = (inviteId) => {
    useDeleteInvite(inviteId)
      .then((res) => {
        onInviteDeleted()
        openSuccessAlert()
      })
      .catch((err) => openErrorAlert({ keys: err.keys }))
  }

  const handleContentCopyRounded = (id) => {
    setCopied(id)
    navigator.clipboard.writeText(`${APP_BASE_URL}/invites/${id}`)
  }

  return (
    <>
      <Stack
        my={4}
        spacing={2}>
        {invites?.data.map((invite) => (
          <Box key={invite?.id}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              mx={2}
              my={1}>
              <Box sx={{ width: '50%' }}>
                <Typography variant="h5">{invite.email}</Typography>
                <Typography
                  variant="body2"
                  id={`invite-url-${invite.email}`}>
                  <small>{`${APP_BASE_URL}/invites/${invite.id}`}</small>
                </Typography>
              </Box>
              <Box sx={{ width: '30%' }}>
                <Chip
                  color="primary"
                  variant="standard"
                  label={invite.organization?.name}
                />
              </Box>
              <Box sx={{ width: '10%' }}>
                <Chip
                  color="primary"
                  variant="standard"
                  label={invite.role.name}
                />
              </Box>
              <Box sx={{ width: '10%' }}>
                <ButtonGroup size="small">
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleDelete(invite.id)}
                      id={`delete-invite-${invite.email}`}>
                      <DeleteRoundedIcon
                        color="error"
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Resend">
                    <IconButton
                      onClick={() => handleResend(invite.email, invite.id)}
                      id={`resend-invite-${invite.email}`}>
                      <MailRoundedIcon
                        color="primary"
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Copy link">
                    <IconButton
                      onClick={() => handleContentCopyRounded(invite.id)}
                      id={`copy-link-invite-${invite.email}`}>
                      {copied !== invite.id ? (
                        <ContentCopyRoundedIcon
                          color="primary"
                          fontSize="small"
                        />
                      ) : (
                        <CheckRoundedIcon
                          color="primary"
                          fontSize="small"
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </ButtonGroup>
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>
      <Box
        display="flex"
        justifyContent="center">
        {invites && invites?.count > DEFAULT_PAGE_SIZE && (
          <Pagination
            count={Math.ceil(invites?.count / DEFAULT_PAGE_SIZE)}
            page={page}
            onChange={onChangePage}
            showFirstButton
            showLastButton
          />
        )}
      </Box>
    </>
  )
}

export default PendingInvitesList
