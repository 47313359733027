/* eslint-disable no-unused-vars */
import { Chip, Container, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GatewaysTable from '../Tables/GatewaysTable.jsx'
import GatewaysFilters from '../Filters/Filters.jsx'
import { t } from 'i18next'
import { useSearchParams } from 'react-router-dom'
import { useGateways } from '../Contexts/GatewaysContext.jsx'

const DEFAULT_PAGE_SIZE = 25

const Gateways = () => {
  const [searchParams] = useSearchParams()
  const { useSearchGateways } = useGateways()
  const fullText = searchParams.get('search')

  const [filters, setFilters] = useState({})

  const [gateways, setGateways] = useState({ data: [], count: 0 })
  const [isGatewaysLoading, setIsGatewaysLoading] = useState(false)
  const [{ sort, direction }, setSorting] = useState({
    direction: 'desc',
    sort: 'lastMeasurementAt'
  })

  const [page, setPage] = useState(1)

  const handleFiltersChange = (filters) => setFilters(filters)

  useEffect(() => {
    setIsGatewaysLoading(true)

    useSearchGateways({
      filters: { ...filters, fullText },
      page,
      pageSize: DEFAULT_PAGE_SIZE,
      sort,
      direction
    })
      .then((res) => {
        setGateways({ data: res.data, count: res.count })
      })
      .finally(() => {
        setIsGatewaysLoading(false)
      })
  }, [filters, page, sort, direction, fullText])

  const handleChangePage = (_, value) => setPage(value)
  const handleChangeSorting = (value) => setSorting(value)

  return (
    <Container sx={{ py: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h2">{t('common.gateways')}</Typography>
          <Chip
            label={gateways?.count}
            color="primary"
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <GatewaysFilters
          filters={filters}
          onChange={handleFiltersChange}
          itemsCount={gateways?.count}
        />
      </Stack>

      <GatewaysTable
        gateways={gateways}
        page={page}
        sorting={{ sort, direction }}
        onPageChange={handleChangePage}
        onSortingChange={handleChangeSorting}
        isLoading={isGatewaysLoading}
      />
    </Container>
  )
}

export default Gateways
