import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import React from 'react'
import { t } from 'i18next'

const GatewayStatus = ({ filters, onChange }) => {
  const status = filters?.status || []

  const handleChange = ({ target: { name } }) => {
    const newStatus = status.includes(name)
      ? status.filter((s) => s !== name)
      : status.concat(name)
    onChange({ status: newStatus })
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.gateways.status.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="Attivo"
            control={
              <Checkbox
                name="Attivo"
                onChange={handleChange}
                checked={status.includes('Attivo')}
              />
            }
            label={t('filters.gateways.status.active')}
          />
          <FormControlLabel
            value="Guasto"
            control={
              <Checkbox
                name="Guasto"
                onChange={handleChange}
                checked={status.includes('Guasto')}
              />
            }
            label={t('filters.gateways.status.failure')}
          />
          <FormControlLabel
            value="In lavorazione"
            control={
              <Checkbox
                name="In lavorazione"
                onChange={handleChange}
                checked={status.includes('In lavorazione')}
              />
            }
            label={t('filters.gateways.status.inProgress')}
          />
          <FormControlLabel
            value="In magazzino"
            control={
              <Checkbox
                name="In magazzino"
                onChange={handleChange}
                checked={status.includes('In magazzino')}
              />
            }
            label={t('filters.gateways.status.inStock')}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

export default GatewayStatus
