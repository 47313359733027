import { IconButton } from '@mui/material'
import React from 'react'
import {
  DarkModeRounded as DarkModeRoundedIcon,
  LightModeRounded as LightModeRoundedIcon
} from '@mui/icons-material'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

const ThemeToggle = () => {
  const { theme, setTheme } = useGlobalContext()

  return (
    <IconButton
      aria-label="switch to dark mode"
      onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
      color="inherit"
    >
      {theme === 'light' && <DarkModeRoundedIcon fontSize="small" />}
      {theme === 'dark' && <LightModeRoundedIcon fontSize="small" />}
    </IconButton>
  )
}

export default ThemeToggle
