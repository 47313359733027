import React, { createContext, useContext } from 'react'
import { useAuth } from '../../Shared/Contexts/AuthContext'
import sanitizer from '../../../helpers/sanitizer'

const EventsContext = createContext({})

export const useEvents = () => {
  return useContext(EventsContext)
}

export const EventsProvider = ({ children }) => {
  const { useBaseAxiosPost, useBaseAxiosGet } = useAuth()

  const useSearchEvents = async ({ payload, page, pageSize }) => {
    return await useBaseAxiosPost({
      url: `/events/search?page=${page}&pageSize=${pageSize}`,
      payload: sanitizer(payload),
      project: ({ data, isSuccess, isError, status }) => ({
        data: data.results,
        count: data.totalCount,
        isSuccess: isSuccess,
        isError: isError,
        status: status
      })
    })
  }

  const useFetchEvents = async ({ deviceId, from, to }) => {
    return await useBaseAxiosGet({
      url: `/events`,
      params: {
        from,
        to,
        deviceId
      }
    })
  }

  const useFetchEventsSearchSummary = async (filters) => {
    return await useBaseAxiosPost({
      url: `/events/search/summary`,
      payload: { ...filters, allowMissingPosition: true },
      project: ({ data }) => data
    })
  }

  const value = {
    useSearchEvents,
    useFetchEvents,
    useFetchEventsSearchSummary
  }

  return <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
}
