import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import DeltaTriggerSelector from './DeltaTriggerSelector'
import { Autocomplete, TextField } from '@mui/material'
import ModelsSelector from './ModelsSelector'
import { useAutomations } from '../../Contexts/AutomationsContext'

const DeltaRuleForm = ({ trigger, isEdit, onChange, selectedOrganizationKey, validation }) => {
  const sortFn = (a, b) => {
    const label1 = a.label.toLowerCase()
    const label2 = b.label.toLowerCase()
    if (label1 < label2) return -1
    if (label1 > label2) return 1
    return 0
  }

  const { useGetQuantities } = useAutomations()

  const {
    quantityId = trigger.quantity?.id || trigger.quantityId,
    device,
    selectedModels = trigger.models,
    applyToChildOrganizations,
    type,
    trigger: triggerType,
    organizationKey
  } = trigger

  const [quantities, setQuantities] = useState([])

  const [selectedQuantity, setSelectedQuantity] = useState()
  const [selectedSubQuantity, setSelectedSubQuantity] = useState()

  const getSubQuantities = () =>
    device && selectedQuantity
      ? device.quantities.filter((q) => q.measureId === selectedQuantity.id).map((q) => ({ id: q.measureName, label: q.measureName }))
      : []

  useEffect(() => {
    useGetQuantities().then((res) => {
      setQuantities(res.data)
      setSelectedQuantity(res.data.find((q) => q.id === quantityId))
    })
  }, [])

  useEffect(() => {
    const selected = quantities.find((q) => q.id === quantityId)
    setSelectedQuantity(selected)
  }, [quantityId])

  const handleChangeMeasuere = (_event, value) => onChange({ quantity: value })

  const handleChangeSubMeasuere = (_event, value) => {
    const models = [
      {
        id: parseInt(device.modelId, 10),
        measures: [{ name: value.id }]
      }
    ]
    setSelectedSubQuantity(value)
    onChange({ models })
  }

  const handleDeltraTriggerSelectorChange = (data) => onChange(data)

  const handleModelsChanged = (data) => onChange(data)

  const subQuantities = getSubQuantities()

  const filteredQuantities = device
    ? quantities
        .filter((q) => device.quantities.some((dq) => dq.measureId === q.id) && q.type.isAccumulator)
        .map((fq) => ({ ...fq, label: t(fq.label) }))
        .sort(sortFn)
    : quantities
        .filter((q) => q.type.isAccumulator)
        .map((fq) => ({ ...fq, label: t(fq.label) }))
        .sort(sortFn)

  return (
    <>
      <Autocomplete
        sx={{
          marginTop: '30px'
        }}
        id="measureSelector"
        name="measureSelector"
        options={filteredQuantities}
        value={selectedQuantity ? { ...selectedQuantity, label: t(selectedQuantity.label) } : ''}
        getOptionLabel={(option) => option.label || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={handleChangeMeasuere}
        renderInput={(params) => (
          <TextField
            name="quantity"
            {...params}
            label={t('triggers.selectMeasure')}
            variant="standard"
            error={validation?.messages?.quantity?.length > 0}
            helperText={validation?.messages?.quantity?.join(', ')}
          />
        )}
        disabled={isEdit}
      />
      {subQuantities.length > 0 && (
        <Autocomplete
          sx={{
            marginTop: '30px'
          }}
          py={5}
          id="subMeasureSelector"
          name="subMeasureSelector"
          options={subQuantities}
          getOptionLabel={(option) => t(option.label)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={handleChangeSubMeasuere}
          defaultValue={selectedSubQuantity || ''}
          value={selectedSubQuantity || ''}
          renderInput={(params) => (
            <TextField
              name="quantity"
              {...params}
              label={t('triggers.selectSubMeasure')}
              variant="standard"
            />
          )}
          disabled={isEdit}
        />
      )}

      {selectedQuantity && (
        <>
          {!device && (
            <div className="a2a-alarms-page__formContainer">
              <ModelsSelector
                selectedModels={selectedModels}
                applyToChildOrganizations={applyToChildOrganizations}
                type={type}
                selectedQuantity={selectedQuantity}
                isEdit={isEdit}
                onModelsChanged={handleModelsChanged}
                selectedOrganizationKey={organizationKey || selectedOrganizationKey}
                validation={validation}
              />
            </div>
          )}

          <div className="a2a-alarms-page__formContainer">
            {selectedQuantity.type.name === 'numeric' && (
              <DeltaTriggerSelector
                trigger={triggerType}
                onDeltaTriggerSelectorChange={handleDeltraTriggerSelectorChange}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DeltaRuleForm
