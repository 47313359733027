import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import React, { useEffect } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import {
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon
} from '@mui/icons-material'

const InvitesTable = ({ invites, onInviteAccepted = () => { } }) => {
  //TODO 03-12-2024 sistemare assolutamente
  //const [acceptInvite, { isSuccess: isSaveSuccess }] = useAcceptInviteMutation()

  // const [refuseInvite, { isSuccess: isRefuseSuccess }] =
  //   useRefuseInviteMutation()

  const handleAcceptInvite = (inviteId) => {
    //acceptInvite({ payload: {}, inviteId })
  }

  const handleRefuseInvite = (id) => {
    //refuseInvite(id)
  }

  // useEffect(() => {
  //   onInviteAccepted()
  // }, [isSaveSuccess, isRefuseSuccess])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="user invites table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('common.organization')}</TableCell>
            <TableCell align="left">{t('common.role')}</TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invites.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                align="left"
              >
                {row.organization.name}
              </TableCell>
              <TableCell align="left">{row.role.name}</TableCell>
              <TableCell
                size="small"
                align="right"
                padding="checkbox"
              >
                <IconButton
                  size="24px"
                  onClick={() => handleAcceptInvite(row.id)}
                >
                  <CheckCircleRoundedIcon
                    size={24}
                    color="green"
                  />
                </IconButton>
              </TableCell>
              <TableCell
                size="small"
                align="right"
                padding="checkbox"
              >
                <IconButton
                  size="24px"
                  onClick={() => handleRefuseInvite(row.id)}
                >
                  <CancelRoundedIcon
                    size={24}
                    color="red"
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

InvitesTable.propTypes = {
  invites: PropTypes.array,
  onInviteAccepted: PropTypes.func
}

export default InvitesTable
