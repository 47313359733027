import { FormControl, FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { t } from 'i18next'

const IsUsedInRuleFilter = ({ filters, onChange = () => {} }) => {
  const handleChange = (_, value) => {
    onChange({ isUsedInRule: value })
  }

  return (
    <>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              onChange={handleChange}
              checked={filters.isUsedInRule}
            />
          }
          label={t('automations.usedInARule')}
          labelPlacement="end"
        />
      </FormControl>
    </>
  )
}

export default IsUsedInRuleFilter
