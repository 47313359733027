import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import OrganizationsFilter from '../../Shared/Filters/Fields/OrganizationsFilter.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'

const Filters = ({ filters, onChange }) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState(false)
  const {
    currentUser: { isSuperAdmin, currentOrganizationKey, permissions }
  } = useAuth()

  const orgPermissions =
    currentOrganizationKey && permissions
      ? permissions[currentOrganizationKey] || []
      : []

  const handleOnDeleteOrganizations = () => {
    setSelectedOrganizations(false)
    onChange({ ...filters, organizationKeys: [] })
  }

  const handleOrganizationsChange = (data) => {
    setSelectedOrganizations(true)
    onChange({ ...filters, ...data })
  }

  useEffect(() => {
    const { organizationKeys } = filters

    setSelectedOrganizations(
      !!(organizationKeys && organizationKeys.length > 0)
    )
  }, [filters])

  const filterUnits = [
    {
      label: t('filters.devices.organizations.label'),
      selected: selectedOrganizations,
      onDelete: handleOnDeleteOrganizations,
      hidden: !orgPermissions.includes('organizations_r') && !isSuperAdmin,
      content: (
        <OrganizationsFilter
          filters={filters}
          onChange={handleOrganizationsChange}
        />
      )
    }
  ]

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {[...filterUnits].map((e, i) => (
          <Filter
            id="button-filter-organization"
            key={`device-filter-${i}`}
            onClick={e.onClick}
            onDelete={e.onDelete}
            selected={e.selected}
            title={e.label}
            hidden={e.hidden || false}
            content={e.content}
            count={e.count}
          />
        ))}
      </Stack>
    </>
  )
}

export default Filters
