import React, { createContext, useContext } from 'react'
import { useAuth } from './AuthContext'
import qs from 'qs'

const FiltersContext = createContext({})

export const useFilters = () => {
  return useContext(FiltersContext)
}

export const FiltersProvider = ({ children }) => {
  const { useBaseAxiosGet, useBaseAxiosPost } = useAuth()

  const useFetchOrganizationsFilters = async () => {
    return await useBaseAxiosGet({ url: `filters/organizations` })
  }

  const useFetchTagsFilters = async () => {
    return await useBaseAxiosGet({ url: `filters/tags` })
  }

  const useFetchBrandsFilters = async () => {
    return await useBaseAxiosGet({ url: `/filters/brands` })
  }

  const useFetchModelsFilters = async (brandIds) => {
    return await useBaseAxiosGet({
      url: `/filters/models?${qs.stringify(
        { brandId: brandIds },
        { arrayFormat: 'repeat' } // array format for api: {a: [0, 1]} -> 'a=0&a=1'
      )}`
    })
  }

  const useFetchPlaces = async ({ place }) => {
    return await useBaseAxiosPost({
      url: '/devices/geocoder',
      payload: {
        address: place
      }
    })
  }

  const value = {
    useFetchOrganizationsFilters,
    useFetchPlaces,
    useFetchTagsFilters,
    useFetchBrandsFilters,
    useFetchModelsFilters
  }

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  )
}
