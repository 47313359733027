import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import InlineMessage from '../../../Shared/Components/InlineMessage'
import { useAutomations } from '../../Contexts/AutomationsContext'

const ModelSelector = ({ onChange, selectedDevice }) => {
  const { useFetchModelsFilter } = useAutomations()
  const [model, setModel] = useState('')
  const [models, setModles] = useState([])

  useEffect(() => {
    useFetchModelsFilter({ brandIds: selectedDevice.brandId }).then((res) =>
      setModles(res.data)
    )
  }, [selectedDevice])

  const handleModelChange = (event) => {
    setModel(event.target.value)
    onChange({ ...selectedDevice, modelId: event.target.value })
  }

  return models.length ? (
    <FormControl fullWidth>
      <InputLabel id="model-select-label">{t('common.model')}</InputLabel>
      <Select
        labelId="model-select-label"
        id="model-select"
        label={t('common.model')}
        onChange={handleModelChange}
        value={model}
      >
        {models.map((model, i) => (
          <MenuItem
            key={`model-${i}`}
            value={model.id}
          >
            {model.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <InlineMessage message={t('actions.modelNotFound')} />
  )
}

export default ModelSelector
