import React, { createContext, useContext } from 'react'
import { useAuth } from '../../Shared/Contexts/AuthContext'
import sanitizer from '../../../helpers/sanitizer'

const GatewaysContext = createContext({})

export const useGateways = () => {
  return useContext(GatewaysContext)
}

export const GatewaysProvider = ({ children }) => {
  const { useBaseAxiosPost, useBaseAxiosGet } = useAuth()

  const useSearchGateways = async ({
    filters,
    page,
    pageSize,
    sort,
    direction
  }) => {
    return await useBaseAxiosPost({
      url: `/gateways/search`,
      params: {
        page: !page ? 1 : page,
        pageSize,
        sort: sort ? `${sort}:${direction}` : undefined
      },
      payload: sanitizer(filters)
    })
  }

  const useFetchGatewayTests = async ({ serial, page = 1, pageSize }) => {
    return await useBaseAxiosGet({
      url: `/gateways/tests/${serial}?page=${page}&pageSize=${pageSize}`
    })
  }

  const value = {
    useSearchGateways,
    useFetchGatewayTests
  }

  return (
    <GatewaysContext.Provider value={value}>
      {children}
    </GatewaysContext.Provider>
  )
}
