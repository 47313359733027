import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import OrganizationForm from '../Form/OrganizationForm.jsx'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useOrganizations } from '../Contexts/OrganizationsContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

const OrganizationEdit = ({ organizationId, isOpen, setIsOpen, onOrganizationEdit }) => {
  const { useEditOrganization, useFetchOrganization } = useOrganizations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const [isOrganizationDataFetching, setIsOrganizationDataFetching] = useState(true)
  const [organization, setOrganization] = useState()
  const [orgData, setOrgData] = useState(null)
  const [isValid, setIsValid] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
    setOrgData(null)
    onOrganizationEdit()
  }

  const handleOrganizationFormChange = (orgData, isValid) => {
    setIsValid(isValid)
    setOrgData(orgData)
  }

  const handleSaveOrganization = () => {
    const payload = {
      data: {
        name: orgData.organizationName,
        domain: orgData.domain,
        timeZone: orgData.timezone,
        orgProfileId: orgData.profile?.id || null
      },
      logo: orgData.file,
      organizationId: organizationId
    }
    useEditOrganization(payload)
      .then((res) => {
        handleClose()
        openSuccessAlert()
      })
      //TODO: 2025-01-02 usare i keys
      .catch((err) => openErrorAlert({ keys: err.data.message }))
  }

  useEffect(() => {
    setIsOrganizationDataFetching(true)
    useFetchOrganization(organizationId).then((res) => {
      setOrganization(res.data)
      setIsOrganizationDataFetching(false)
    })
  }, [organizationId])

  return (
    <Dialog
      maxWidth="md"
      scroll="body"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="edit-organization-modal-title"
      aria-describedby="edit-organization-modal-description">
      <CloseDialogButton onClose={handleClose} />

      <DialogTitle
        variant="h4"
        display="flex"
        justifyContent="center">
        {t('organizations.edit')}: {organization?.name}
      </DialogTitle>

      <DialogContent>
        {!isOrganizationDataFetching && (
          <OrganizationForm
            onChange={handleOrganizationFormChange}
            organization={organization}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="edit-organization-confirm-save-button"
          variant="contained"
          onClick={handleSaveOrganization}
          disabled={!isValid}>
          {t('common.confirmAndSave')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrganizationEdit.propTypes = {
  organizationId: PropTypes.number,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default OrganizationEdit
