import {
  Autocomplete,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  Container,
  ListItem,
  ListItemText
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'

const TriggerSelector = ({ isOpen, onClose, onChange, organization }) => {
  const { useSearchTriggers } = useAutomations()
  const [isTriggersLoading, setIstriggersLoading] = useState(false)

  const [triggers, setTriggers] = useState()

  const searchTriggers = ({ filters, page }) => {
    setIstriggersLoading(true)
    useSearchTriggers({
      filters,
      page,
      pageSize: 100
    })
      .then((res) => setTriggers(res))
      .finally(() => setIstriggersLoading(false))
  }

  const handleClose = () => onClose()

  const handleChangeSelectedTrigger = (_event, value) => {
    onChange(value)
    onClose()
  }

  useEffect(() => {
    searchTriggers({ filters: {}, page: 1 })
  }, [])

  useEffect(() => {
    if (organization) {
      const filters = { organizationKeys: [organization.key] }
      searchTriggers({ filters, page: 1 })
    }
  }, [organization])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      scroll="body"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle
        display="flex"
        justifyContent="center"
        variant="h4"
      >
        {t('rules.selectTrigger')}
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent
        style={{
          paddingTop: '30px',
          minHeight: '15vh',
          maxHeight: '15vh'
        }}
      >
        <Container maxWidth="md">
          <Autocomplete
            id="trigger-select"
            loading={isTriggersLoading}
            options={triggers?.data.filter((t) => !t.rules.length) || []}
            label="Trigger"
            onChange={handleChangeSelectedTrigger}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                name="trigger-select-label"
                {...params}
                label={t('rules.selectTrigger')}
                variant="standard"
              />
            )}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                divider
                key={option.id}
              >
                <ListItemText
                  primary={option.name}
                  secondary={option.organizationKey}
                />
              </ListItem>
            )}
          />
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default TriggerSelector
