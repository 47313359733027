import {
  Dialog,
  TextField,
  Autocomplete,
  DialogTitle,
  DialogContent,
  Container,
  ListItem,
  ListItemText
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import PropTypes from 'prop-types'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'

const ActionSelector = ({ isOpen, onClose, onChange, rule, organization }) => {
  const { useSearchActions } = useAutomations()

  const [actions, setActions] = useState()

  useEffect(() => {
    useSearchActions({
      page: 1,
      pageSize: 100,
      organizationKey: organization?.key
    }).then((res) => setActions(res))
  }, [])

  const handleClose = () => {
    onClose()
  }
  const handleChangeSelectedAction = (_event, value) => {
    onChange(value)
    onClose()
  }

  const filteredOptions =
    rule?.trigger && rule.trigger?.type === 'scheduled-trigger'
      ? actions?.data?.filter((a) => a.type === 'downlink') || []
      : actions?.data || []

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      scroll="body"
      sx={{ borderRadius: 100 }}
    >
      <DialogTitle
        display="flex"
        justifyContent="center"
        variant="h4"
      >
        {t('rules.selectAction')}
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent
        style={{
          paddingTop: '30px',
          minHeight: '15vh',
          maxHeight: '15vh'
        }}
      >
        <Container maxWidth="md">
          <Autocomplete
            id="action-selector"
            loading={!actions}
            options={filteredOptions}
            disableClearable={true}
            onChange={handleChangeSelectedAction}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('rules.selectAction')}
                variant="standard"
              />
            )}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                divider
                key={option.id}
              >
                <ListItemText
                  primary={option.name}
                  secondary={option.organizationKey}
                />
              </ListItem>
            )}
          />
        </Container>
      </DialogContent>
    </Dialog>
  )
}

ActionSelector.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  rule: PropTypes.object,
  organization: PropTypes.object
}

export default ActionSelector
