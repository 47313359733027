import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Stack,
  TextField,
  Grid2,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Divider,
  Dialog,
  Button,
  Card,
  CardContent,
  Alert
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import useFieldValidation from '../../../helpers/fieldValidation.js'
import Joi from 'joi'
import { useNavigate, useParams } from 'react-router-dom'
import { useValidationEffect } from '../../../helpers/hooks.js'
import Show from '../../Layout/Can/Show.jsx'
import InlineMessage from '../../Shared/Components/InlineMessage.jsx'
import LoginForm from '../../Shared/Forms/LoginForm.jsx'
import { useInvites } from '../Contexts/InvitesContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'

const schema = Joi.object({
  firstName: Joi.string().empty(null).required(),
  lastName: Joi.string().empty(null).required(),
  phoneNumber: Joi.string().empty(null).required(),
  password: Joi.string()
    .empty(null)
    .pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)
    .message(`La passowrd non rispetta gli standard di sicurezza`)
    .required(),
  confirmPassword: Joi.string()
    .empty(null)
    .pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)
    .message(`La passowrd non rispetta gli standard di sicurezza`)
    .required()
})

/*
Almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale, lunghezza minima 8 caratteri
*/

const AcceptInvite = () => {
  const { openErrorAlert, openSuccessAlert, setLoginDialog } = useGlobalContext()
  const { useGetInvite, useAcceptInvite, useRefuseInvite } = useInvites()
  const { saveCurrentUser, useLoginUser } = useAuth()

  const navigate = useNavigate()
  const { inviteId } = useParams()

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [privacy, setPrivacy] = useState()
  const [legal, setLegal] = useState()
  const [marketing, setMarketing] = useState()
  const [languageCode, setLanguageCode] = useState('it')
  const [isLoginError, setIsLoginError] = useState(false)

  const [invite, setInvite] = useState()

  const [inviteSuccess, setInviteSuccess] = useState({ isRefuseInError: false })

  const { isRefuseInError } = inviteSuccess

  useEffect(() => {
    useGetInvite({ inviteId })
      .then((res) => {
        setInvite(res.data)
      })
      .catch((err) => openErrorAlert({ keys: err.data }))
  }, [])

  const acceptInvite = ({ openLogin = true }) => {
    useAcceptInvite({ inviteId, payload: {} })
      .then(() => {
        navigate('/')
        openSuccessAlert()
        setIsOpen(false)
        if (openLogin) {
          setLoginDialog(true)
        }
        setInviteSuccess({ ...inviteSuccess, isSaveSuccess: true })
      })
      .catch((err) => {
        openErrorAlert({ keys: err.data.keys })
        setInviteSuccess({ ...inviteSuccess, isSaveSuccess: false })
      })
  }

  const refuseInvite = () => {
    useRefuseInvite({ inviteId })
      .then(() => {
        setInviteSuccess({ ...inviteSuccess, isRefuseSuccess: true })
        openSuccessAlert()
      })
      .catch(() => {
        openErrorAlert({ keys: err.data })
        setInviteSuccess({
          ...inviteSuccess,
          isRefuseSuccess: false,
          isRefuseInError: true
        })
      })
  }

  const [validation, setValidation] = useState({ isValid: false })
  const [loginFormIsValid, setLoginFormIsValid] = useState(false)
  const [loginForm, setLoginForm] = useState({})

  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [isOpen, setIsOpen] = useState(true)

  useValidationEffect(() => {
    const validateFields = useFieldValidation({ firstName, lastName, phoneNumber, password, confirmPassword }, schema)

    setValidation(validateFields)
  }, [firstName, lastName, phoneNumber, password, confirmPassword])

  const handleLoginFormChange = (loginForm, isValid) => {
    setLoginForm(loginForm)
    setLoginFormIsValid(isValid)
  }

  const handleLoginAndAccept = () => {
    useLoginUser(loginForm)
      .then(({ data: loginData }) => {
        saveCurrentUser(loginData)
        acceptInvite({ openLogin: false })
      })
      .catch((err) => setIsLoginError(err.isError))
  }
  const handleLoginAndRefuse = () => {
    useLoginUser(loginForm)
      .then(({ data: loginData }) => {
        saveCurrentUser(loginData)
        refuseInvite()
      })
      .catch((err) => setIsLoginError(err.isError))
  }

  const handleFirstName = ({ target: { value } }) => setFirstName(value)

  const handleLastName = ({ target: { value } }) => setLastName(value)

  const handlePassword = ({ target: { value } }) => {
    const passwordMatchValue = value === confirmPassword
    setPasswordsMatch(passwordMatchValue)
    setPassword(value)
  }

  const handleConfirmPassword = ({ target: { value } }) => {
    const passwordMatchValue = value === password
    setPasswordsMatch(passwordMatchValue)

    setConfirmPassword(value)
  }

  const handlePhoneNumber = ({ target: { value } }) => setPhoneNumber(value)

  const handlePrivacy = (_event, value) => setPrivacy(value)

  const handleLegal = (_event, value) => setLegal(value)

  const handleMarketing = (_event, value) => setMarketing(value)

  const handleLanguageCode = (_event, languageValue) => setLanguageCode(languageValue.value)

  const handleRefuseInvite = () =>
    useRefuseInvite(inviteId)
      .then(() => openSuccessAlert())
      .catch((err) => openErrorAlert({ keys: err.data }))
      .finally(() => {
        setIsOpen(false)
        navigate('/')
      })

  const handleSave = () => {
    const payload = {
      firstName,
      lastName,
      email: invite.email,
      phone: phoneNumber,
      password,
      gdprConsents: {
        privacy,
        legal,
        marketing
      },
      languageCode
    }

    useAcceptInvite({
      payload,
      inviteId
    })
      .then(() => openSuccessAlert())
      .catch((err) => openErrorAlert({ keys: err.data }))
  }

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      aria-labelledby="accept-invite-modal-title"
      aria-describedby="accept-invite-modal-description">
      <DialogTitle
        variant="h4"
        display="flex"
        justifyContent="center">
        {t('invites.confirmTheInvite')}
      </DialogTitle>

      <DialogContent>
        <Show when={() => !invite}>
          <InlineMessage message={t('invites.notFound')} />
          <Button
            variant="contained"
            onClick={() => navigate('/')}>
            {t('invites.backToHome')}
          </Button>
        </Show>
        <Show when={() => !!invite}>
          <Stack
            marginTop={'20px'}
            spacing={2}
            justifyContent="center">
            {!invite && !isRefuseInError && (
              <Box>
                <Typography variant="h5">{t('invites.yourData')}</Typography>
                <Stack direction="row">
                  <Typography variant="h6">
                    <strong>{t('common.organization')}:</strong>
                  </Typography>
                  <Typography variant="h6">{invite?.organization?.name}</Typography>
                </Stack>
                <Stack direction="row">
                  <Typography variant="h6">
                    <strong>{t('common.email')}:</strong>
                  </Typography>
                  <Typography variant="h6">{invite?.email}</Typography>
                </Stack>
                <Stack direction="row">
                  <Typography variant="h6">
                    <strong>{t('common.role')}:</strong>
                  </Typography>
                  <Typography variant="h6">{invite?.role?.name}</Typography>
                </Stack>
              </Box>
            )}
            <Show when={() => !!invite && !invite.userExists}>
              <Divider orientation="horizontal" />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h5">{t('invites.basicInformation')}</Typography>
                <Grid2
                  container
                  spacing={2}>
                  <Grid2 size={6}>
                    <TextField
                      id="create-user-name"
                      label="Nome"
                      name="firstName"
                      variant="standard"
                      required
                      error={validation?.messages?.firstName?.length > 0}
                      helperText={validation?.messages?.firstName?.join(', ')}
                      onChange={handleFirstName}
                      fullWidth></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField
                      id="create-user-surname"
                      label="Cognome"
                      name="lastName"
                      variant="standard"
                      required
                      error={validation?.messages?.lastName?.length > 0}
                      helperText={validation?.messages?.lastName?.join(', ')}
                      onChange={handleLastName}
                      fullWidth></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField
                      id="create-user-phone-number"
                      label="Telefono"
                      name="phoneNumber"
                      variant="standard"
                      required
                      error={validation?.messages?.phoneNumber?.length > 0}
                      helperText={validation?.messages?.phoneNumber?.join(', ')}
                      onChange={handlePhoneNumber}
                      fullWidth></TextField>
                  </Grid2>

                  <Grid2 size={12}>
                    <Divider sx={{ py: 3 }} />
                  </Grid2>

                  <Grid2 size={6}>
                    <TextField
                      id="create-user-password"
                      label="Password"
                      name="password"
                      variant="standard"
                      type="password"
                      required
                      error={validation?.messages?.password?.length > 0}
                      helperText={validation?.messages?.password?.join(', ')}
                      onChange={handlePassword}
                      fullWidth
                    />
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField
                      id="create-user-confirm-password"
                      label="Conferma Password"
                      name="confirmPassword"
                      variant="standard"
                      type="password"
                      required
                      error={validation?.messages?.confirmPassword?.length > 0}
                      helperText={validation?.messages?.confirmPassword?.join(', ')}
                      onChange={handleConfirmPassword}
                      fullWidth
                    />
                  </Grid2>

                  {validation?.messages?.password?.length && (
                    <Grid2 size={12}>
                      <Card
                        variant="standard"
                        sx={{
                          width: '100%',
                          background: 'rgba(229, 94, 194, 0.1)',
                          marginTop: '10px'
                        }}>
                        <CardContent>
                          <Stack
                            justifyContent={'center'}
                            alignItems={'center'}
                            spacing={2}>
                            <Typography
                              variant="body2"
                              py={1}>
                              {t('invites.passwordError')}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid2>
                  )}
                  {!passwordsMatch && (
                    <Grid2 size={12}>
                      <Card
                        variant="standard"
                        sx={{
                          width: '100%',
                          background: 'rgba(229, 94, 194, 0.1)',
                          marginTop: '10px'
                        }}>
                        <CardContent>
                          <Stack
                            justifyContent={'center'}
                            alignItems={'center'}
                            spacing={2}>
                            <Typography
                              variant="body2"
                              py={1}>
                              {t('invites.correspondingPasswordError')}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid2>
                  )}

                  <Grid2 size={12}>
                    <Divider sx={{ py: 3 }} />
                  </Grid2>

                  <Grid2 size={6}>
                    <Autocomplete
                      py={1}
                      disablePortal
                      onChange={handleLanguageCode}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      defaultValue={{ label: 'It', value: 'it' }}
                      options={[
                        { label: 'It', value: 'it' },
                        { label: 'En', value: 'en' }
                      ]}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('common.language')}
                          variant="standard"
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <Typography
                      variant="h5"
                      pt={5}>
                      {t('invites.editYourPreferences')}
                    </Typography>

                    <Stack
                      spacing={1}
                      py={1}>
                      <FormControlLabel
                        control={<Checkbox onChange={handlePrivacy} />}
                        label={
                          <Typography
                            id="accept-data-treatment"
                            variant="body2"
                            color="textSecondary"
                            component="p">
                            {t('invites.acceptDataTreatment')}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox onChange={handleLegal} />}
                        label={
                          <Typography
                            id="accept-legal-terms"
                            variant="body2"
                            color="textSecondary"
                            component="p">
                            {t('invites.acceptLegalTerms')}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox onChange={handleMarketing} />}
                        label={
                          <Typography
                            id="accept-commercial-data-usage"
                            variant="body2"
                            color="textSecondary"
                            component="p">
                            {t('invites.acceptCommercialDataUsage')}
                          </Typography>
                        }
                      />
                    </Stack>
                  </Grid2>
                </Grid2>
              </Box>
            </Show>
          </Stack>
        </Show>
        <Show when={() => !!invite && invite.userExists}>
          <Stack
            marginTop={'15px'}
            direction="column"
            spacing={2}>
            <Typography variant="title">Conferma la tua identià prima di procedere</Typography>
            <LoginForm
              onChange={handleLoginFormChange}
              onLogin={() => {}}
            />
            {isLoginError && <Alert severity="error">{t('users.loginFail')}</Alert>}
            <Stack
              marginTop={'20px'}
              spacing={2}
              direction={'row'}>
              <Button
                id="accept-confirm-invite-button"
                variant="contained"
                disabled={!loginFormIsValid}
                onClick={handleLoginAndAccept}>
                {t('invites.acceptInvite')}
              </Button>
              <Button
                id="refuse-confirm-invite-button"
                variant="contained"
                disabled={!loginFormIsValid}
                onClick={handleLoginAndRefuse}>
                {t('invites.refuseInvite')}
              </Button>
            </Stack>
          </Stack>
        </Show>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Show when={() => !!invite && !invite.userExists}>
          <Button
            id="refuse-creation-user-button"
            variant="contained"
            color="error"
            onClick={handleRefuseInvite}>
            {t('invites.refuseInvite')}
          </Button>
          <Button
            id="accept-creation-user-button"
            variant="contained"
            disabled={!validation.isValid || !privacy || !legal || !passwordsMatch}
            onClick={handleSave}>
            {t('invites.acceptInvite')}
          </Button>
        </Show>
      </DialogActions>
    </Dialog>
  )
}

export default AcceptInvite
