import React, { useState, useEffect } from 'react'
import useFieldValidation from '../../../helpers/fieldValidation.js'

import { Autocomplete, Button, FormControl, Input, InputLabel, FormHelperText, TextField } from '@mui/material'
import { t } from 'i18next'
import Joi from 'joi'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useRoles } from '../../Roles/Contexts/RolesContext.jsx'
import { useInvites } from '../Contexts/InvitesContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  organization: Joi.object().required(),
  role: Joi.object().required()
}).unknown()

const InviteUser = ({ isOpen, onClose }) => {
  const { useCreateInvite } = useInvites()
  const { useFetchRolesStandardQuery } = useRoles()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const {
    currentUser: { currentOrganizationKey, selfOrganizations }
  } = useAuth()

  const [rolesSuggestions, setRolesSuggestions] = useState([])
  const [isRolesSuggestionsError, setIsRolesSuggestionsError] = useState(false)
  const [isRolesSuggestionsLoading, setIsRolesSuggestionsLoading] = useState(false)

  const [validation, setValidation] = useState({ isValid: false })
  const [invite, setInvite] = useState({
    email: '',
    role: null,
    organization: null
  })

  const handleChangeOrganization = (_, value) => setInvite({ ...invite, organization: value })

  const handleChangeRole = (_, value) => setInvite({ ...invite, role: value })

  const handleChangeEmail = (event) => setInvite({ ...invite, email: event.target.value })

  const handleCreateInvite = () => {
    useCreateInvite({
      email: invite.email,
      roleId: invite.role.id,
      organizationKey: invite.organization.key
    })
      .then(() => {
        onClose()
        openSuccessAlert()
      })
      .catch(() => openErrorAlert({ keys: [] }))
  }

  useEffect(() => {
    setIsRolesSuggestionsLoading(true)
    useFetchRolesStandardQuery()
      .then((res) => {
        setRolesSuggestions(res.data)
        setIsRolesSuggestionsLoading(false)
      })
      .catch(() => setIsRolesSuggestionsError(true))
  }, [])

  useEffect(() => {
    if (invite.organization === null && selfOrganizations?.length) {
      setInvite({
        ...invite,
        organization: selfOrganizations.find((o) => o.key === currentOrganizationKey)
      })
    }
  }, [selfOrganizations])

  useEffect(() => {
    if (invite.role === null && rolesSuggestions.length) {
      setInvite({
        ...invite,
        role: rolesSuggestions.find((r) => r.name === 'viewer')
      })
    }
  }, [rolesSuggestions])

  useEffect(() => {
    setValidation(useFieldValidation(invite, schema))
  }, [invite])

  return (
    <>
      <ZDialog
        id="invite-dialog"
        isOpen={isOpen}
        onClose={onClose}
        title={t('invites.add')}
        content={
          <>
            <Autocomplete
              id="InviteUser-ChooseOrganization"
              name="organization"
              disableClearable
              options={selfOrganizations}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              value={invite.organization || { name: '' }}
              onChange={handleChangeOrganization}
              renderInput={(params) => (
                <TextField
                  name="organization"
                  {...params}
                  label={t('invites.chooseOrganization')}
                  variant="standard"
                  helperText={t('common.organization')}
                />
              )}
            />

            <Autocomplete
              id="InviteUser-ChooseRole"
              name="role"
              disableClearable
              loading={isRolesSuggestionsLoading}
              options={rolesSuggestions}
              disabled={isRolesSuggestionsError}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              value={invite.role || { name: '' }}
              onChange={handleChangeRole}
              renderInput={(params) => (
                <TextField
                  name="role"
                  {...params}
                  label={t('invites.chooseRole')}
                  variant="standard"
                  helperText={t('common.role')}
                />
              )}
              sx={{ marginTop: 3 }}
            />

            <FormControl
              fullWidth
              sx={{ marginTop: 3 }}>
              <InputLabel htmlFor="InviteUser-Email">{t('common.email')}</InputLabel>

              <Input
                id="InviteUser-Email"
                name="email"
                onChange={handleChangeEmail}
              />

              <FormHelperText>{validation?.messages?.email?.join(', ')}</FormHelperText>
            </FormControl>
          </>
        }
        actions={
          <Button
            id="confirm-create-invite-user-button"
            variant="contained"
            color="primary"
            onClick={handleCreateInvite}
            sx={{ marginTop: 3 }}>
            {t('invites.add')}
          </Button>
        }
        centerActions={true}
      />
    </>
  )
}

export default InviteUser
