import React, { memo, useEffect, useState } from 'react'
import { GOOGLE_MAPS_API_KEY } from '../../configs/environment.js'
import { googleMapsDarkStyle } from '../../configs/googleMapsConfig.js'
import MarkerAlarm from '../../images/markerAlarm.svg'
import MarkerList from '../../images/markerList.svg'
import MarkerOffline from '../../images/markerOffline.svg'
import MarkerOnline from '../../images/markerOnline.svg'
import m1 from '../../images/m1.png'
import m2 from '../../images/m2.png'
import m3 from '../../images/m3.png'
import m4 from '../../images/m4.png'
import m5 from '../../images/m5.png'
import m6 from '../../images/m6.png'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { notNullOrUndefined } from '../../helpers/utilis.js'
import { GoogleMap, MarkerClustererF, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { useGlobalContext } from '../Shared/Contexts/GlobalContext.jsx'

const FrontMap = ({ deviceId }) => {
  const navigate = useNavigate()
  const {
    map: { center },
    theme,
    setMapCenter,
    setMapZoom,
    useFetchMarkers,
    devicesFilters: filters,
    setDevicesFilters
  } = useGlobalContext()

  const [markers, setMarkers] = useState([])

  useEffect(() => {
    useFetchMarkers({
      filters
    }).then((res) => setMarkers(res.data))
  }, [filters])

  const { isLoaded } = useJsApiLoader({
    id: 'cityeye-map',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  const [frontMap, setFrontMap] = useState(null)

  const mapContainerStyle = {
    width: '100%',
    minHeight: '100%',
    height: 'calc(100vh - 150px)'
  }

  const onLoad = (frontMap) => {
    setFrontMap(frontMap)
  }

  useEffect(() => {
    if (!frontMap) {
      return
    }

    if (deviceId) {
      frontMap.setCenter(center)
      if (center?.lat !== 0 && center?.lng !== 0) {
        frontMap.setZoom(22)
      } else {
        frontMap.setZoom(5)
      }
    } else {
      const bounds = new window.google.maps.LatLngBounds()

      const len = markers.length

      for (let i = 0; i < len; i++) {
        if (!isValidPosition(markers[i].position)) {
          continue
        }
        bounds.extend({
          lat: markers[i].position.latitude,
          lng: markers[i].position.longitude
        })
      }

      frontMap.fitBounds(bounds)
    }
  }, [center, markers, frontMap, deviceId])

  const handleClick = ({ id, position, isList }) => {
    if (isValidPosition(position)) {
      setMapCenter({
        lat: position.latitude,
        lng: position.longitude
      })
    }
    if (isList) {
      setDevicesFilters({
        ...filters,
        nearby: {
          distance: 3,
          center: {
            latitude: position.latitude,
            longitude: position.longitude
          }
        }
      })
      setMapZoom({ zoom: 22 })
    } else {
      navigate(`/detail/${id}`)
    }
  }

  //This is a `for` due to performance issues, don't substitute it for a filter&map!
  const renderMarkers = (clusterer, markers) => {
    // 20/12/2024 commentato perche' causava un render non corretto dei marker dopo aver applicato i filtri
    //potrebbe causare problemi di performance in prod, e in quel caso bisogna valutare una soluzione alternativa
    //  clusterer.clearMarkers()

    const markerComponents = new Array()
    const len = markers.length

    for (let i = 0; i < len; i++) {
      if (!isValidPosition(markers[i].position)) {
        continue
      }

      markerComponents.push(
        <MarkerF
          key={i}
          position={{
            lat: markers[i].position.latitude,
            lng: markers[i].position.longitude
          }}
          clusterer={clusterer}
          noClustererRedraw={true}
          icon={markerSwitch(markers[i])}
          onClick={() => handleClick(markers[i])}
          animation={markers[i].id === deviceId ? 1 : undefined}
        />
      )
    }

    return markerComponents
  }

  const markerSwitch = (marker) => {
    const { isAlive, isAlarm, isList } = marker

    if (isList) {
      return MarkerList
    }
    if (isAlive) {
      return MarkerOnline
    }
    if (isAlarm) {
      return MarkerAlarm
    }

    return MarkerOffline
  }

  const onUnmount = React.useCallback(() => {
    setFrontMap(null)
  }, [])

  const isValidPosition = (position) => !!position && notNullOrUndefined(position.latitude) && notNullOrUndefined(position.longitude)

  return isLoaded ? (
    <GoogleMap
      id="frontpage-map"
      mapContainerStyle={mapContainerStyle}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
          mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid']
        },
        rotateControl: false,
        tilt: 0,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        styles: theme === 'dark' ? googleMapsDarkStyle : undefined
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      // zoom={zoom}
      // center={center}
    >
      <MarkerClustererF
        options={{
          gridSize: 20, // distanza in pixel tra i punti
          styles: [
            {
              textColor: 'white',
              textSize: 16,
              url: m1,
              width: 60,
              height: 60
            },
            {
              textColor: 'white',
              textSize: 16,
              url: m2,
              width: 65,
              height: 65
            },
            {
              textColor: 'white',
              textSize: 16,
              url: m3,
              width: 70,
              height: 70
            },
            {
              textColor: 'white',
              textSize: 16,
              url: m4,
              width: 75,
              height: 75
            },
            {
              textColor: 'white',
              textSize: 16,
              url: m5,
              width: 80,
              height: 80
            },
            {
              textColor: 'white',
              textSize: 16,
              url: m6,
              width: 90,
              height: 90
            }
          ]
        }}>
        {(clusterer) => renderMarkers(clusterer, markers)}
      </MarkerClustererF>
    </GoogleMap>
  ) : (
    <></>
  )
}

FrontMap.propTypes = {
  deviceId: PropTypes.string
}

export default memo(FrontMap)
