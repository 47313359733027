import {
  Box,
  Grid2,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import ModelSelector from './ModelSelector'
import DevicesList from './DevicesList'
import { useAutomations } from '../../Contexts/AutomationsContext'

const SelectDevice = ({ onChange, selectedDevice }) => {
  const { useFetchBrandsFilter } = useAutomations()

  const [showModelSelector, setShowModelSelector] = useState(false)
  const [brand, setBrand] = useState('')
  const [brands, setBrands] = useState([])

  useEffect(() => {
    useFetchBrandsFilter().then((res) => setBrands(res.data))
  }, [])

  const handleBrandChange = (event) => {
    setBrand(event.target.value)
    onChange({
      ...selectedDevice,
      brandId: event.target.value.id,
      brandEncoded: event.target.value.name
    })
    setShowModelSelector(true)
  }

  const handleDeviceChange = (device) => onChange(device)

  const handleModelChange = (device) => onChange(device)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        display="flex"
        alignItems="center"
        spacing={2}
      >
        <Grid2 size={6}>
          <FormControl fullWidth>
            <InputLabel id="brand-select-label">{t('common.brand')}</InputLabel>
            <Select
              labelId="brand-select-label"
              id="brand-select"
              value={brand}
              label={t('common.brand')}
              onChange={handleBrandChange}
            >
              {brands.map((brand, i) => (
                <MenuItem
                  key={`brand-${i}`}
                  value={brand}
                >
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>

        {showModelSelector && (
          <Grid2 size={6}>
            <ModelSelector
              onChange={handleModelChange}
              selectedDevice={selectedDevice}
            />
          </Grid2>
        )}
      </Grid2>

      <Box
        sx={{
          marginTop: 4,
          borderBottom: 2,
          borderColor: '#0f81fc',
          paddingBottom: 1
        }}
      >
        {t('actions.availableSensors')}
      </Box>

      <DevicesList
        onChange={handleDeviceChange}
        selectedDevice={selectedDevice}
      />
    </Box>
  )
}

export default SelectDevice
