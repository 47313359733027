import { Box, IconButton, ButtonGroup, CircularProgress, Stack } from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  ScheduleRounded as ScheduleRoundedIcon,
  DownloadRounded as DownloadRoundedIcon,
  EditRounded as EditRoundedIcon,
  SendRounded as SendRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import Can from '../../Layout/Can/Can.jsx'
import Show from '../../Layout/Can/Show.jsx'
import { useDevices } from '../Contexts/DevicesContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

const ActionFooter = ({ device, actionsDisabled = false, onDelete, onEdit, onAction }) => {
  const { useDeviceMeasurementsDownload } = useDevices()
  const { openErrorAlert } = useGlobalContext()
  const [downloadInProgress, setDownloadInProgress] = useState(false)

  const handleActions = (device) => onAction(device)

  const handleEdit = (device) => onEdit(device)

  const handleDownload = (id, name, serial, from, to) => {
    setDownloadInProgress(true)
    useDeviceMeasurementsDownload({
      id,
      name,
      serial,
      from,
      to
    })
      .catch(() => openErrorAlert({ keys: ['download'] }))
      .finally(() => setDownloadInProgress(false))
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px={1}>
        <Stack
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
          spacing={1}>
          <ScheduleRoundedIcon fontSize="small" />{' '}
          <small>
            {t('devices.lastUpdate')}{' '}
            {device.lastMeasurementAt ? moment(device.lastMeasurementAt).format('DD.MM.YYYY HH:mm:ss') : t('common.noPresent')}
          </small>
        </Stack>
        <Box>
          <ButtonGroup size="small">
            <Can action="devices_d">
              <IconButton
                key="delete"
                onClick={onDelete}
                aria-label="delete"
                color="error">
                <DeleteRoundedIcon fontSize="small" />
              </IconButton>
            </Can>

            <Can action="devices_u">
              <IconButton
                key="actions"
                disabled={actionsDisabled}
                onClick={() => handleActions(device)}
                color="primary">
                <SendRoundedIcon fontSize="small" />
              </IconButton>
            </Can>
            <Can action="devices_r">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Show when={() => downloadInProgress}>
                  <CircularProgress size={20} />
                </Show>
                <Show when={() => !downloadInProgress}>
                  <IconButton
                    variant="text"
                    key="download"
                    color="primary"
                    onClick={() =>
                      handleDownload(device.id, device.name, device.serial, moment().subtract(5, 'days').toISOString(), moment().toISOString())
                    }>
                    <DownloadRoundedIcon fontSize="small" />
                  </IconButton>
                </Show>
              </Box>
            </Can>
            <Can action="devices_u">
              <IconButton
                key="edit"
                aria-label="edit device"
                color="primary"
                onClick={() => handleEdit(device)}>
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            </Can>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  )
}

export default ActionFooter
