import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import Show from '../../../Layout/Can/Show'
import { useAutomations } from '../../Contexts/AutomationsContext'

const ModelsSelector = ({
  selectedQuantity,
  selectedModels,
  type,
  applyToChildOrganizations,
  isEdit,
  onModelsChanged,
  selectedOrganizationKey
}) => {
  const { useGetOrganizationModels, useGetModelsByQuantity } = useAutomations()

  const [modelsInfo, setModelsInfo] = useState([])

  const allIdSelector = '*'
  const quantityId = selectedQuantity ? selectedQuantity.id : ''

  const isRuleCommOrTrigger = (type) =>
    ['communication', 'trigger', 'scheduled-trigger'].includes(type)

  const getModelsByQuantity = () => {
    if (!quantityId) {
      return
    }

    useGetModelsByQuantity({
      quantityId,
      searchInChildrenOrganizations: applyToChildOrganizations,
      organizationKey: selectedOrganizationKey
    }).then((res) => setModelsInfo(res.data.models))
  }

  const getOrganizationsModels = () => {
    useGetOrganizationModels({
      searchInChildrenOrganizations: applyToChildOrganizations,
      organizationKey: selectedOrganizationKey
    }).then((res) => setModelsInfo(res.data.models))
  }

  useEffect(() => {
    isRuleCommOrTrigger(type) ? getOrganizationsModels() : getModelsByQuantity()
  }, [quantityId])

  const onModelChecked = (modelInfo) => {
    const newModels = selectedModels.filter((m) => m.id !== modelInfo.model.id)
    const currentModelIndex = selectedModels.findIndex(
      (m) => m.id === modelInfo.model.id
    )

    currentModelIndex < 0
      ? onModelsChanged({
          models: [
            ...selectedModels,
            {
              id: modelInfo.model.id,
              measures: modelInfo.measures.map((m) => ({ name: m.name }))
            }
          ]
        })
      : onModelsChanged({ models: newModels })
  }

  const onMeasureChanged = (model, measure, checked) => {
    const currentModel = selectedModels.find(
      (sm) => model.model && sm.id === model.model.id
    )

    const newMeasures = checked
      ? currentModel &&
        currentModel.measures &&
        currentModel.measures.length > 0
        ? [...currentModel.measures, { name: measure.name }]
        : [{ name: measure.name }]
      : currentModel.measures.filter((m) => m.name !== measure.name)

    const newModel =
      newMeasures.length !== 0
        ? { id: model.model.id, measures: newMeasures }
        : undefined

    const newSelectedModels = selectedModels.filter(
      (sm) => sm.id !== model.model.id
    )

    if (newModel) {
      onModelsChanged({ models: [...newSelectedModels, newModel] })
    } else {
      onModelsChanged({ models: [...newSelectedModels] })
    }
  }

  const onSelectAllModels = (event) => {
    const {
      target: { checked }
    } = event

    if (isRuleCommOrTrigger(type)) {
      onModelsChanged({ models: checked ? [{ id: allIdSelector }] : [] })
    } else {
      const selectedModels = checked
        ? [
            {
              id: allIdSelector,
              measures: modelsInfo.reduce((acc, val) => {
                const measureSingle = val.measures
                  .map((m) => ({
                    name: m.name
                  }))
                  .filter((ms) => !acc.find((m) => ms.name === m.name))
                acc = [...acc, ...measureSingle]
                return acc
              }, [])
            }
          ]
        : []
      onModelsChanged({ models: selectedModels })
    }
  }

  const isModelChecked = (selectedModels, info) => {
    const isChecked =
      selectedModels.length > 0 &&
      selectedModels.findIndex(
        (m) => m.id === info.model.id || m.id === allIdSelector
      ) > -1

    return isChecked
  }

  const isMeasureChecked = (selectedModels, info, measure) => {
    const isChecked =
      selectedModels.findIndex(
        (sm) =>
          (sm.measures &&
            sm.id === info.model.id &&
            sm.measures.findIndex((m) => m.name === measure.name) > -1) ||
          sm.id === allIdSelector
      ) > -1

    return isChecked
  }

  const sortFn = (a, b) => {
    const name1 = a.model.brandName.toLowerCase()
    const name2 = b.model.brandName.toLowerCase()
    if (name1 < name2) return -1
    if (name1 > name2) return 1
    return 0
  }

  return (
    <Box py={5}>
      <FormControlLabel
        label={t('triggers.searchInSubOrganizations')}
        control={
          <Checkbox
            id="models-selector-search-in-suborgs"
            disabled={isEdit}
            checked={applyToChildOrganizations}
            label={t(`alarms.page.addRule.quantitySubOrgSelection`)}
            onChange={({ target: { checked } }) => {
              onModelsChanged({ applyToChildOrganizations: checked })
            }}
          />
        }
      />
      <Typography
        component="h3"
        fontWeight={700}
        paddingTop={5}
      >
        {t('triggers.modelsRelatedTo')}:
        {type === 'communication' || type === 'measure-communication'
          ? selectedQuantity && selectedQuantity.label
            ? `${t(selectedQuantity.label)}`
            : t('triggers.missingCommunication')
          : 'Trigger'}
      </Typography>
      <Box>
        <FormControlLabel
          label={t('triggers.selectAll')}
          control={
            <Checkbox
              id="models-selector-select-all-triggers"
              disabled={isEdit}
              onChange={onSelectAllModels}
            />
          }
        />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-around"
          useFlexGap
          flexWrap="wrap"
          py={2}
        >
          <Show when={() => isRuleCommOrTrigger(type)}>
            {modelsInfo.sort(sortFn).map((info) => (
              <Card key={`modelsSelector-${info.model.id}`}>
                <CardContent
                  sx={{
                    marginBottom: '15px',
                    maxWidth: '220px',
                    minWidth: '220px'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`models-selector-model-checkbox-${info.model.id}`}
                        disabled={isEdit}
                        onChange={() => onModelChecked(info)}
                        checked={isModelChecked(selectedModels, info)}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {info.model.brandName} / {info.model.name} (
                        {info.devicesCount})
                      </Typography>
                    }
                  />
                </CardContent>
              </Card>
            ))}
          </Show>
          <Show when={() => !isRuleCommOrTrigger(type)}>
            {modelsInfo.sort(sortFn).map((info, index) => (
              <Card key={`modelsSelector-${info.model.id}`}>
                <CardContent
                  sx={{
                    marginBottom: '15px',
                    width: '220px'
                  }}
                >
                  <FormControlLabel
                    key={`model-${index}`}
                    control={
                      <Checkbox
                        id={`models-selector-nocomm-model-checkbox-${info.model.id}`}
                        disabled={isEdit}
                        onChange={() => onModelChecked(info)}
                        checked={isModelChecked(selectedModels, info)}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {info.model.brandName} / {info.model.name} (
                        {info.devicesCount})
                      </Typography>
                    }
                  />
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="p"
                  >
                    {t('common.measures')}:
                  </Typography>
                  {info.measures.map((measure, index) => (
                    <FormControlLabel
                      key={`measure-${index}`}
                      control={
                        <Checkbox
                          id={`models-selector-measures-checkbox-${info.model.id}-${index}`}
                          checked={isMeasureChecked(
                            selectedModels,
                            info,
                            measure
                          )}
                          onChange={(_event, value) =>
                            onMeasureChanged(info, measure, value)
                          }
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {t(measure.label)}
                        </Typography>
                      }
                    />
                  ))}
                </CardContent>
              </Card>
            ))}
          </Show>
        </Stack>
      </Box>
    </Box>
  )
}

export default ModelsSelector
