import {
  Box,
  Grid2,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import Joi from 'joi'
import useFieldValidation from '../../../../helpers/fieldValidation'
import { useValidationEffect } from '../../../../helpers/hooks'

const schema = Joi.object({
  name: Joi.string().empty(undefined).required(),
  emailsToNotify: Joi.string().empty(undefined).required()
})

const ConfigureActionNotification = ({
  notificationConfig,
  actionName,
  onChangeIsValid,
  onNameChange,
  onNotificationConfigChange
}) => {
  const [name, setName] = useState()
  const [emailsToNotify, setEmailsToNotify] = useState()
  const [validation, setValidation] = useState({ isValid: false })

  const handleNameChange = (event) => setName(event.target.value)

  const handleNotifyAdmin = (event) =>
    onNotificationConfigChange({
      ...notificationConfig,
      notifyAdmin: event.target.checked
    })

  const handleNotifyEditor = (event) =>
    onNotificationConfigChange({
      ...notificationConfig,
      notifyEditor: event.target.checked
    })

  const handleNotifyViewer = (event) =>
    onNotificationConfigChange({
      ...notificationConfig,
      notifyViewer: event.target.checked
    })

  const handleChangeEmails = (event) => setEmailsToNotify(event.target.value)

  useValidationEffect(() => {
    const fieldValidation = useFieldValidation({ name, emailsToNotify }, schema)
    setValidation(fieldValidation)
    onChangeIsValid(fieldValidation.isValid)
    onNameChange(name)
    onNotificationConfigChange({
      ...notificationConfig,
      emailsToNotify: emailsToNotify
        ? emailsToNotify.split(',').map((x) => x.trim())
        : emailsToNotify
    })
  }, [name, emailsToNotify])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        marginBottom={5}
        spacing={2}
      >
        <Grid2 size={6}>
          <TextField
            id="configure-action-name"
            variant="standard"
            label={t('actions.actionName')}
            fullWidth
            value={actionName}
            onChange={handleNameChange}
            error={validation.messages?.name?.length > 0}
            helperText={validation.messages?.name?.join(', ')}
          />
        </Grid2>
        <Grid2 size={6}>
          <TextField
            id="configure-action-email-to-notify"
            variant="standard"
            label={t('actions.emailsToNotify')}
            value={notificationConfig.emailsToNotify}
            onChange={handleChangeEmails}
            fullWidth
            error={validation.messages?.emailsToNotify?.length > 0}
            helperText={validation.messages?.emailsToNotify?.join(', ')}
          />
        </Grid2>

        <Grid2
          size={12}
          pt={3}
        >
          <Typography
            variant="h5"
            align="center"
          >
            {t('actions.notificationChoices')}
          </Typography>
        </Grid2>
        <Grid2
          size={12}
          display="flex"
          justifyContent="center"
        >
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationConfig.notifyAdmin}
                  value={true}
                  onClick={handleNotifyAdmin}
                />
              }
              label={t('actions.notifyAdmin')}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value={true}
                  checked={notificationConfig.notifyEditor}
                  onClick={handleNotifyEditor}
                />
              }
              label={t('actions.notifyEditor')}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value={true}
                  checked={notificationConfig.notifyViewer}
                  onClick={handleNotifyViewer}
                />
              }
              label={t('actions.notifyViewer')}
            />
          </FormGroup>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default ConfigureActionNotification
