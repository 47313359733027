import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import {
  TriggerOthersFilters,
  OrganizationsFilter
} from '../../Shared/Filters/Fields/index.js'
import PropTypes from 'prop-types'

const TriggersFilters = ({ filters, onChange }) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState(false)
  const [selectedOthers, setSelectedOthers] = useState(false)

  const handleOnDeleteOrganizations = () => {
    setSelectedOrganizations(false)
    onChange({
      ...filters,
      organizationKeys: [],
      searchInSubOrganizations: false
    })
  }

  const handleOnDeleteOthers = () => {
    setSelectedOthers(false)
    onChange({
      ...filters,
      triggerTypes: [],
      isUsedInRule: false,
      brandIds: [],
      modelIds: []
    })
  }

  const handleOrganizationsChange = (data) => {
    setSelectedOrganizations(true)
    onChange({ ...filters, ...data })
  }

  const handleOthersChange = (data) => {
    setSelectedOthers(true)
    onChange({ ...filters, ...data })
  }

  const filterUnits = [
    {
      label: t('common.organization'),
      selected: selectedOrganizations,
      onDelete: handleOnDeleteOrganizations,
      content: (
        <OrganizationsFilter
          onChange={handleOrganizationsChange}
          filters={filters}
        />
      )
    },
    {
      label: t('common.filters.other'),
      selected: selectedOthers,
      onDelete: handleOnDeleteOthers,
      content: (
        <TriggerOthersFilters
          onChange={handleOthersChange}
          filters={filters}
        />
      )
    }
  ]

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      {filterUnits.map((e, i) => (
        <Filter
          key={`trigger-filter-${i}`}
          onClick={e.onClick}
          onDelete={e.onDelete}
          selected={e.selected}
          title={e.label}
          hidden={e.hidden || false}
          content={e.content}
          count={e.count}
        />
      ))}
    </Stack>
  )
}

TriggersFilters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default TriggersFilters
