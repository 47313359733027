import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { ChevronRightRounded as ChevronRightRoundedIcon, LogoutRounded as LogoutRoundedIcon } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

export default function ProfileMenu({ anchorEl, isOpen, handleClose }) {
  const { openSuccessAlert } = useGlobalContext()
  const navigate = useNavigate()

  const {
    authState: { refreshToken },
    logout
  } = useAuth()

  const { useLogoutUser } = useAuth()

  const handleLogout = () => {
    useLogoutUser({ refreshToken })
      .then(() => {
        openSuccessAlert()
        logout()
      })
      .catch(() => logout())
      .finally(() => navigate('/'))
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{ top: '20px' }}>
      <MenuItem
        id="profile-menu-profile"
        component={Link}
        to="/management/profile">
        <ListItemIcon>
          <ChevronRightRoundedIcon />
        </ListItemIcon>
        <ListItemText>{t('common.profile')}</ListItemText>
      </MenuItem>
      {
        //TODO: scommentare una volta che esiste la dashboard
        /* <MenuItem> 
        <ListItemIcon>
          <ChevronRightRoundedIcon />
        </ListItemIcon>
        <ListItemText>{t('common.dashboard')}</ListItemText>
      </MenuItem> */
      }
      <MenuItem
        id="profile-menu-management"
        component={Link}
        to="/management/devices">
        <ListItemIcon>
          <ChevronRightRoundedIcon />
        </ListItemIcon>
        <ListItemText>{t('common.management')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        id="profile-menu-logout"
        onClick={handleLogout}>
        <ListItemIcon>
          <LogoutRoundedIcon />
        </ListItemIcon>
        <ListItemText>{t('common.logout')}</ListItemText>
      </MenuItem>
    </Menu>
  )
}
